import CarApi from "../../api/CarApi";

export const DECISION_LABELS = {
    [CarApi.const.MergeDecision.theirs]: "Theirs",
    [CarApi.const.MergeDecision.ours]: "Ours",
    [CarApi.const.MergeDecision.conflict]: "Conflict",
    [CarApi.const.MergeDecision.inplace]: "Inplace",
};

export const ACTION_LABELS = {
    [CarApi.const.MergeAction.hold]: "Hold",
    [CarApi.const.MergeAction.resolved]: "Resolved",
    [CarApi.const.MergeAction.undecided]: "Undecided",
};

// HACK: converts keys back to integers
function labelsDict2options(labelsDict) {
    return Object.keys(labelsDict).map(key => {
        return {
            "value": key | 0,
            "label": labelsDict[key],
        }
    });
}

export const DECISION_OPTIONS = labelsDict2options(DECISION_LABELS);

export const ACTION_OPTIONS = labelsDict2options(ACTION_LABELS);

export const AssignValueType = Object.freeze({
    DECISION: Symbol("decision"),
    ACTION: Symbol("action"),
    VALUE: Symbol("value"),
});

export const MERGE_GRID_COLUMN = Object.freeze({
    place: {
        id: 1,
        key: 'place',
    },
    field: {
        id: 2,
        key: 'field',
    },
    origin: {
        id: 3,
        key: 'origin',
    },
    current: {
        id: 4,
        key: 'current',
    },
    decision: {
        id: 5,
        key: 'decision',
    },
    value: {
        id: 6,
        key: 'value',
    },
    action: {
        id: 7,
        key: 'action',
    },
});
