import React from 'react';
import Button from "@material-ui/core/Button";
import CarApi from "../../api/CarApi";
import {Box} from "@material-ui/core";
import {nativeComparator} from "../../utils/utils";
import {POPUP_TYPES, showNotification} from "../../notifications";
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";
import {PlacesetCreateDialog} from "./PlacesetCreateDialog";
import lodash from "lodash";
import {useValueset} from "../../services/valuesets";
import {ValidatePlaceset} from "./ValidatePlaceset";

const AUTO_TIMEZONE_OPTION = {
    label: "",
    value: undefined,
};

export function ProjectSettingsMainTab({project, onChange}) {
    const [timezoneValueset] = useValueset("timezone");
    const [timezoneManual, setTimezoneManual] = React.useState();
    const [placesetId, setPlacesetId] = React.useState();
    const [placesets, setPlacesets] = React.useState([]);
    const [createPlacesetIsOpen, setCreatePlacesetIsOpen] = React.useState(false);
    const init = React.useCallback(async () => {
        setPlacesetId(project?.placeset?.id);
        setTimezoneManual(project?.timezone_manual);
    }, [project]);
    React.useEffect(() => {
        init();
    }, [init]);
    const refresh = React.useCallback(async () => {
        if (!project) {
            return;
        }
        const cres = await CarApi.getClient(project.client_id);
        setPlacesets(cres.data.placesets.sort((a, b) => nativeComparator(a.name, b.name)));
    }, [project]);
    React.useEffect(() => {
        refresh();
    }, [project, refresh]);
    const handleCreatePlaceset = async (data) => {
        if (!project) {
            return;  // project object not yet loaded
        }
        setCreatePlacesetIsOpen(!createPlacesetIsOpen);
        if (!data) {
            return;
        }
        const res = await CarApi.createPlaceset({
            client_id: project.client_id,
            name: data.name,
        });
        setPlacesetId(res.data.placeset_id);
        showNotification({message: "Placeset created"});
        refresh();
    };
    const handleUpdate = async () => {
        await CarApi.updateProject({
            id: project?.id,
            placeset_id: placesetId,
            timezone_manual: timezoneManual || null,
        });
        onChange();
    };
    const handleDelete = () => {
        showNotification({message: "Not yet implemented", type:POPUP_TYPES.ERROR});
    };
    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box>
                <AriAutocomplete
                    options={placesets}
                    onChange={setPlacesetId}
                    value={placesetId}
                    labelGetter={(object)=>`${object?.["name"]} (id=${object?.["id"]})`}
                    valueGetter={fieldGetter("id")}
                    fullWidth
                    label="Placeset"
                />
                <Button
                    onClick={()=>handleCreatePlaceset()}
                >
                    Create new Placeset
                </Button>
                { placesetId && <ValidatePlaceset placesetId={placesetId}/> }
                <Button
                    onClick={handleDelete}
                >
                    Delete
                </Button>
                <AriAutocomplete
                    options={lodash.concat([AUTO_TIMEZONE_OPTION], timezoneValueset)}
                    onChange={setTimezoneManual}
                    value={timezoneManual||""}
                    labelGetter={fieldGetter("label")}
                    valueGetter={fieldGetter("value")}
                    fullWidth
                    label="Timezone"
                />
                <Box>
                    {!timezoneManual && !project?.timezone_inferred?(
                        <React.Fragment>
                            <i>Has no manual or inferred timezone set.</i> Timezone is missing.<br/>
                            (If the project was newly created, inferral from places/maz takes a few hours.)
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            Using <i>{!timezoneManual?"inferred":"manual"}</i> timezone&nbsp;
                            <b>{!timezoneManual?project?.timezone_inferred:timezoneManual}</b>.
                        </React.Fragment>
                    )}
                </Box>
                <Button
                    onClick={handleUpdate}
                >
                    Save changes
                </Button>
                <PlacesetCreateDialog
                    onSave={handleCreatePlaceset}
                    open={createPlacesetIsOpen}
                    defaultName={project?.name}
                    singleClientMode
                />
            </Box>
        </Box>
    );
}
