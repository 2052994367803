import {parseFilterList} from "./poiFilterParser";
import {prettyArrayJoin, trimWithEllipsis} from "./utils";
import lodash from "lodash";

export const SUPPORTED_PREFIXES = [
    'address', 'all', 'brand', 'category', 'city', 'country', 'geocnt', 'has_overlap', 'has_building',
    'has_mixed', 'has_parking', 'has_unspec', 'naics', 'name', 'phone', 'place_id', 'post',
    'postal_code', 'region', 'work', 'work_status', 'work_type', 'ws', 'wstatus', 'wt',
    'zip'];

export const DEFAULT_PREFIX = "name";

export const PREFIX_FILTERS = SUPPORTED_PREFIXES.map( (value) => {
    return {
        label: value,
        prefix: value,
    }
});

export const PREFIX_NORMALIZER = {
    'ws': 'work_status',
    'wstatus': 'work_status',
    'wt': 'work_type',
    'work': 'work_type',
    'post': 'postal_code',
    'zip': 'postal_code',
};

/**
 * {field: validator}
 *   [a, b, ...] - one of the values in the list
 *   'integer' - integer value
 */
const VALIDATORS = {
    'has_building': ['0', '1', 'true', 'false'],
    'has_mixed': ['0', '1', 'true', 'false'],
    'has_parking': ['0', '1', 'true', 'false'],
    'has_unspec': ['0', '1', 'true', 'false'],
    'has_overlap': ['0', '1', 'true', 'false'],
    'work_status': ['open', 'closed', 'blocked'],
    'category': [],
    'geocnt': 'integer',
    'place_id': 'integer',
    'naics': 'integer',
};

export function assignValidator(field, validator) {
    VALIDATORS[field] = validator;
}

const VALUE_NORMALIZERS = {
    'true': '1',
    'false': '0',
};

/**
 * Parse a single line filter string into filter strucutre
 * The parser returns prefixes with multiple values, this is converted into triplets of
 *   index, prefix, value
 * The parser uses "" as default prefix, we convert that into DEFAULT_PREFIX.
 * @param value
 * @returns {[]}
 */
export function buildFilterStructFromString(value) {
    let tempFilterList = [];
    let seq = 0;
    parseFilterList(value).forEach((rule) => {
        rule.values.forEach((value) => {
            if (!value) {
                return;
            }
            tempFilterList.push([seq++, rule.prefix || DEFAULT_PREFIX, value]);
        });
        if (rule.values.filter((v) => !!v).length === 0) {
            tempFilterList.push([seq++, rule.prefix || DEFAULT_PREFIX, ""]);
        }
    });
    return tempFilterList;
}

/**
 * Return a structured filter for the Search API
 * E.g.:
 *   brand:apple carrot brand:disney
 * Converted to:
 *   {
 *       'all': ['carrot'],
 *       'brand': ['apple', 'disney'],
 *   }
 * Return
 * @param value
 * @returns {{}} A dictionary of prefixes with lists of values to filter for
 */
export function buildApiQueryFromString(value) {
    const filterPairs = buildFilterStructFromString(value).map(  // drop seq and normalize prefix
            ([seq, prefix, value]) => [lodash.get(PREFIX_NORMALIZER, prefix, prefix), value]
        ).filter(  // remove empty filters
            ([prefix, value]) => !!value
        ).filter(  // remove invalid prefixes
            ([prefix, value]) => SUPPORTED_PREFIXES.includes(prefix)
        );
    // group by prefix
    const perPrefix = lodash.groupBy(filterPairs, ([prefix, value]) => prefix);
    // keep only list of values
    const result = lodash.mapValues(perPrefix, (list) => list.map(([prefix, value]) => value));
    return result;
}

/**
 * Renders filter list into a string.
 * Merges adjacent filters with the same prefix into a single filter with comma separated values.
 * Prefixes are all expected to be from the PREFIX_FILTERS set
 * @param filterList filter list of [seq, prefix, value] triplets
 * @returns {string} flat string format
 */
export function buildFilterStringFromStruct(filterList) {
    let result = "";
    let index = 0;
    while (index < filterList.length) {
        let temp = "";
        const prefix = filterList[index][1];
        const values = [];
        do {
            values.push(filterList[index][2]);
            index++;
        } while (index < filterList.length && filterList[index][1] === prefix && prefix !== DEFAULT_PREFIX);
        if (prefix !== DEFAULT_PREFIX) {
            temp = prefix + ":";
        }
        temp += values.map((value) => {
            if (value.includes(",") || value.includes(":") || value.includes(" ")) {
                // FIXME: pooor escaping
                return '"' + value + '"';
            } else {
                return value;
            }
        }).join(",");
        if (!temp) {
            continue;
        }
        if (result.length > 0) {
            result += " ";
        }
        result += temp;
    }
    return result;
}

/**
 * Normalize values in query
 * @param query
 * @return {*}
 */
export function normalizeApiQuery(query) {
    const result = Object.assign({}, query);
    Object.keys(result).forEach(key =>
        result[key] = result[key].map(value => {
           if (key.startsWith("has_") && value in VALUE_NORMALIZERS) {
               return VALUE_NORMALIZERS[value];
           } else {
               return value;
           }
       })
    );
    return result;
}

/**
 * Return a list of validation error messages for each field.
 */
export function getQueryValidationMessages(value) {
    const errors = {};
    const parsedFilterList = parseFilterList(value)
    const invalidPrefixes = parsedFilterList
        .map(rule => rule.prefix || DEFAULT_PREFIX)
        .filter(prefix => !SUPPORTED_PREFIXES.includes(prefix));
    if (invalidPrefixes.length > 0) {
        errors['_prefix'] = `contains invalid prefixes: ${invalidPrefixes.join(", ")}`;
    }
    parsedFilterList.forEach(rule => {
        const rawPrefix = rule.prefix || DEFAULT_PREFIX;
        const prefix = lodash.get(PREFIX_NORMALIZER, rawPrefix, rawPrefix);
        let hasError = false;
        let message = undefined;
        const validator = VALIDATORS[prefix];
        if (validator === "integer") {
            hasError = rule.values.some(value => !/^([0-9]+)$/.test(value));
            message = `${rule.prefix} should be integer`;
        } else if (Array.isArray(validator)) {
            hasError = rule.values.some(value => !validator.includes(value));
            message = `${rule.prefix} should be one of ${trimWithEllipsis(prettyArrayJoin(validator), 40)}`;
        } else {
            return null;
        }
        if (hasError) {
            errors[rule.prefix] = message;
        }
    });
    return Object.values(errors);
}

/**
 * Get valuesets for all validators
 */
export function getValuesets() {
    const res = {}
    Object.keys(VALIDATORS).forEach((key) => {
        const validator = VALIDATORS[key];
       if (Array.isArray(validator)) {
           res[key] = validator;
       }
    });
    return res;
}
