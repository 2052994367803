import React from 'react';
import {Tooltip} from "@material-ui/core";

/**
 * OptionalTooltip
 *
 * Does not render a tooltip if no title is provided.
 * Renders multiline tooltip if an array is provided as title.
 */
export function OptionalTooltip({title, children, ...props}) {
  return title === undefined ? (
      children
  ) : (
      <Tooltip title={
        Array.isArray(title) ?
            title.map(line => <React.Fragment>{line}<br/></React.Fragment>)
            : title
      } {...props}>
        {children}
      </Tooltip>
  );
}
