import React from "react";
import {PreferencesModal} from "./PreferencesModal";
import { useHistory } from "react-router-dom";
import {Appbar} from "@arrivalist/a-js-commons/lib/components/Appbar";
import {useUserinfo} from "../services/userinfo-service";
import {Box, Typography} from "@material-ui/core";
import {SelectionMode} from "../utils/modeSelectorUtils";
import lodash from 'lodash';

export function MDAppBar({appName, children, onHelp, onDehaze, modeInfo}) {
  // TODO: a-js-commons to add title support
  const history = useHistory();
  const userinfo = useUserinfo();
  const [userinfoPostProc, setUserinfoPostProc] = React.useState(userinfo);
  React.useEffect(() => {
    const value = lodash.cloneDeep(userinfo);
    if (modeInfo?.selection_mode === SelectionMode.PROJECT) {
      value.apps.forEach(app => {
        app.url += `projects/${modeInfo.selection_id}`;
      });
    }
    setUserinfoPostProc(value);
  }, [userinfo, modeInfo]);
  const [visiblePreferences, setVisiblePreferences] = React.useState(false);
  const logOut = () => {
    history.push("/saml/logout");
    document.location.reload();
  }
  return (
      <React.Fragment>
        <Appbar
            appName={appName || "POI Editor"}
            userinfo={userinfoPostProc}
            onHelp={onHelp}
            onDehaze={onDehaze}
            menu={[{
              name: "Log Out",
              onClick: logOut,
            }, {
              name: "Settings",
              onClick: () => setVisiblePreferences(true),
            }]}
        >
          <Box flexGrow={1}>
            <Typography style={{width:"82%", textAlign:"center", float:"right"}}
                        component="div"
                        variant="subtitle1"
            >
              {children}
            </Typography>
          </Box>
        </Appbar>
        <PreferencesModal open={visiblePreferences} onClose={() => setVisiblePreferences(false)}/>
      </React.Fragment>
  );
}
