import React from "react";
import {Box, Grid} from "@material-ui/core";
import {SelectionMode} from "../utils/modeSelectorUtils";
import {ModeSelectorButton} from "./ModeSelectorButton";

export function ModeSelectorTooltipInfo({client_id, client_name, placeset, projects, onModeSelect}) {
    console.log([client_id, client_name]);
  return (
      <React.Fragment>
        { client_id &&
        <React.Fragment>
          Belongs to client:
          <Grid container direction='row'>
            <Grid item xs={12}>
              <Box pl={3} my={0.5}>
                <ModeSelectorButton
                  mode={SelectionMode.CLIENT}
                  id={client_id}
                  label={client_name}
                  onModeSelect={onModeSelect}
                />
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>}
        { placeset &&
        <React.Fragment>
          Uses placeset:
          <Grid container direction='row'>
            <Grid item xs={12}>
              <Box pl={3} my={0.5}>
                <ModeSelectorButton
                    mode={SelectionMode.PLACESET}
                    id={placeset.id}
                    label={placeset.name}
                    onModeSelect={onModeSelect}
                />
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>}
        { projects &&
        <React.Fragment>
          Projects using this placeset:
          <Grid container direction='row'>
            {projects.map((project)=> (
                <Grid item xs={12} key={project.id}>
                  <Box pl={3} my={0.5}>
                    <ModeSelectorButton
                        mode={SelectionMode.PROJECT}
                        id={project.id}
                        label={project.name}
                        onModeSelect={onModeSelect}
                    />
                  </Box>
                </Grid>
            ))}
          </Grid>
        </React.Fragment>}
      </React.Fragment>
  ) ;
}
