import mbxClient from '@mapbox/mapbox-sdk';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import {getLatitude, getLongitude, toRad, toDeg} from "geolib";

// NOTE: mapbox uses lonlat ordering: https://macwright.org/lonlat/
export function FeatureCollectionItem(lat, lon) {
  return {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [lon, lat],
    },
    "properties": {
      "title": "Mapbox DC",
      "icon": "monument",
    }
  }
}

export async function geocodePoiAddress(poiData) {
  const baseClient = mbxClient({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  });
  const geocodingClient = mbxGeocoding(baseClient);
  const query = [
    poiData.address,
    poiData.city,
    [poiData.region_code, poiData.postal_code].join(" "),
  ].filter(x => x).join(", ");
  const result = await geocodingClient.forwardGeocode({
    query: query,
    limit: 2,
    countries: [poiData.country_code].filter(x => x),
  }).send();
  if (result.body.features?.length > 0) {
    const [lon, lat] = result.body.features[0].center;
    return {
      longitude: lon,
      latitude: lat,
    }
  }
  return;
}
