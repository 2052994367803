import React from 'react';
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";
import CarApi from "../../api/CarApi";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import lodash from 'lodash';
import Grid from "@material-ui/core/Grid";
import ClearIcon from '@material-ui/icons/Clear';
import {nativeComparator} from "../../utils/utils";

/**
 * Render info box from a place
 * @param path - The path describing this area [country, region, city]
 * @param onDropPlace - Callback to remove this area
 */
function Place({place_id, place, onDropPlace}) {
  return (
      <Grid container style={{height:45}}>
        <Grid item xs={11}>
          <h4 style={{whiteSpace: "nowrap"}}>{place?.name}</h4>
          <h6 style={{ marginTop: "-2em", fontWeight: "bold" }}>
            {place?.address +
            (place?.city ? ', ' + place?.city : '') +
            (place?.region_code ? ', ' + place?.region_code : '') +
            (place?.country_code ? ', ' + place?.country_code : '')}
          </h6>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Remove from list">
            <IconButton
                color="primary"
                aria-label="add"
                component="span"
                onClick={()=>onDropPlace(place_id)}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
  );
}

export function PlacesetSettingsMazPoi({value, placeset, onChange}) {
  const [places, setPlaces] = React.useState([]);
  const [placeLut, setPlaceLut] = React.useState({});
  const [selectedPlaces, setSelectedPlaces] = React.useState([]);
  const [dropdownPlace, setDropdownPlace] = React.useState();
  const [loading, setLoading] = React.useState();
  const init = React.useCallback(async () => {
    // NOTE: Only supports first 1000 places
    setLoading(true);
    const res = await CarApi.getPlacesOffset({placeset_id: placeset?.id}, 0);
    setPlaces(res.data.data);
    setPlaceLut(lodash.keyBy(res.data.data, "place_id"));
    setLoading(false);
  }, [placeset?.id]);
  React.useEffect(() => {
    setSelectedPlaces(value);
  }, [value]);
  React.useEffect(() => {
    init();
  }, [init, placeset?.id]);
  const updateSelectedPlaces = (places) => {
    setSelectedPlaces(places);
    if (places !== value) {
      onChange(places);
    }
  }
  const addPlace = (value) => {
    setDropdownPlace(value);
    if (!value) return;
    const newPlaces = selectedPlaces.filter(area => !lodash.isEqual(area, value));
    newPlaces.push(value);
    updateSelectedPlaces(newPlaces.sort((a,b)=>nativeComparator(placeLut[a]?.name, placeLut[b]?.name)));
    setTimeout(() => {
      // Synchronously calling it would overwrite the previous change, leaving the value
      // as it was, ultimately cancelling resetting the dropdown. setTimeout ensures that
      // the reset happens.
      setDropdownPlace(undefined);
    }, 0);

  };
  const handleDropPlace = (value) => {
    updateSelectedPlaces(selectedPlaces.filter(place_id => !lodash.isEqual(place_id, value)));
  }
  return loading ? "Loading..." : (
      <React.Fragment>
        <Box p={1} bgcolor="#f0f0f0">
          <Grid container>
            <Grid item xs={12}>
              <AriAutocomplete
                  options={places}
                  onChange={addPlace}
                  value={dropdownPlace}
                  labelGetter={fieldGetter("name")}
                  valueGetter={fieldGetter("place_id")}
                  fullWidth
                  label="POI"
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={1}>
          <Paper>
            <Box p={1} style={{height:'300px', overflow:'auto'}}>
              {selectedPlaces.length === 0 && "No POI selected."}
              {selectedPlaces.map(place_id => <Place key={place_id} onDropPlace={handleDropPlace} place_id={place_id} place={placeLut[place_id]}/>)}
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
  );
}
