import React, {useEffect, useRef, useState} from "react";
import lodash from "lodash";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import {Box, Grid, Paper} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from "@material-ui/core/MenuItem";
import {
    buildFilterStringFromStruct,
    buildFilterStructFromString,
    getValuesets,
    PREFIX_FILTERS,
} from "../utils/filterFormUtils";
import {AriAutocomplete} from "../utils/formUtils";

const TEXT_DEBOUNCE_TIME_MS = 200;

export default function FilterForm({
    value: valueProp,
    onInputChange: onInputChangeProp,
    label: labelProp,
    toggleHelp: toggleHelpProp,
    reloadStat: reloadStatProp
}) {
    const [value, setValue] = useState(valueProp);
    const [filterList, setFilterList] = useState([]);
    const [nextFilterId, setNextFilterId] = useState(0);
    const [hasLocalChange, setHasLocalChange] = useState(false);
    const [filterIndex, setFilterIndex] = useState({});
    const [newFilterValue, setNewFilterValue] = useState("");
    const updateFilterIndex = (tempFilterList) => {
        const result = {};
        (tempFilterList||filterList).forEach( (expr, index) => {
            result[""+expr[0]] = index;
        });
        setNextFilterId(nextFilterId+1);
        setFilterIndex(result);
    };
    const updateValue = (valueProp) => {
        setValue(valueProp);
        try {
            const tempFilterList = buildFilterStructFromString(valueProp);
            setFilterList(tempFilterList);
            updateFilterIndex(tempFilterList);
        } catch {
            console.log("Invalid filter. Cannot parse");
            setFilterList([]);
            setNextFilterId(0);
        }
    };

    useEffect( updateFilterIndex, [filterList.length]);
    useEffect( () => updateValue(valueProp),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);
    useEffect(() => {
        if (!hasLocalChange && value !== valueProp) {
            updateValue(valueProp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueProp]);

    const onInputChangeDebounced = useRef(lodash.debounce((p1, p2) => {
        const flatValue = buildFilterStringFromStruct(p1);
        setValue(flatValue);
        setHasLocalChange(false);
        onInputChangeProp(flatValue, p2);
    }, TEXT_DEBOUNCE_TIME_MS));

    const onInputChangeText = (event) => {
        let newValue = event.target.value;
        const filterId = event.target.id;
        onInputChangeSelect(filterId, newValue);
    };
    const onInputChangeSelect = (id, value) => {
        if (value === null) {
            // Clicking X on the select sends null
            handleDelete(id);
        } else {
            const filterPos = filterIndex[id];
            filterList[filterPos][2] = value;
            setHasLocalChange(true);
            updateFilterList(filterList);
        }
    }

    const handleDelete = (filterId) => {
        const filterPos = filterIndex[filterId];
        filterList.splice(filterPos, 1);
        setHasLocalChange(true);
        updateFilterList(filterList);
    }

    const updateFilterList = (filterList) => {
        const newFilterList = lodash.clone(filterList);
        onInputChangeDebounced.current(newFilterList, {action:'input-change'});
        setFilterList(newFilterList);
    };

    const handleNewFilter = (event) => {
        const prefix = event.target.value;
        filterList.push([nextFilterId, prefix, ""]);
        const newFilterList = lodash.clone(filterList);
        setFilterList(newFilterList);
        setNewFilterValue("");
    };

    const onKeyDown = (event) => {
        if ((event.key === 'i' || event.key === 'I') && event.ctrlKey) {
            event.preventDefault();
            reloadStatProp();
        }
    };

    const onKeyPress = (event) => {
        if (event.key === '?') {
            // allow propagation, so hotkey detects it, but prevent
            // default so it wont show up in the input.
            event.preventDefault();
            toggleHelpProp();
        }
    };
    const options = getValuesets();
    return <React.Fragment>
        <Paper style={{width:'100%'}}>
            <Grid container>
                {filterList.map( ([filterId, filterPrefix, filterValue]) =>
                    <Grid item xs={12} lg={6} key={filterId}>
                        <Box px={0.5}>
                            { filterPrefix in options ? (
                                <AriAutocomplete
                                    id={""+filterId}
                                    autoComplete="new-password"
                                    placeholder={filterPrefix}
                                    margin="dense"
                                    fullWidth
                                    value={filterValue}
                                    options={options[filterPrefix]}
                                    labelGetter={(x)=>x}
                                    valueGetter={(x)=>x}
                                    onChange={(value) => onInputChangeSelect(filterId, value)}
                                    label={filterPrefix}
                                />
                            ) : (
                                <TextField
                                    id={""+filterId}
                                    autoComplete="new-password"
                                    placeholder={filterPrefix}
                                    margin="dense"
                                    fullWidth
                                    value={filterValue}
                                    onChange={onInputChangeText}
                                    onKeyPress={onKeyPress}
                                    onKeyDown={onKeyDown}
                                    label={filterPrefix}
                                    InputProps={{endAdornment:
                                        <IconButton
                                            size="small"
                                            tabIndex={-1}
                                            onClick={() => handleDelete(filterId)}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                )}
                <Grid item xs>
                    <Box px={0.5}>
                        <TextField
                            select
                            key={"add"}
                            margin="dense"
                            fullWidth
                            value={newFilterValue}
                            label={"Add new filter"}
                            onChange={handleNewFilter}
                        >
                            {PREFIX_FILTERS.map(option => (
                                <MenuItem key={option.prefix} value={option.prefix}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>

            </Grid>
        </Paper>
    </React.Fragment>
}
