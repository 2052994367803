import React from 'react';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import {Link, Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import CarApi from "../../api/CarApi";
import {MDAppBar} from "../MDAppBar";
import {Box, Tab, Tabs} from "@material-ui/core";
import {getSelectionModeInfo} from "../../utils/modeSelectorUtils";
import {ProjectSettingsMainTab} from "./ProjectSettingsMainTab";
import {PlacesetSettingsMazTab} from "./PlacesetSettingsMazTab";
import {PlacesetSettingsExtraFieldsTab} from "./PlacesetSettingsExtraFieldsTab";

export function ProjectSettings(props) {
  const history = useHistory();
  const project_id = props.match.params.project_id;
  const [project, setProject] = React.useState();
  const [modeInfo, setModeInfo] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const reload = React.useCallback(async (silent) => {
    if (!silent) {
      setLoading(true);
    }
    const res = await CarApi.getProject(project_id);
    const project = res.data;
    setProject(project);
    setModeInfo(await getSelectionModeInfo({project_id:project_id}));
    setLoading(false);
  }, [project_id]);
  React.useEffect(() => {
    reload();
  }, [reload]);
  const url = `/projects/${project_id}`;
  const handleBack = () => {
    history.push(url);
  };
  const handleUpdate = () => {
    reload(true);
  }
  const match = useRouteMatch("/projects/:id/settings/:view/");
  const has_placeset = !!project?.placeset?.id;
  const has_maz = CarApi.const.ProductIdCategories.MD_MAZ_PRODUCT_IDS.includes(project?.product_id);
  const display_maz_tab = has_maz || match?.params?.view === "maz";
  return !match ? (
      <Redirect to={`/projects/${project_id}/settings/main`}/>
  ) : (
      <Box display="flex" flexDirection="column" style={{height:"100vh"}}>
        <MDAppBar
            modeInfo={modeInfo}
        >Project {project?.name} settings</MDAppBar>
        <Box p={6} flexGrow={1} display="flex" flexDirection="column">
          <Typography variant="h4">Project settings</Typography>
          <Box>
            <Button
                onClick={handleBack}
            >
              &lt;&lt; Back to editor
            </Button>
          </Box>
          {!loading && <Tabs value={match.params.view}>
            {[
              <Tab key="main" label="Settings" value="main" component={Link} to={`/projects/${project_id}/settings/main`}/>,
              display_maz_tab && <Tab key="maz" label="MAZ" value="maz" component={Link} to={`/projects/${project_id}/settings/maz`}/>,
              <Tab key="fields" label="Extra POI Fields" value="fields" component={Link} to={`/projects/${project_id}/settings/fields`}/>,
            ]}
          </Tabs>}
          {!loading && <Switch>
            <Route path={`/projects/${project_id}/settings/main`} render={() => <ProjectSettingsMainTab project={project} onChange={handleUpdate}/>} />
            <Route path={`/projects/${project_id}/settings/maz`} render={() => (
                !has_maz ? (
                    "ERROR: This product type does not have MAZ"
                ) : <PlacesetSettingsMazTab project={project} onChange={handleUpdate}/>
            )} />
            <Route path={`/projects/${project_id}/settings/fields`} render={() => (
                !has_placeset ? (
                    "ERROR: This project does not have a placeset assigned yet. Create and assign a placeset first."
                ) : <PlacesetSettingsExtraFieldsTab placeset={project.placeset} onChange={handleUpdate}/>
            )} />
          </Switch>}
        </Box>
      </Box>
  );
}
