import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Tooltip
} from "@material-ui/core";
import {PREFERENCE_TYPES, useUserPreferences} from "../services/userpreferences-service";
import {Warning, Info} from "@material-ui/icons";
import {blue} from "@material-ui/core/colors";

// Borrowed from ES ariform
function FormIcon({iconComponent, tooltip, visible, action, style}) {
    if (visible === undefined) {
        visible = true;
    }
    return visible ? (
        tooltip ? (
            <Tooltip title={tooltip}>
                { React.createElement(iconComponent, {
                    onClick: action,
                    style: style,
                }) }
            </Tooltip>
        ) : React.createElement(iconComponent, {
            onClick: action,
            style: style,
        })
    ) : (
        // NOTE: This a hack
        <Warning style={{...style, opacity: 0}}/>
    );
};


export function PreferencesModal({open, onClose}) {
    const userPreferences = useUserPreferences();
    const [preferences, setPreferences] = useState({...userPreferences.allPreferences});
    useEffect(() => {
        setPreferences({...userPreferences.allPreferences})
        }
    , [userPreferences.allPreferences]);
    const updateValue = (value, key) => {
        const new_ = { ...preferences };
        new_[key] = value;
        setPreferences(new_);
    };
    const handleClose = () => {
        userPreferences.config.forEach( preferenceConfig => {
            let value = preferences[preferenceConfig.key];
            if (preferenceConfig.type === PREFERENCE_TYPES.NUMBER) {
                value = value|0;
            }
            preferences[preferenceConfig.key] = value;
        });
        userPreferences.savePreferences(preferences);
        onClose();
    };
    return (
        <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">User Settings</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { userPreferences.config.map( preferenceConfig => (
                        <Grid container style={{height:"40px"}} key={preferenceConfig.key}>
                            <Grid item xs={6}>
                                {preferenceConfig.label}
                                <FormIcon
                                    iconComponent={Info}
                                    visible={!!preferenceConfig.description}
                                    tooltip={preferenceConfig.description}
                                    style={{
                                        color: blue[500],
                                        verticalAlign:'bottom'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={preferences[preferenceConfig.key]}
                                    onChange={(event) => updateValue(event.target.value, preferenceConfig.key)}/>
                            </Grid>
                        </Grid>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
