import React from "react";
import {Avatar, Badge, Button, Chip, Grid, Paper, Tooltip, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import WrongLocationIcon from '../icons/WrongLocation';
import TextField from '@material-ui/core/TextField';
import {SelectionMode} from "../utils/modeSelectorUtils";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List, areEqual} from "react-window";
import memoize from 'memoize-one';
import {Link} from "react-router-dom";
import lodash from "lodash";
import {FeatureFlag} from "../constants";

const TEXT_DEBOUNCE_TIME_MS = 200;

const useStyles = makeStyles(theme => ({
  modeButton: {
    flexGrow: 1,
    justifyContent: "left",
  },
  selectorButton: {
    borderRadius: 0,
    '& .MuiButton-endIcon': {
      opacity: 0.05,
    },
    '&:hover .MuiButton-endIcon': {
      opacity: 0.6,
    },
  },
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: theme.spacing(2) + "px !important",
  },
  countChipSmall: {
    height: theme.spacing(1.75),
    marginLeft: theme.spacing(1),
    backgroundColor: "#88f",
    color: "#fff",
    paddingBottom: "1px",
    textTransform: "none",
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}));

const createItemData = memoize((items, filter, statsLut, toggleOpen, onModeSelect) => ({
  items,
  filter,
  statsLut,
  toggleOpen,
  onModeSelect,
}));

/**
 * Compute initial state
 * If there is only one client open it be default
 * All projects to be displayed by default
 * ? Currently active to be visible ?
 * @param placesets
 */
function computeInitialState(placesets) {
  const state = {};
  if (placesets.length === 1) {
    state[SelectionMode.CLIENT.selector + String(placesets[0].id)] = true;
  }
  placesets.forEach(client => {
    state[SelectionMode.PROJECT.selector + String(client.id)] = true;
  });
  return state;
}

const ListRow = React.memo(({data, index, style}) => {
  const classes = useStyles();
  const {items, filter, statsLut, toggleOpen, onModeSelect} = data;
  const {type, open, mode, indent, label, id, isActive} = items[index];
  const BaseButton = React.forwardRef(({indent, label, style, classes:classesProp, ...props}, ref) => {
    const hasBadge = props.hasOwnProperty("startIcon");
    return (
        <Button
            ref={ref}
            classes={{ label: classes.modeButton, ...classesProp}}
            style={{...style, paddingLeft:(16*indent-8+(hasBadge?4:0))}}
            {...props}
        >
          <Typography noWrap variant="button">
            {label}
          </Typography>
        </Button>
    )
  });
  const ToggleButton = ({mode, open, indent, label, id, ...props}) => {
    const objectName = SelectionMode.PROJECT === mode ? "projects" : "placesets";
    const tooltipText = `Show/hide the list of ${objectName} for this client`;
    return (
        <Tooltip title={tooltipText} arrow placement="right">
          <BaseButton
              indent={indent}
              label={label}
              onClick={() => toggleOpen(mode, id)}
              endIcon={open?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
              {...props}
          />
        </Tooltip>
    );
  };
  const SelectorButton = ({mode, indent, label, id, isActive, style, ...props}) => {
    const url = mode.path_template.replace(":id", id);
    const mergeUrl = `/placesets/${id}/settings/merge`;
    // NOTE: We both have a link AND eventHandler. Bit dirty, but does the job
    if (isActive) {
      style = {...style, backgroundColor:"#2ECC71"};
    }
    const stats = statsLut[statsKey(mode, id)];
    const placeCount = (
        stats?.place_count === undefined ?
            null :
            (
                stats?.place_count === 0 ?
                    "empty" :
                    "∑" + stats?.place_count
            )
    );
    const outstandingMerge = getOutstandingMergeCountFromStats(stats);
    return (
        <Link to={url}>
          <Tooltip title={mode.description} arrow placement="right">
            <BaseButton
                classes={{root: classes.selectorButton}}
                indent={indent}
                label={<React.Fragment>
                  {label}
                  {placeCount!==null && <Chip
                      className={classes.countChipSmall}
                      size="small"
                      label={placeCount}
                  />}
                  {outstandingMerge>0 && <Tooltip title={`Has ${outstandingMerge} merge event(s)`}>
                    <Link to={mergeUrl}>
                      <Badge color="primary">
                        <MergeTypeIcon />
                      </Badge>
                    </Link>
                  </Tooltip>}
                </React.Fragment>}
                onClick={()=>onModeSelect(mode.selector?{[mode.selector]:id}:{},filter?.overlap || false)}
                startIcon={<Avatar className={classes.small}>{mode.avatar_letter}</Avatar>}
                style={style}
                {...props}
            />
          </Tooltip>
        </Link>
    );
  };
  return type === ItemType.SELECTOR ?
      <SelectorButton mode={mode} indent={indent} label={label} id={id} style={style} isActive={isActive}/>
      : <ToggleButton mode={mode} open={open} indent={indent} label={label} id={id} style={style}/>
}, areEqual);

export const ItemType = Object.freeze({
  SELECTOR:   Symbol("selector"),
  TOGGLE:  Symbol("toggle"),
});

function ModeFilter({value:valueProp, onChange}) {
  const [nameFilter, setNameFilter] = React.useState("");
  const [mergeFilter, setMergeFilter] = React.useState(false);
  const [overlapFilter, setOverlapFilter] = React.useState(false);
  const [hasLocalChange, setHasLocalChange] = React.useState(false);
  React.useEffect(() => {
    if (!hasLocalChange) {
      setNameFilter(valueProp?.name || "");
      setMergeFilter(valueProp?.merge || false);
      setOverlapFilter(valueProp?.overlap || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp]);
  const onChangeDebounced = React.useCallback(lodash.debounce((nameFilter, mergeFilter, overlapFilter) => {
    setHasLocalChange(false);
    onChange({
      name: nameFilter,
      merge: mergeFilter,
      overlap: overlapFilter,
    });
  }, TEXT_DEBOUNCE_TIME_MS), [onChange]);
  const handleChange = (newValue) => {
    setHasLocalChange(true);
    onChangeDebounced(newValue, mergeFilter, overlapFilter);
    setNameFilter(newValue);
  }
  const handleToggleMergeFilter = (newValue) => {
    setHasLocalChange(true);
    onChangeDebounced(nameFilter, !mergeFilter, overlapFilter);
    setMergeFilter(!mergeFilter);
  }
  const handleToggleOverlapFilter = (newValue) => {
    setHasLocalChange(true);
    onChangeDebounced(nameFilter, mergeFilter, !overlapFilter);
    setOverlapFilter(!overlapFilter);
  }
  return (
      <Grid container={true}>
        <React.Fragment>
          <TextField
              style={{
                height: 34,
                paddingLeft: 8,
                paddingRight: 8,
                flexGrow: 1,
                marginTop: '6px',
              }}
              value={nameFilter}
              onChange={(event)=>handleChange(event.target.value)}
              InputProps={{
                endAdornment : (
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleChange("")}
                    >
                      <ClearIcon/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
          />
          {FeatureFlag.overlap && <Tooltip title="Filter placesets with overlapping places">
            <Button
                color={overlapFilter?"primary":undefined}
                variant={overlapFilter?"contained":undefined}
                style={{
                  minWidth:"0px",
                  padding:"6px",
                }}
                onClick={handleToggleOverlapFilter}
            >
              <WrongLocationIcon/>
            </Button>
          </Tooltip>}
          {FeatureFlag.merge && <Tooltip title="Filter placesets with merge tasks">
            <Button
                color={mergeFilter?"primary":undefined}
                variant={mergeFilter?"contained":undefined}
                style={{
                  minWidth:"0px",
                  padding:"6px",
                }}
                onClick={handleToggleMergeFilter}
            >
              <MergeTypeIcon/>
            </Button>
          </Tooltip>}
        </React.Fragment>
      </Grid>
  );
}

/**
 * Determine if an object matches the filter
 * If no filter is defined that is a no match
 * @param object
 * @param filter
 * @returns {boolean}
 */
function filterMatcher(object, filter) {
  const nameFilter = filter?.name || "";
  const mergeFilter = filter?.merge || false;
  const overlapFilter = filter?.overlap || false;
  const hasNameFilter = nameFilter !== "";
  const hasMergeFilter = mergeFilter !== false;
  const hasOverlapFilter = overlapFilter !== false;
  const hasAnyFilter = hasNameFilter || hasMergeFilter || hasOverlapFilter;
  const nameMatch = hasNameFilter && object.name && object.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1;
  const mergeMatch = hasMergeFilter && getOutstandingMergeCountFromStats(object.stats) > 0;
  const overlapMatch = hasOverlapFilter && object.is_valid !== undefined && !object.is_valid;
  return hasAnyFilter && (!hasNameFilter || nameMatch) && (!hasMergeFilter || mergeMatch) && (!hasOverlapFilter || overlapMatch);
}

/**
 * @param selectionMode SelectionMode
 * @param id Integer
 */
function statsKey(selectionMode, id) {
  return `${selectionMode.key}:${id}`;
}


function generateStatsLut(placesets) {
  const statLut = {};
  placesets.forEach(client => {
    client.placesets.forEach(placeset => {
      statLut[statsKey(SelectionMode.PLACESET, placeset.id)] = placeset.stats
    });
  });
  placesets.forEach(client => {
    client.projects.forEach(project => {
      if (project.placeset_id) {
        statLut[statsKey(SelectionMode.PROJECT, project.id)] = statLut[statsKey(SelectionMode.PLACESET, project.placeset_id)];
      }
    });
  });
  return statLut;
}


/**
 * Get number of records that are not yet resolved for merge
 * @param stats
 * @returns {integer}
 */
function getOutstandingMergeCountFromStats(stats) {
  const outstandingMerge = (stats?.merge_count?.hold||0) + (stats?.merge_count?.undecided||0);
  return FeatureFlag.merge ? outstandingMerge : 0;
}


export default function ModeSelectorListComponent({placesets, onModeSelect, modeInfo}) {
  const [openState, setOpenState] = React.useState({});
  const [statsLut, setStatsLut] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [list, setList] = React.useState([]);
  const isOpen = React.useCallback((mode, id) => {
    return openState[mode.selector + String(id)] || false;
  }, [openState]);
  const generateList = React.useCallback(() => {
    function item(type, open, mode, indent, label, id) {
      const isActive = id === modeInfo.selection_id && modeInfo.selection_mode === mode;
      return {
        type: type,
        open: open,
        mode: mode,
        indent: indent,
        label: label,
        id: id,
        isActive: isActive,
      }
    }
    const hasFilter = !!filter?.name || !!filter?.merge || !!filter?.overlap;
    const result = [];
    if (!hasFilter) {
      result.push(item(ItemType.SELECTOR, null, SelectionMode.ALL, 1, "All Placesets"));
    }
    placesets.forEach(client => {
      const cOpen = isOpen(SelectionMode.CLIENT, client.id);
      const dOpen = isOpen(SelectionMode.PLACESET, client.id);
      const psetItems = client.placesets.map(placeset =>
          ((dOpen || filterMatcher(placeset, filter)) &&
              item(ItemType.SELECTOR, null, SelectionMode.PLACESET, 3, placeset.name, placeset.id))
      ).filter(x=>!!x);
      const pOpen = isOpen(SelectionMode.PROJECT, client.id);
      const projItems = client.projects.map(project =>
          ((pOpen || filterMatcher(project, filter)) &&
              item(ItemType.SELECTOR, null, SelectionMode.PROJECT, 3, project.name, project.id))
      ).filter(x=>!!x);
      const cOpenOrSub = cOpen || (hasFilter && (psetItems.length || projItems.length || filterMatcher(client, filter)));
      if (!hasFilter || cOpenOrSub) {
        result.push(item(ItemType.TOGGLE, cOpen, SelectionMode.CLIENT, 1, client.name, client.id));
      }
      if (cOpenOrSub) {
        if (cOpen || filterMatcher(client, filter)) {
          result.push(item(ItemType.SELECTOR, null, SelectionMode.CLIENT, 2, "All Placesets", client.id));
        }
        if (client.placesets.length) {
          result.push(item(ItemType.TOGGLE, dOpen, SelectionMode.PLACESET, 2, "Placesets", client.id));
          result.push(...psetItems);
        }
        if (client.projects.length) {
          result.push(item(ItemType.TOGGLE, pOpen, SelectionMode.PROJECT, 2, "Projects", client.id));
          result.push(...projItems);
        }
      }
    });
    return result;
  }, [placesets, isOpen, filter]);
  React.useEffect(() => {
    const value = (!!filter)?{}:computeInitialState(placesets)
    setOpenState(value);
  }, [!!filter, placesets]);
  React.useEffect(() => {
    setStatsLut(generateStatsLut(placesets))
  }, [placesets]);
  React.useEffect(() => {
    setList(generateList());
  }, [openState, placesets, generateList, filter, modeInfo]);
  React.useEffect(() => {
    const nameFilterValue = modeInfo.client_id && modeInfo.title;
    setFilter({
      name: nameFilterValue || "",
      merge: false,
      overlap: false,
    });
  }, [modeInfo]);
  const toggleOpen = (mode, id) => {
    openState[mode.selector + String(id)] = !openState[mode.selector + String(id)];
    setOpenState(Object.assign({}, openState));
  };
  return (
      <Paper style={{height:'100%',maxHeight:'100%'}}>
        <ModeFilter
          value={filter}
          onChange={setFilter}
          />
        <AutoSizer>
          {({height, width}) => (
            <List
                height={height}
                itemCount={list.length}
                itemData={createItemData(list, filter, statsLut, toggleOpen, onModeSelect)}
                itemSize={34}
                width={width}
            >
              {ListRow}
            </List>
          )}
        </AutoSizer>
      </Paper>
  );
}
