// Defines a parser for filter strings.
// The grammar of such strings should be:
//     prefix := [a-z][a-z_]*':'
//     value :=
//         ['][^']+[']
//         ["][^"]+["]
//         [^'" ]*
//     values :=
//         value
//         value? ',' values
//     filter := prefix? values?
//     filterexpr := filter*
//
// TODO: Should add support for negation, aka dont match something
//   -brand:Disney => match where brand is not Disney
//   candiates are dash or exclamation mark
// TODO: Exact / starts with / ends with / contains match support
//   Now all string matcher is contains with. Should add in alternative matchers.
//   For exact match using quotes is a good candidate
//   Adding in % into quoted string can loosen the exact constraint on that end.
// TODO: Exceptions should contain information about the location of the error

export const TokenType = Object.freeze({
    PREFIX: 'PREFIX',
    VALUE: 'VALUE',
    LIST_SEPARATOR: 'LIST_SEPARATOR',
    WHITESPACE: 'WHITESPACE',
    END: 'END',
});

//NOTE: exported for testing
export class Token {
    constructor(type, value, start, end) {
        this.type = type;
        this.value = value;
        this.start = start;
        this.end = end;
    }
}

class Tokenizer {
    constructor(str) {
        this.str = str;
        this.pos = 0;
        this._processNextToken();
    }

    _isWS(chr) {
        return chr && chr.trim() === '';
    }

    _peekChar() {
        return this.pos < this.str.length && this.str[this.pos];
    }

    _consumeChar() {
        const chr = this._peekChar();
        if (chr !== false) {
            this.pos++;
        }
        return chr;
    }

    peek() {
        return this.token;
    }

    consume() {
        const token = this.token;
        this._processNextToken();
        return token;
    }

    consumeWS() {
        while (this.peek().type === TokenType.WHITESPACE) {
            this.consume();
        }
    }

    _processNextToken() {
        const startPos = this.pos;
        const firstCh = this._consumeChar();
        let value = "";
        let type = TokenType.VALUE;
        if (firstCh === false) {
            type = TokenType.END;
        } else if (this._isWS(firstCh)) {
            while (this._isWS(this._peekChar())) {
                this._consumeChar();
            }
            type = TokenType.WHITESPACE;
        } else if (firstCh === ',') {
            type = TokenType.LIST_SEPARATOR;
        } else if (('"\'').indexOf(firstCh) > -1) {
            while (true) {
                const nextCh = this._consumeChar();
                if (nextCh === false || nextCh === firstCh) {
                    // NOTE: We accept unterminated if at the end
                    break;
                }
                value += nextCh;
            }
        } else {
            value += firstCh;
            while (true) {
                const nextCh = this._peekChar();
                if (nextCh === false || this._isWS(nextCh) || nextCh === ',') {
                    break;
                }
                this._consumeChar();
                if (nextCh === ":") {
                    type = TokenType.PREFIX;
                    break;
                }
                value += nextCh;
            }
        }
        this.token = new Token(type, value, startPos, this.pos);
    }
}

/**
 * Convert string to tokens. USED FOR TESTING!
 * @param expr
 * @returns {[]}
 */
export function tokenize(expr) {
    const tokenizer = new Tokenizer(expr);
    let result = [];
    while(true) {
        const token = tokenizer.consume();
        result.push(token);
        if (token.type === TokenType.END) {
            break;
        }
    }
    return result;
}

/**
 * Describes a single rule in the filter
 */
class FilterRule {
    /**
     * @param prefix The prefix
     * @param values A list of string values to filter for
     */
    constructor(prefix, values) {
        this.prefix = prefix;
        this.values = values;
    }
}

/**
 * Parse filter into a dictionary
 * See parseFilterList for details about the filter expression.
 * Uses combineFilterList to transform output of the former into a dictionary.
 *
 * E.g.:
 *   brand:nokia brand:samsung city:detroit
 * returns:
 *   {
 *       brand: ['nokia', 'samsung],
 *       city: ['detroit']
 *   }
 * means:
 *   Filter POIs where brand is either nokia or samsung AND city is detroit.
 * @param str The expression to parse
 * @returns {{}} The filter dictionary
 */
export function parseFilter(str) {
    return combineFilterList(parseFilterList(str));
}

/**
 * Parse filter into a list of FilterRules.
 * The filter expression is a set of values with optional prefixes
 * Missing prefix aka default prefix is denoted by "".
 * The parser returns a list, items being prefixes and the values to filter for.
 * Values are either a single string or a list of strings
 *
 * E.g.:
 *   brand:nokia brand:samsung city:detroit,dakota
 * returns:
 *   [
 *       ['brand', ['nokia']],
 *       ['brand', ['samsung']],
 *       ['city', ['detroit', 'dakota']],
 *   ]
 * means:
 *   Filter POIs where brand is either nokia or samsung AND city is detroit.
 *   - values for the same prefix should be combined with OR
 *   - values for different prefixes should be combined with AND
 * @param str The expression to parse
 * @returns {{}} The filter list
 */
export function parseFilterList(str) {
    const result = [];
    const tokenizer = new Tokenizer(str);
    while (true) {
        tokenizer.consumeWS();
        let token = tokenizer.consume();
        let prefix = "";
        let values = undefined;
        if (token.type === TokenType.END) {
            break;
        } else if (token.type === TokenType.LIST_SEPARATOR) {
            continue;
        } else if (token.type === TokenType.PREFIX) {
            prefix = token.value;
            values = [];
            while (true) {
                tokenizer.consumeWS();
                token = tokenizer.peek();
                if (token.type === TokenType.END || token.type === TokenType.PREFIX) {
                    if (values.length === 0) {
                        values.push("");
                    }
                    break;
                } else if (token.type === TokenType.VALUE) {
                    values.push(tokenizer.consume().value);
                    tokenizer.consumeWS();
                    if (tokenizer.peek().type === TokenType.LIST_SEPARATOR) {
                        tokenizer.consume();
                    } else {
                        break;
                    }
                } else if (token.type === TokenType.LIST_SEPARATOR) {
                    tokenizer.consume();
                    values.push("");
                } else {
                    throw new Error("Unhandled token type");
                }
            }
        } else if (token.type === TokenType.VALUE) {
            values = [token.value];
        } else {
            throw new Error("Unhandled token type");
        }
        result.push(new FilterRule(prefix, values));
    }
    return result;
}

/**
 * Combine a list of FilterRules into a dictionary of {prefix:values}
 * E.g.:
 *   [
 *       ['brand', ['nokia']],
 *       ['brand', ['samsung']],
 *       ['city', ['detroit']],
 *   ]
 * returns:
 *   {
 *       brand: ['nokia', 'samsung'],
 *       city: ['detroit'],
 *   }
 *
 * @param filterList The output from parseFilterList
 */
export function combineFilterList(filterList) {
    const result = {};
    filterList.forEach( (rule) => {
        if (!(rule.prefix in result)) {
            result[rule.prefix] = [];
        }
        result[rule.prefix].push(...rule.values);
    });
    return result;
}
