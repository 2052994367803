import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

// hack
// need to clone the event to be able to dispatch again
// https://stackoverflow.com/a/48063808/1442987
var cloneNativeMouseEvent = function (original) {
    var copy;
    try {
        copy = new MouseEvent(original.type, original);
    } catch (stupidInternetExplorer) {
        copy = document.createEvent('MouseEvent');
        copy.initMouseEvent(original.type, original.bubbles, original.cancelable, original.view,
            original.detail, original.screenX, original.screenY, original.clientX, original.clientY,
            original.ctrlKey, original.altKey, original.shiftKey, original.metaKey, original.button,
            original.relatedTarget)
    }
    return copy;
};
function replayEvent(event) {
    var originalEventClone = cloneNativeMouseEvent(event.originalEvent);
    event.target._canvas.dispatchEvent(originalEventClone);
}
// end of hack

const SimpleSelect = MapboxDraw.modes.simple_select;
const _clickOnVertex = SimpleSelect.clickOnVertex;

// Override to enable instant drag, without selecting first
SimpleSelect.clickOnVertex = function(state, e) {
    _clickOnVertex.call(this, state, e);
    // Rerun the event, this time, it will be handled by the direct_select handler (user can drag immediately)
    e.preventDefault();
    replayEvent(e);
};

SimpleSelect.onMouseDown = function(state, e) {
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) return this.clickOnVertex(state, e);
    // NOTE: Added in Control or Meta key condition, so dragging on the polygon will not move it, it will pan the map
    if (CommonSelectors.isActiveFeature(e) && (e.originalEvent.ctrlKey || e.originalEvent.metaKey)) return this.startOnActiveFeature(state, e);
    if (this.drawConfig.boxSelect && CommonSelectors.isShiftMousedown(e)) return this.startBoxSelect(state, e);
};

export default SimpleSelect;
