import React from 'react';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import {Link, Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import CarApi from "../../api/CarApi";
import {MDAppBar} from "../MDAppBar";
import {
    Box, Tab, Tabs,
} from "@material-ui/core";
import {getSelectionModeInfo} from "../../utils/modeSelectorUtils";
import {PlacesetSettingsMergeTab} from "./PlacesetSettingsMergeTab";
import {PlacesetSettingsMainTab} from "./PlacesetSettingsMainTab";
import {FeatureFlag} from "../../constants";
import {PlacesetSettingsMazTab} from "./PlacesetSettingsMazTab";
import {PlacesetSettingsExtraFieldsTab} from "./PlacesetSettingsExtraFieldsTab";

export function PlacesetSettings(props) {
  const history = useHistory();
  const placeset_id = props.match.params.placeset_id;
  const [placeset, setPlaceset] = React.useState();
  const [modeInfo, setModeInfo] = React.useState({});
  const refresh = React.useCallback(async () => {
    const res = await CarApi.getPlaceset(placeset_id);
    const placeset = res.data;
    setPlaceset(placeset);
    setModeInfo(await getSelectionModeInfo({placeset_id:placeset_id}));
  }, [placeset_id]);
  React.useEffect(() => {
    refresh();
  }, [refresh]);
  const url = `/placesets/${placeset_id}`;
  const handleBack = () => {
    history.push(url);
  };
  const handleUpdate = async () => {
    refresh();
  };
  const match = useRouteMatch("/placesets/:id/settings/:view/");
  return !match ? (
      <Redirect to={`/placesets/${placeset_id}/settings/main`}/>
    ) : (
      <Box display="flex" flexDirection="column" style={{height:"100vh"}}>
        <MDAppBar
            modeInfo={modeInfo}
        >{`Placeset ${placeset?.name} (#${placeset_id}) settings`}</MDAppBar>
        <Box p={6} flexGrow={1} display="flex" flexDirection="column">
          <Typography variant="h4">Placeset settings</Typography>
          <Box>
            <Button
                onClick={handleBack}
            >
              &lt;&lt; Back to editor
            </Button>
          </Box>
          <Tabs value={match.params.view}>
            {[
              <Tab key="main" label="Settings" value="main" component={Link} to={`/placesets/${placeset_id}/settings/main`}/>,
              <Tab key="maz" label="MAZ" value="maz" component={Link} to={`/placesets/${placeset_id}/settings/maz`}/>,
              <Tab key="fields" label="Extra POI Fields" value="fields" component={Link} to={`/placesets/${placeset_id}/settings/fields`}/>,
              FeatureFlag.merge && <Tab key="merge" label="Merge tasks" value="merge" component={Link} to={`/placesets/${placeset_id}/settings/merge`}/>,
            ]}
          </Tabs>
          <Switch>
            <Route path={`/placesets/${placeset_id}/settings/main`} render={() => <PlacesetSettingsMainTab placeset={placeset} onChange={handleUpdate}/>} />
            <Route path={`/placesets/${placeset_id}/settings/maz`} render={() => <PlacesetSettingsMazTab placeset={placeset} onChange={handleUpdate}/>} />
            <Route path={`/placesets/${placeset_id}/settings/fields`} render={() => <PlacesetSettingsExtraFieldsTab placeset={placeset} onChange={handleUpdate}/>} />
            <Route path={`/placesets/${placeset_id}/settings/merge`} render={() => <PlacesetSettingsMergeTab placeset_id={placeset_id}/>} />
          </Switch>
        </Box>
      </Box>
  );
}
