import React, {useState} from 'react';
import CarApi from "../api/CarApi";
import lodash from 'lodash';

/**
 * UserPreferences service
 *
 * Setup:
 * You should wrap your entire application into the UserPreferencesProvider. You can only access the functionallity
 * from code that is inside this tag.
 *     <UserPreferencesProvider>
 *         <App />
 *     </UserPreferencesProvider>
 * The UserPreferencesProvider has two parameters to provide read and write operations for the preferences. These can
 * be used to persist the preferences onto a server.
 *
 * Usage:
 * If you are using a functional component, you can use the useUserPreferences hook to access the preferences object.
 * If you are working with class based components, the UserPreferences component is they way to go.
 * Both approaches provide you with a preferences object that allows you to access/update the preferences.
 * For members of this preferences object, see the documentation of the useUserPreferences hook.
 * For usage example, see the documentation of the function in question.
 *
 * @type {{NUMBER: number, STRING: number, BOOLEAN: number}}
 */

function PreferenceType(key, validator) {
    return {
        key: key,
        validator: validator,
    };
}

export const PREFERENCE_TYPES = {
  STRING: PreferenceType('STRING', lodash.isString),
  BOOLEAN: PreferenceType('BOOLEAN', lodash.isBoolean),
  NUMBER: PreferenceType('NUMBER', lodash.isNumber),
};

function Preference(key, type_, default_, label, description) {
  return {
    key: key,
    label: label || key,
    description: description,
    type: type_,
    default: default_,
  };
}

/**
 * Add the preferences here
 * The Preferences service uses this configuration to ensure, that only those preferences that are defined here
 * can be accessed from within the application. It also ensures, that the preferneces have the proper type set.
 */
const PREFERENCES_CONFIG = [
  Preference('test', PREFERENCE_TYPES.NUMBER, 42),
  Preference('place_initial_zoom', PREFERENCE_TYPES.NUMBER, 90, "Place initial zoom", "How much % of the viewpane should be filled by the Place.\n100 - leave no padding,\n10 - place should be only 10%."),
];

const { createContext, useContext } = React;

const UserPreferencesContext = createContext(null);

export const UserPreferencesProvider = (props) => {
  const value = {
    _queryUserPreferences: props.queryUserPreferences || (async () => {
      const resp = await CarApi.getUserPreferences();
      return resp;
    }),
    _updateUserPreferences: props.updateUserPreferences || (async (data) => {
      const resp = await CarApi.updateUserPreferences(data);
      return resp;
    }),
    _listeners: new Set(),
  };
  value.buildState = buildState.bind(value);
  value.loadUserPreferences = loadUserPreferences.bind(value);
  value.saveUserPreferences = saveUserPreferences.bind(value);
  value.validateUserPreferences = validateUserPreferences.bind(value);
  value.updateUserPreferences = updateUserPreferences.bind(value);
  value.updateUserPreferences({});
  setTimeout(value.loadUserPreferences, 0);
  return (
    <UserPreferencesContext.Provider value={value}>
      {props.children}
    </UserPreferencesContext.Provider>
  );
};

/**
 * Preferences accessor component
 *
 * NOTE: If you are using Object components, you cannot use the useUserPreferences
 * hook. Instead create a UserPreferences component, and add a function inside. This
 * function will receive the same response as parameter what useUserPreferences
 * returns. So if you want to display the `test` proprety, you can do it with:
 *     <UserPreferences>
 *         {(preferences) => (
 *             <b>The value of the test property is: {preferences.test}</b>
 *         )}
 *     </UserPreferences>
 */
export const UserPreferences = ({children}) => {
    const preferences = useUserPreferences();
    return children(preferences);
}

/**
 * Get user preferences hook
 * The return value auto-updates when saved (TBD)
 * The fields in the result:
 *   - ready {boolean} If the data is loaded
 *   - allPreferences {Object} A dictionary with all the preferences
 *   - savePreferences {Function} Persist the preferences provided as the parameter, and update them locally.
 *   - *preference-name* {*preference-type*} Any preference defined in the PREFERENCES_CONFIG
 * Usage:
 * Within your functional component, just call the hook and use the results.
 * E.g.: (assuming you have a preference called `listItemNum`)
 *     function RenderItemNumPreferenceComponent() {
 *         const preferences = useUserPreferences();
 *         return (<b> {preferences.listItemNum} </b>);
 *     }
 * @returns {Object} The preferences object
 */
export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);
  const [userPreferences, setUserPreferences] = useState(context._state);
  context._listeners.add(setUserPreferences);
  return userPreferences;
};


/**
 * User preferences HOC
 *
 * To be used with class components
 * Wrap your class component into this, and you will have userPreferences available as a prop
 * Usage:
 *   class Demo extends Component {
 *     constructor(props) {
 *       super(props);
 *       this.pref = props.userPreferences
 *     }
 *     render() {
 *       return (<div>{this.pref.testSetting}</div>)
 *     }
 *   }
 *   PrefDemo = withUserPreferences(Demo);
 *
 * @param BaseComponent The component to be used with UserPreferences
 * @returns {} BaseComponent injected with userPreferences property
 */
export const withUserPreferences = BaseComponent => props => {
    const userPreferences = useUserPreferences();
    return <BaseComponent
        {...props}
        userPreferences={userPreferences}
    />;
};


/**
 * Helper to create state object returned to users
 * @param apiData {object} The key-value dict with user preferences
 * @returns {{allPreferences: *, savePreferences: saveUserPreferences, ready: *, config: *}}
 */
function buildState(apiData) {
    const ready = Object.keys(apiData).length > 0;
    const preferences = {};
    const configMap = {};
    PREFERENCES_CONFIG.forEach( item => {
        preferences[item.key] = (ready && item.key in apiData)?apiData[item.key]:item.default;
        configMap[item.key] = item;
    });
    const result = {
        ready: ready,
        config: PREFERENCES_CONFIG,
        configMap: configMap,
        allPreferences: preferences,
        savePreferences: this.saveUserPreferences,
    };
    Object.keys(preferences).forEach( key => {
        result[key] = preferences[key];
    });
    return result;
}

/**
 * Validate preferences
 * Throws error for invalid values
 * @param preferences {object} The key-value dict with user preferences.
 */
function validateUserPreferences(preferences) {
    Object.keys(preferences).forEach((key) => {
        const value = preferences[key];
        const config = this._state.configMap[key];
        if (value !== null && !config.type.validator(value)) {
            throw new Error("Preference set to invalid value");
        }
    });
}
/**
 * Save preferences to the remote, and update them locally on success
 * @param preferences {object} The key-value dict with user preferences. Can be incremental.
 * @returns {Promise<void>}
 */
async function saveUserPreferences(preferences) {
    this.validateUserPreferences(preferences);
    const newState = Object.assign({}, this._state.allPreferences);
    Object.assign(newState, preferences);
    await this._updateUserPreferences(newState);
    await this.loadUserPreferences();
}

/**
 * Update local state from preferences
 * Recalculates state and notifies listerners.
 * @param preferences {object} The key-value dict with user preferences
 */
function updateUserPreferences(preferences) {
    this._data = preferences;
    this._state = this.buildState(this._data);
    for (let setUserPreferencesListener of this._listeners) {
        setUserPreferencesListener(this._state);
    }
}

/**
 * Load preferences from the remote and update local status
 */
async function loadUserPreferences() {
    try {
        const resp = await this._queryUserPreferences();
        this.updateUserPreferences(resp.data);
    } catch (err) {
        // nop
    }
}
