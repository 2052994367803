
export function nativeComparator(a, b) {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

export function fixAutocompleteBug(x) {
  // Used for Autocomplete
  // Original code uses off for the autoComplete value, that is not cool
  x["inputProps"]["autoComplete"] = "new-password";
  return x
}

/**
 * Render list in format "1, 2 or 3"
 * @param values
 * @return {string}
 */
export function prettyArrayJoin(values) {
  return [
    values.slice(0, -1).join(', '),
    ...values.slice(-1)
  ].filter(x=>x!=="").join(' or ');
}

/**
 * Trim too long strings to fix length with ellipsis
 * @param value String to trim
 * @param length (required) Max length
 * @param ellipsis (optional)
 * @returns {string} Result
 */
export function trimWithEllipsis(value, length, ellipsis="\u2026") {
  return value.length <= length ? value : (value.substr(0, length - ellipsis.length) + ellipsis);
}

// creates a random hash to assign to geo features drawn on the map
export function createRandomUID() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

//To check if attribute is an array and is empty
export function isEmptyArray(array){
  return Array.isArray(array) && array.length === 0;
}

/**
 * Make string css selector friendly
 * Removes non alphanumerics/underscore, replaces spaces with dashes
 * Inspired by https://stackoverflow.com/questions/7627000
 * @param value
 */
export function normalizeForCss(value) {
  return value.toLowerCase().replace(/[^ a-z0-9_]/g, '').replace(/ /g, '-');
}
