import * as MapBox from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React from "react";
import ReactMapboxGl, {
  GeoJSONLayer,
} from "react-mapbox-gl";
import {
    getCircleBoundingBox,
    getGeoJsonBoundingBox,
    getMetaBoundingBox,
    scaleBoundingBox
} from "../utils/geoUtils";
import {boolToCssVisibility} from "./utils";

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
MapBox.accessToken = accessToken;
const ReactMap = ReactMapboxGl({
  accessToken
});

const poiMarker = new MapBox.Marker();

const INITIAL_COORDINATE = [-98.5795, 39.8283];

const DEFAULT_POLYGON_COLOR = "blue";

/**
 * lat, lon
 * polygons
 * @param selectedPOI
 * @return {*}
 * @constructor
 */
export default function StaticPlaceGeoMap({
                              location,  // geolib compatible (e.g.:{lat,lon})
                              geos,      // [{geo_text,color}]
                              geoColor,  // fallback color if geo misses one
                              height,  // NOTE: If using '100%' renders 0px
                              width,
                            }) {
  // TODO: Render locations as points
  const [polygons, setPolygons] = React.useState([]);
  const [bbox, setBbox] = React.useState(getCircleBoundingBox(INITIAL_COORDINATE, 2700000));
  React.useEffect(() => {
      const polygons = (geos||[]).map(geo => {
          return {
              type: "Feature",
              properties: {
                  color: geo.color || geoColor || DEFAULT_POLYGON_COLOR,
              },
              geometry: JSON.parse(geo.geo_text),
          };
      });
      setPolygons(polygons);
  }, [geos, geoColor]);
  React.useEffect(() => {
      let bbox = getMetaBoundingBox([
          location?getCircleBoundingBox(location, 20):null,
          ...polygons.map(polygon => getGeoJsonBoundingBox(polygon.geometry)),
      ].filter(x=>x!=null));
      if (!bbox) {
          bbox = getCircleBoundingBox(INITIAL_COORDINATE, 2700000);
      }
      setBbox(scaleBoundingBox(bbox, 100/80));
  }, [polygons, location]);
  const onStyleLoad = map => {
    // Disable map rotation using right click + drag
    map.dragRotate.disable();
    // Disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();
    map.resize();
    // Useful:
    // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-hover/
  };

  return (
      <div>
        <ReactMap
            accessToken={accessToken}
            style={'mapbox://styles/mapbox/satellite-streets-v10'}
            fitBounds={bbox}
            fitBoundsOptions={{ padding: 0, animate: false }}
            movingMethod="jumpTo"
            containerStyle={{
              width: width||"100%",
              height: height||"100%",
            }}
            onStyleLoad={onStyleLoad}
        >
          <GeoJSONLayer
              data={{
                  type: 'FeatureCollection',
                  features: polygons,
              }}
              fillLayout={{visibility: boolToCssVisibility(true) }}
              fillPaint={{
                "fill-color": ["get", "color"],
                "fill-outline-color": "white",
                "fill-opacity": 0.33,
              }}
              lineLayout={{visibility: boolToCssVisibility(true) }}
              linePaint={{
                "line-color": ["get", "color"],
                "line-width": 4,
                "line-dasharray": [2, 2],
              }}
              circleLayout={{visibility: boolToCssVisibility(true) }}
              circlePaint={{
                "circle-color": ["get", "color"],
              }}
          />
          {/*<MapContext.Consumer>*/}
          {/*  {map => {*/}
          {/*    if (selectedPOI) {*/}
          {/*      if (poiMarker) {*/}
          {/*        poiMarker.remove();*/}
          {/*      } //remove marker if exists*/}
          {/*      //only add marker when no geos*/}
          {/*      poiMarker*/}
          {/*          .setLngLat([selectedPOI.lon, selectedPOI.lat])*/}
          {/*          .addTo(map);*/}
          {/*      }*/}
          {/*    }*/}
          {/*  }}*/}
          {/*</MapContext.Consumer>*/}
        </ReactMap>
      </div>
  );
}