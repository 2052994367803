import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.css'
import './index.css'
import {UserPreferencesProvider} from "./services/userpreferences-service";
import App from "./App";
import {UserinfoProvider} from "./services/userinfo-service";
import * as Sentry from '@sentry/react';
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import {blue} from "@material-ui/core/colors";

const basePath = process.env.REACT_APP_BASE_PATH || undefined;

const history = createBrowserHistory({
  basename: basePath,
});


const sentryDsn = process.env.REACT_APP_SENTRY_DSN || undefined;
if (sentryDsn !== undefined) {
    console.log("Initializing sentry");
    Sentry.init({
        dsn: sentryDsn,
    });
}

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.875em",
      }
    },
    palette: {
      primary:blue,
    },
  }
});

ReactDOM.render((
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <UserinfoProvider>
          <UserPreferencesProvider>
            <App />
          </UserPreferencesProvider>
        </UserinfoProvider>
      </Router>
    </MuiThemeProvider>
), document.getElementById('root'));
