import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import lodash from 'lodash';

// We extend the existing direct_select and override the dragFeature function to only allow polygon dragging if
// the control key or command/meta key (depending on OS) is pressed while dragging.
const DirectSelect = lodash.extend(MapboxDraw.modes.direct_select, {
    dragFeature (state, e, delta) {
        if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
            moveFeatures(this.getSelected(), delta);
            state.dragMoveLocation = e.lngLat;
        }
    }
});

export default DirectSelect