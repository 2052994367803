import React from 'react';
import {useHistory} from "react-router-dom";
import CarApi from "../../api/CarApi";
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import HelpIcon from '@material-ui/icons/Help';
import {Box, IconButton, Paper, Tooltip,} from "@material-ui/core";
import {AutoSizer, Column, Table as Table2} from "react-virtualized";
import lodash from 'lodash';
import 'react-virtualized/styles.css';
import StatefulButton from "../StatefulButton";
import Joyride, {ACTIONS as JOYRIDE_ACTIONS, STATUS as JOYRIDE_STATUS} from 'react-joyride';
import {MergeTaskTooltip} from "./MergeTaskTooltip";
import {MergeTaskValueEditor} from "./MergeTaskValueEditor";
import {MergeTaskStat} from "./MergeTaskStat";
import {ACTION_LABELS, ACTION_OPTIONS, AssignValueType, DECISION_LABELS, DECISION_OPTIONS} from "./constants";
import {AssignButton, EditableCellRenderer, mergeRowRenderer, ValueDeltaIcon, ValuePreview} from "./utils";
import {MergeTaskTableHeader} from "./MergeTaskTableHeader";
import {decodeMouseEvent, gridHasEditor, gridHasTooltip} from "./mouseevents";
import {computeMutations, getRowUpdateValue, getUpdate, mergeTaskFilter, normalizeFilters} from "./logic";
import {PRODUCT_TOUR_STEPS} from "./help";

export function MergeTaskTable({placeset_id}) {
  const history = useHistory();
  const [assigners, setAssigners] = React.useState({});
  const [filters, setFilters] = React.useState({});
  const [rawMergeTasks, setRawMergeTasks] = React.useState([]);
  const [mergeTasks, setMergeTasks] = React.useState([]);
  const [doLog, setDoLog] = React.useState([]);
  const [doLogIndex, setDoLogIndex] = React.useState(0);
  const [tooltipInfo, setTooltipInfo] = React.useState();
  const [editInfo, setEditInfo] = React.useState();
  const [runTour, setRunTour] = React.useState(false);
  const handleSave = async () => {
    const data = getUpdate(rawMergeTasks);
    const res = await CarApi.updatePlacesetMergeTasks(placeset_id, data);
    await refresh();
  }
  const refresh = React.useCallback(async () => {
    const res = await CarApi.getPlacesetMergeTasks(placeset_id);
    /** Sort by [place_id, field] */
    function sortMergeTasks(a, b) {
      if (a.place_id===b.place_id) {
        return a.field.localeCompare(b.field);
      } else {
        return a.place_id-b.place_id;
      }
    }
    const tasks = Array.from(res.data).sort(sortMergeTasks);
    tasks.forEach((row, index) => {
      row.rowIndex = index;
      row.originalValue = getRowUpdateValue(row);
    });
    setRawMergeTasks(tasks);
    setDoLog([]);
    setDoLogIndex(0);
  }, [placeset_id]);
  React.useEffect(() => {
    const predicate = mergeTaskFilter(normalizeFilters(filters));
    setMergeTasks(rawMergeTasks.filter(predicate));
  }, [rawMergeTasks, filters]);
  React.useEffect(() => {
    refresh();
  }, [refresh]);
  const applyDoLog = (doLog) => {
    doLog.forEach((update) => {
      const row = rawMergeTasks[update.rowIndex];
      if (update.decision !== undefined) {
        row.decision = update.decision;
      }
      if (update.action !== undefined) {
        row.action = update.action;
      }
      if (update.value !== undefined) {
        row.value = update.value;
      }
    });
    setRawMergeTasks([...rawMergeTasks]);
  };
  const handleAssignValue = (rowIndex, valueType, value) => {
    setEditInfo(null);
    if (valueType === null ||
        valueType === undefined ||
        valueType === AssignValueType.ACTION && !Object.values(CarApi.const.MergeAction).includes(value)) {
      return;  // empty assign
    }
    if (rowIndex === null || rowIndex === undefined) {
      rowIndex = mergeTasks.map(row=>row.rowIndex);
    } else {
      rowIndex = [rowIndex];
    }
    const {undolog, redolog} = computeMutations(rowIndex, rawMergeTasks, valueType, value);
    setDoLog([...doLog.slice(0, doLogIndex), {
      // NOTE: Would be nice to have summary text
      undo: undolog,
      redo: redolog,
    }]);
    applyDoLog(redolog);
    setDoLogIndex(doLogIndex+1);
  }
  const handleUndo = () => {
    //assert doLogIndex > 0;
    applyDoLog(doLog[doLogIndex-1].undo);
    setDoLogIndex(doLogIndex-1);
  };
  const handleRedo = () => {
    //assert doLogIndex < doLog.length
    applyDoLog(doLog[doLogIndex].redo);
    setDoLogIndex(doLogIndex+1);
  };
  const handleTableClick = (event) => {
    let info = decodeMouseEvent(event);
    if (!gridHasEditor(info)) {
      info = undefined;
    }
    if (!lodash.isEqual(tooltipInfo, info)) {
      setEditInfo(info);
    }
  };
  const handleTableHover = (event) => {
    let info = decodeMouseEvent(event);
    if (!gridHasTooltip(info)) {
      info = undefined;
    }
    if (!lodash.isEqual(tooltipInfo, info)) {
      setTooltipInfo(info);
    }
  };
  const handleJoyrideCallback = data => {
    const { action, status } = data;
    // console.groupCollapsed(data.type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
    if ([JOYRIDE_STATUS.FINISHED, JOYRIDE_STATUS.SKIPPED].includes(status)
        || action === JOYRIDE_ACTIONS.CLOSE) {
      setRunTour(false);
    }
  };
  const setFilterValue = (field, value) => {
    const neu = Object.assign({}, filters);
    neu[field] = value;
    setFilters(neu);
  }
  const setAssignValue = (field, value) => {
    const neu = Object.assign({}, assigners);
    neu[field] = value;
    setAssigners(neu);
  }
  const tableData = {
    assigners: assigners,
    setAssignValue: setAssignValue,
    filters: filters,
    setFilterValue: setFilterValue,
    handleAssignValue: handleAssignValue,
  };
  const fieldCounts = lodash.countBy(mergeTasks, task => task.field);
  const fields = Array.from(new Set(rawMergeTasks.map((row) => row.field))).sort();
  const field_options = fields.map(field => {
    return {
      label: `${field} (${fieldCounts[field]|0})`,
      value: field,
    }
  });
  return rawMergeTasks.length === 0 ? (
      <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
      >
        No outstanding merge tasks.
      </Box>
  ) : (
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Paper>
          <Box className="prodtour-undo" display="inline-block">
            <IconButton
                aria-label="delete"
                disabled={doLogIndex === 0}
                color="primary"
                onClick={handleUndo}
            >
              <UndoIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                disabled={doLogIndex === doLog.length}
                color="primary"
                onClick={handleRedo}
            >
              <RedoIcon />
            </IconButton>
          </Box>
          <StatefulButton
              variant="contained"
              color="primary"
              onClick={handleSave}
          >
            Save
          </StatefulButton>
          <Tooltip title="Introduce merge">
            <IconButton
                aria-label="info"
                color="primary"
                className="prodtour-merge-help"
                onClick={() => setRunTour(true)}
            >
              <HelpIcon/>
            </IconButton>
          </Tooltip>
        </Paper>
        <Paper style={{ flexGrow: 1, }}>
          <AutoSizer>
            {({ height, width }) => (
                <Box onClick={handleTableClick} onMouseMove={handleTableHover}>
                <Table2
                    width={width}
                    height={height}
                    headerHeight={90}
                    rowHeight={40}
                    rowCount={mergeTasks.length}
                    rowGetter={({ index }) => mergeTasks[index]}
                    rowRenderer={mergeRowRenderer}
                    headerStyle={{
                      height: '100%',
                    }}
                >
                  <Column
                      label="Place" dataKey="place" width={85}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                      }}
                      cellRenderer={({rowData})=>{
                        return "#" + rowData.place_id;
                      }}
                  />
                  <Column
                      label="Field" dataKey="field" width={100}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                        options: field_options,
                      }}
                  />
                  <Column
                      label="Change in origin" dataKey="change" width={400}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                        assignDecisionValue: CarApi.const.MergeDecision.theirs,
                      }}
                      cellRenderer={({rowData})=>(
                          <React.Fragment>
                            <ValuePreview value={rowData.us_from_value} maxWidth={180}/>
                            <ValueDeltaIcon/>
                            <ValuePreview value={rowData.us_to_value} maxWidth={180}/>
                            <AssignButton
                                handler={handleAssignValue}
                                rowIndex={rowData.rowIndex}
                                valueType={AssignValueType.DECISION}
                                value={CarApi.const.MergeDecision.theirs}
                            />
                          </React.Fragment>
                      )}
                  />
                  <Column
                      label="Current" dataKey="current" width={200}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                        assignDecisionValue: CarApi.const.MergeDecision.ours,
                      }}
                      cellRenderer={({rowData})=>(
                          <React.Fragment>
                            <ValuePreview value={rowData.ds_value} maxWidth={180}/>
                            <AssignButton
                              handler={handleAssignValue}
                              rowIndex={rowData.rowIndex}
                              valueType={AssignValueType.DECISION}
                              value={CarApi.const.MergeDecision.ours}
                            />
                          </React.Fragment>
                      )}
                  />
                  <Column
                      label="Decision" dataKey="decision" width={75}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                        options: DECISION_OPTIONS,
                      }}
                      cellRenderer={({cellData})=>DECISION_LABELS[cellData]}
                  />
                  <Column
                      label="Value" dataKey="value" width={200}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                      }}
                      cellRenderer={({cellData, columnData, rowData}) => <EditableCellRenderer value={cellData} maxWidth={200}/>}
                  />
                  <Column
                      label="Action" dataKey="action" width={125}
                      headerRenderer={MergeTaskTableHeader}
                      columnData={{
                        tableData: tableData,
                        options: ACTION_OPTIONS,
                      }}
                      cellRenderer={({cellData, columnData, rowData}) => <EditableCellRenderer value={ACTION_LABELS[cellData]} maxWidth={125}/>}
                  />
                </Table2>
                </Box>
            )}
          </AutoSizer>
        </Paper>
        <MergeTaskStat rawMergeTasks={rawMergeTasks} mergeTasks={mergeTasks}/>
        <MergeTaskTooltip
          mergeTasks={rawMergeTasks}
          info={tooltipInfo}
        />
        <MergeTaskValueEditor
          mergeTasks={rawMergeTasks}
          handleAssignValue={handleAssignValue}
          info={editInfo}
        />
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={runTour}
            steps={PRODUCT_TOUR_STEPS}
            styles={{
              options: {
                width: 500,
                zIndex: 1200,
              },
            }}
        />
      </Box>
  );
}
