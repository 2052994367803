import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as MapBox from "mapbox-gl";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CoordinatesGeocoder from "./CoordinatesGeocoder";
import SimpleSelect from "./mapbox-draw-modes/simple_select";
import DirectSelect from "./mapbox-draw-modes/direct_select";
import DrawControl from "react-mapbox-gl-draw";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Paper } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect } from "react";
import ReactMapboxGl, {
  GeoJSONLayer,
  Source,
  Layer,
  MapContext,
  ZoomControl,
} from "react-mapbox-gl";
import lodash from 'lodash';
import {boolToCssVisibility, GpsSource, updateRef} from './utils';
import StatefulButton from "./StatefulButton";
import Icon from "@material-ui/core/Icon";
import {getDistance, getLatitude, getLongitude} from "geolib";
import {showNotification} from "../notifications";
import * as DrawConst from "@mapbox/mapbox-gl-draw/src/constants";

// If zoom level is >=9, geocoder sends the centerpoint for proximity search.
// However, if <9, no context is sent. We inject context by providing a bounding
// box for the search if it is within GEOCODER_BBOX_***_ZOOM_LEVELs.
const GEOCODER_BBOX_MIN_ZOOM_LEVEL = 3;
const GEOCODER_BBOX_MAX_ZOOM_LEVEL = 9;

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
MapBox.accessToken = accessToken;
const ReactMap = ReactMapboxGl({
  accessToken
});

const GoogleMapButton = withStyles({
  root: {
    background:
      "linear-gradient(25deg, rgba(228,154,6,1) 0%, rgba(250,191,12,1) 46%, rgba(251,244,6,1) 100%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 35,
    width: 27,
    minWidth: 27,
    marginTop: 5,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
  }
})(Button);

const poiMarker = new MapBox.Marker();

export default function Map({
  selectedPOI,
  mapInfo,
  mapFunctions,
  refs,
}) {
  const [state] = React.useState({
      drawControl: null,  // https://github.com/mapbox/mapbox-gl-draw/blob/master/docs/API.md
  });
  updateRef(refs.mapRef, state);
  const mapRef = React.useRef();
  const mapInfoRef = React.useRef();
  const geocoderRef = React.useRef();
  mapInfoRef.current = mapInfo;
  let valueTravelMode = mapInfo.isoParams.travelMode;
  let valueTravelTime = mapInfo.isoParams.travelTime;
  let isoPolyVisible = mapInfo.isoParams.isoPolyVisible;

  const onDrawCreate = ({ features }) => {
    mapFunctions.createPOIGeo(features[0]);
  };



  const onDrawUpdate = ({ features }) => {
    mapFunctions.updatePOIGeo(features[0]);
  };

  const onDrawDelete = ({ features }) => {
    mapFunctions.deletePOIGeo(features[0]);
  };

  const openGoogleMapView = () => {
    mapFunctions.openGoogleMapView(mapInfoRef.current.viewportStatus);
  };

  const handleBasemapChange = (e) => {
    if (state.drawControl.getMode() === DrawConst.modes.DRAW_POLYGON) {
      showNotification({
        message: "Cannot change map style during drawing.",
        type: "danger",
      });
    } else {
      mapFunctions.basemapChange(e);
    }
  }

  const onStyleLoad = map => {

    // Disable map rotation using right click + drag
    map.dragRotate.disable();
    // Disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    map.resize();
    const statusUpdaterFn = (event) => {
      const bbox = map.getBounds();
      const zoomLevel = map.getZoom();
      const bboxDiagMeters = getDistance(bbox._sw, bbox._ne);
      const bboxDiagPixels = (map.transform.width**2 + map.transform.height**2)**0.5;
      const viewportStatus = {
          center: map.getCenter(),
          bounds: [
              [bbox._sw.lng, bbox._sw.lat],
              [bbox._ne.lng, bbox._ne.lat],
          ],
          bboxRadiusMaxMeters: bboxDiagMeters / 2,
          zoom: zoomLevel,
          pitch: map.getPitch(),
          bearing: map.getBearing(),
          densityMPP: bboxDiagMeters / bboxDiagPixels,
      };
      mapFunctions.setViewportStatus(viewportStatus);
      if (geocoderRef.current) {
        const geoBbox = [
          bbox.getWest(), bbox.getSouth(),
          bbox.getEast(), bbox.getNorth(),
        ];
        const isAtBboxZoomLevel = zoomLevel < GEOCODER_BBOX_MAX_ZOOM_LEVEL &&
            zoomLevel >= GEOCODER_BBOX_MIN_ZOOM_LEVEL;
        geocoderRef.current.setBbox( isAtBboxZoomLevel ? geoBbox : null );
        const placeholderText =
            zoomLevel >= GEOCODER_BBOX_MAX_ZOOM_LEVEL ?
                "Search around"
            : zoomLevel >= GEOCODER_BBOX_MIN_ZOOM_LEVEL ?
                "Search in view"
            : "Search globally";
        geocoderRef.current.setPlaceholder(placeholderText);
      }
    };
    map.on("moveend", statusUpdaterFn);

    // Create a popup, but don't add it to the map yet.
    let popup = new MapBox.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.on("mouseenter", "geojsonLayer-fill", function(e) {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";
      let description =
        "Area within " +
        mapInfo.isoParams.travelTime +
        " minutes " +
        mapInfo.isoParams.travelMode;
      popup
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(map);
    });

    map.on("mouseleave", "geojsonLayer-fill", function() {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });

    let geocoder = new MapboxGeocoder({
      accessToken: accessToken,
      mapboxgl: MapBox,
      localGeocoder: CoordinatesGeocoder,
      zoom: 18,
      flyTo: {
          maxDuration: 0.1,
      },
      limit: 10,
      clearAndBlurOnEsc: true,
      placeholder: "Search",
      marker: { color: "orange" }
    });
    geocoderRef.current = geocoder;

    document.getElementById("geocoderCtrl").appendChild(geocoder.onAdd(map));
    statusUpdaterFn();
  };

  //end of style load

  const setIsoParams = e => {
    if (e.target.id === "chkIsoToggle") {
      if (e.target.checked) {
        mapInfo.isoParams.isoPolyVisible = true;
      } else {
        mapInfo.isoParams.isoPolyVisible = false;
        mapFunctions.hideIso();
      }
    }
    // set params
    else {
      mapInfo.isoParams.isoPolyVisible = true;
      if (e.target.name === "radioTravelTime") {
        mapInfo.isoParams.travelTime = e.target.value;
      }

      if (e.target.name === "radioTravelMode") {
        mapInfo.isoParams.travelMode = e.target.value;
      }
    }

    if (poiMarker._lngLat && poiMarker._lngLat.lat !== 0) {
      mapInfo.isoParams.lat = selectedPOI.lat; //poiMarker.latitude;
      mapInfo.isoParams.lon = selectedPOI.lon; //poiMarker.longitude;
    } else {
      //no marker, take map center
      mapInfo.isoParams.lat = getLatitude(mapInfoRef.current.viewportStatus.center);
      mapInfo.isoParams.lon = getLongitude(mapInfoRef.current.viewportStatus.center);
    }
    mapFunctions.setIsoParams(mapInfo.isoParams);
  };

  useEffect(() => {
    if (mapRef.current.state.map) {
      mapRef.current.state.map.resize();
    }
  }, [mapInfo.leftDrawerOpen]);

  let carPointCount = lodash.sum(mapInfo.geogpsdataholder.map((item) => item.data.features.length));
  return (
    <div>
      <ReactMap
        accessToken={accessToken}
        ref={mapRef}
        style={mapInfo.mapStyle}
        fitBounds={mapInfo.viewportArea}
        fitBoundsOptions={{ padding: 0, animate: false }}
        movingMethod="jumpTo"
        containerStyle={{
          width: "100%",
          height: "calc(100vh - 64px)",
        }}
        onStyleLoad={onStyleLoad}
      >
        {
          mapInfo.geogpsdataholder.map((item) =>
            <React.Fragment>
              <Source id={item.source_name} geoJsonSource={{type:'geojson', data:item.data, buffer:0 }} />
              <Layer
                type="circle"
                paint={{
                  'circle-color': item.source_type === GpsSource.CAR ? '#1145da' : '#11da45',
                  'circle-radius': {
                    stops: [
                      [0, 2],
                      [20, 5]
                    ],
                    base: 2
                  },
                  'circle-stroke-width': {
                    stops: [
                      [0, 0],
                      [20, .5]
                    ],
                    base: 2
                  },
                  'circle-stroke-color': '#fff'
                }}
                sourceId={item.source_name}
                before="gl-draw-polygon-fill-inactive.cold"
              />
            </React.Fragment>
          )
        }
        <GeoJSONLayer
            data={mapInfo.polygonsMapLayerData}
            fillLayout={{visibility: boolToCssVisibility(mapInfo.polygonsMapLayerData!=null) }}
            fillPaint={{
              "fill-color": "pink",
              "fill-opacity": 0.2
            }}
            lineLayout={{visibility: boolToCssVisibility(mapInfo.polygonsMapLayerData!=null) }}
            linePaint={{
              "line-color": "pink",
              "line-width": 4
            }}
        />
        <GeoJSONLayer
          id="geojsonLayer"
          data={mapInfo.iso}
          fillLayout={{ visibility: boolToCssVisibility(mapInfo.iso !== null) }}
          fillPaint={{
            "fill-color": "yellow",
            "fill-opacity": 0.2
          }}
          lineLayout={{ visibility: boolToCssVisibility(mapInfo.iso !== null) }}
          linePaint={{
            "line-color": "yellow",
            "line-width": 4
          }}
        />

        <DrawControl
          ref={(ref) => {
              state.drawControl = ref && ref.draw;
          }}
          displayControlsDefault={false}
          controls={{ polygon: true, point: true, trash: true }}
          //styles={DrawCtrlStyles.DrawStyles} //add custom styles
          default_mode={mapInfo.mode}
          modes={{
            simple_select: SimpleSelect,
            direct_select: DirectSelect
          }}
          onDrawCreate={onDrawCreate}
          onDrawUpdate={onDrawUpdate}
          onDrawDelete={onDrawDelete}

        ></DrawControl>

        <MapContext.Consumer>
          {map => {
            if (selectedPOI) {
              if (poiMarker) {
                poiMarker.remove();
              } //remove marker if exists

              //only add marker when no geos
              if (mapInfo.geojson.features.length < 1) {
                poiMarker
                  .setLngLat([selectedPOI.lon, selectedPOI.lat])
                  .addTo(map);
              }
            }

            if (state.drawControl) {
              let drawFeatColl = state.drawControl.getAll();
              const mapGeoms = drawFeatColl.features.map(item => item.geometry);
              const visibleUpdates = mapInfo.geojson.features.filter(item=>item.properties.show);
              const updateGeoms = visibleUpdates.map(item => item.geometry);
              if (!lodash.isEqual(mapGeoms, updateGeoms) && state.drawControl.getMode() !== DrawConst.modes.DRAW_POLYGON) {
                state.drawControl.deleteAll();
                visibleUpdates.forEach(feature => {
                    state.drawControl.add(feature);
                });
              }
            }
          }}
        </MapContext.Consumer>
        <NativeSelect
          className="basemapSelect"
          onChange={handleBasemapChange}
        >
          <option defaultValue value="placeholder" disabled>
            {" "}
            Change Map style{" "}
          </option>
          <option value={"satellite-streets-v10"}>Satellite</option>
          <option value={"streets-v11"}>Street</option>
          <option value={"light-v10"}>Light</option>
          <option value={"dark-v10"}>Dark</option>
        </NativeSelect>
        <div className="geocoderCtrl" id="geocoderCtrl" />
        <div>
          <ZoomControl style={{ top: 50 }} />
          <div className="streetView">
            <GoogleMapButton
              title="Open in Google Maps"
              onClick={openGoogleMapView}
            >
              <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" />
            </GoogleMapButton>
          </div>
        </div>
        <ButtonGroup variant="contained" className="EditorButtonGroup">
          {
            mapInfo.mapActions.map((item, index) =>
              <Tooltip title={item.tooltip} key={index.toString()}>
                <StatefulButton
                  style={{ backgroundColor: (item.action !== false && item.color) || "#FFFFFF" }}
                  disabled={item.action === false}
                  onClick={item.action}
                >
                  {item.icon && <Icon>{item.icon}</Icon>}
                  {item.label && item.label}
                </StatefulButton>
              </Tooltip>
            )
          }
        </ButtonGroup>
        <Paper className="carCountPanel">
          Car object count: {carPointCount}
        </Paper>
        <ExpansionPanel className="isoPanel">
          <ExpansionPanelSummary>
            ISOCHRONES
            <Checkbox
                id="chkIsoToggle"
                style={{ position: "absolute", right: "0px", top: "-3px" }}
                checked={isoPolyVisible}
                color="default"
                onClick={e => e.stopPropagation() }
                onChange={e => {
                  setIsoParams(e);
                }}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              <form id="params">
                <label className="txt-m txt-bold">Travel Mode</label>
                <RadioGroup
                  aria-label="travelMode"
                  id="radioTravelMode"
                  value={valueTravelMode}
                  name="radioTravelMode"
                  onChange={e => {
                    setIsoParams(e);
                  }}
                >
                  <FormControlLabel
                    value="walking"
                    control={<Radio color="primary" />}
                    label="Walk"
                  />
                  <FormControlLabel
                    value="cycling"
                    control={<Radio color="primary" />}
                    label="Cycle"
                  />
                  <FormControlLabel
                    value="driving"
                    control={<Radio color="primary" />}
                    label="Drive"
                  />
                </RadioGroup>
                <label className="txt-m txt-bold">Time</label>
                <RadioGroup
                  aria-label="travelTime"
                  id="radioTravelTime"
                  value={valueTravelTime}
                  name="radioTravelTime"
                  onChange={e => {
                    setIsoParams(e);
                  }}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio color="primary" />}
                    label="10 min"
                  />
                  <FormControlLabel
                    value="20"
                    control={<Radio color="primary" />}
                    label="20 min"
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio color="primary" />}
                    label="30 min"
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio color="primary" />}
                    label="1 hour"
                  />
                </RadioGroup>
                <Button
                  style={{ backgroundColor: "#FFFFFF" }}
                  variant="contained"
                  onClick={() => {
                    mapFunctions.exportGeojson(mapInfo.iso);
                  }}
                >
                  Export WKT
                </Button>
              </form>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ReactMap>
    </div>
  );
}