import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Map from './Map';
import PoiForm from './PoiForm';
import FilteredSearchList from "./FilteredSearchList";
import ModeSelectorComponent from "./ModeSelectorComponent";
import ModeSelectorListComponent from "./ModeSelectorListComponent";

const useStyles = makeStyles(theme => ({
    paperForm: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        overflow: 'overlay',
    }
}));

export default function CenteredGrid({selectedPOI, selectedPOIOrig, mapInfo, mapFunctions, formFunctions, refs,
                                      hasNextPage, searchStatus, filteredPlaceData, filteredPlaceTotalCount, loadNextChunk, searchBarInputValue,
                                      workTypeValues, isPOIDirty, searchStat, searchStatIsOpen,
                                         modeInfo, placesets, flatPlacesets, displayModeSelectorList, onOpenModeSelector,
                                         onModeSelect, onModeAction, assignTargetInfo}) {
    const classes = useStyles();
    const mapSpan = mapInfo.leftDrawerOpen ? 9 : 12;

    return (
        <div>
            <Grid container spacing={0}>
                <Grid container item={true} xs={12} sm={12} md={12} lg={12} spacing={0}>
                    <Grid item={true} xs={12} sm={3} md={3} lg={3} style={{display: mapInfo.leftDrawerOpen ? "": "none"}} >
                        {(displayModeSelectorList &&
                            <Box display="flex" flexDirection="column" style={{height:'100%'}}>
                                <ModeSelectorComponent
                                    displayModeSelectorList={displayModeSelectorList}
                                    modeInfo={modeInfo}
                                    onOpenModeSelector={onOpenModeSelector}
                                    onModeAction={onModeAction}
                                />
                                <Box flexGrow={1}>
                                  <ModeSelectorListComponent
                                      modeInfo={modeInfo}
                                      placesets={placesets}
                                      onModeSelect={onModeSelect}
                                  />
                                </Box>
                            </Box>
                        ) || (
                            <Box style={{display:'grid', gridTemplateColumns:'100%', gridTemplateRows:'50% 50%', height:'calc(100vh - 64px)' }}>
                                <Box display="flex" flexDirection="column">
                                    <Box>
                                      <ModeSelectorComponent
                                            modeInfo={modeInfo}
                                            onOpenModeSelector={onOpenModeSelector}
                                            onModeAction={onModeAction}
                                        />
                                    </Box>
                                    <Box flexGrow={1} flexBasis={0}>
                                      <FilteredSearchList
                                          mapInfo={mapInfo}
                                          mapFunctions={mapFunctions}
                                          formFunctions={formFunctions}
                                          refs={refs}
                                          hasNextPage={hasNextPage}
                                          searchStatus={searchStatus}
                                          filteredPlaceData={filteredPlaceData}
                                          filteredPlaceTotalCount={filteredPlaceTotalCount}
                                          loadNextChunk={loadNextChunk}
                                          searchBarInputValue={searchBarInputValue}
                                          workTypeValues={workTypeValues}
                                          searchStat={searchStat}
                                          searchStatIsOpen={searchStatIsOpen}
                                          modeInfo={modeInfo}
                                          onModeSelect={onModeSelect}
                                          flatPlacesets={flatPlacesets}
                                      />
                                    </Box>
                                </Box>
                                <Paper className={classes.paperForm}>
                                    <PoiForm
                                      selectedPOI={selectedPOI}
                                      selectedPOIOrig={selectedPOIOrig}
                                      geojson={mapInfo.geojson.features}
                                      geojsonOrig={mapInfo.geojsonOrig.features}
                                      formFunctions={formFunctions}
                                      poiFormRef={refs.poiFormRef}
                                      isPOIDirty={isPOIDirty}
                                      modeInfo={modeInfo}
                                      assignTargetInfo={assignTargetInfo}
                                      onModeSelect={onModeSelect}
                                    />
                                </Paper>
                            </Box>
                        )}
                    </Grid>
                    <Grid item={true} xs={12} sm={mapSpan} md={mapSpan} lg={mapSpan} id="mapContainer">
                        <div>
                            <Map
                                selectedPOI={selectedPOI}
                                mapInfo={mapInfo}
                                mapFunctions={mapFunctions}
                                refs={refs}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}