import React from 'react';
import {Grid} from "@material-ui/core";
import CarApi from "../api/CarApi";

/**
 * Redirect from old hld link format to new format
 *
 * ES uses /destinations/nnnn/hld/mmmm link format, but it is not anymore supported in MD.
 * This component receives such calls and generates the new URL in the format of:
 *   /destinations/nnnn?place_id=xxxx
 * The hyperlocal_destination_id gets converted into the proper place_id
 */
export function RedirectHldUrl({match, history}) {
    const { destination_id, hyperlocal_destination_id} = match.params;
    const doRedirect = async () => {
        const resp = await CarApi.getProjectByDestination(destination_id);
        const project_id = resp.data.id;
        if (hyperlocal_destination_id) {
            // NOTE: During the migration from place_destinations to placesets, we ensured
            //   that hyperlocal_destination_id == place_id holds true.
            history.replace(`/projects/${project_id}?place_id=${hyperlocal_destination_id}`);
        } else {
            history.replace(`/projects/${project_id}`);
        }
    };
    doRedirect();
    return (
        <Grid container>
            <Grid item>
                You provided an old link format. Redirecting...
            </Grid>
        </Grid>
    );
}
