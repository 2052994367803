import {Redirect, Route, Switch} from "react-router-dom";
import Editor from "./Components/editor";
import React from "react";
import {useUserinfo} from "./services/userinfo-service";
import {useClearCache} from "react-clear-cache";
import {RedirectHldUrl} from "./Components/RedirectHldUrl";
import * as Sentry from '@sentry/react';
import {ProjectSettings} from "./Components/Settings/ProjectSettings";
import {PlacesetSettings} from "./Components/Settings/PlacesetSettings";
import ReactNotification from "react-notifications-component";
import {GlobalHotKeys} from "react-hotkeys";
import {pruneCache, wipeCache} from "./utils/localStorageCache";

const globalKeyMap = {
    FORCE_RELOAD: {name: 'Force reload (discard cache)', sequence: 'ctrl+f5', action: 'keydown'},
};

const globalHandlers = {
    FORCE_RELOAD: () => { wipeCache() },
};

function App() {
    const { loading, isLatestVersion, emptyCacheStorage } = useClearCache();
    React.useEffect(() => {
        if (!isLatestVersion) {
            wipeCache();
            console.log("empty-cache");
            emptyCacheStorage();
        }
    }, [isLatestVersion, emptyCacheStorage]);
    pruneCache();
    const userinfo = useUserinfo();
    if (userinfo.redirect_url) {
        window.location = userinfo.redirect_url;
    }
    Sentry.setUser({
        email: userinfo.email,
    });
    return <Sentry.ErrorBoundary
        fallback={"An error has occurred"}
        showDialog
        dialogOptions={{
            user: {
                email: userinfo.email,
                name:  userinfo.username,
            },
        }}
    >
        <GlobalHotKeys
            keyMap={globalKeyMap}
            handlers={globalHandlers}
            global
        >
            <ReactNotification />
            {loading || !userinfo.ready?(
                <div>Loading...</div>
            ) : (
                <Switch>
                    <Redirect exact from='/' to={'/editor' + window.location.hash + window.location.search} />
                    <Route path='/editor' render={(props) => <Editor {...props} userinfo={userinfo} />}/>
                    <Route path='/projects/:project_id/settings' render={(props) => <ProjectSettings {...props} />}/>
                    <Route path='/projects/:project_id' render={(props) => <Editor {...props} userinfo={userinfo} />}/>
                    <Route path='/placesets/:placeset_id/settings' render={(props) => <PlacesetSettings {...props} />}/>
                    <Route path='/placesets/:placeset_id' render={(props) => <Editor {...props} userinfo={userinfo} />}/>
                    <Route path='/clients/:client_id' render={(props) => <Editor {...props} userinfo={userinfo} />}/>
                    <Redirect from="/places/:id" to='/editor?place_id=:id' />
                    <Route from="/destinations/:destination_id/hld/:hyperlocal_destination_id" render={(props) => <RedirectHldUrl {...props} />}/>
                    <Route from="/destinations/:destination_id" render={(props) => <RedirectHldUrl {...props} />}/>
                </Switch>
            )}
        </GlobalHotKeys>
    </Sentry.ErrorBoundary>;
}

export default App;
