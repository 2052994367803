import React, {useEffect, useRef, useState} from "react";
import {Box, Chip, Grid, makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {FixedSizeList as List} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import lodash from "lodash";
import FilterInput from "./FilterInput";
import AutoSizer from "react-virtualized-auto-sizer";
import {HotKeys, ObserveKeys} from "react-hotkeys";
import WorkTypeDropdown from "./WorkTypeDropdown";
import QueueIcon from '@material-ui/icons/Queue';
import {SearchStatus, getSearchStatusLabel} from './utils';
import SearchStatInfo from "./SearchStatInfo";
import FilterForm from "./FilterForm";
import Button from "@material-ui/core/Button";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SelectionMode} from "../utils/modeSelectorUtils";
import {OptionalTooltip} from "./OptionalTooltip";
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import {POPUP_TYPES, showNotification} from "../notifications";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        overflow: 'hidden'
    },
    formPadding: {
        padding: theme.spacing(0, 1),
    },
    placesetChip: {
        height: '18px',
        '& .MuiChip-label': {
            paddingLeft: '4px',
            paddingRight: '4px',
        },
    }
}));

const formKeyMap = {
    PREV_ITEM: {name: 'Move to previous place', sequence: 'up'},
    NEXT_ITEM: {name: 'Move to next place', sequence: 'down'},
    PREV_PAGE: {name: 'Move to previous page', sequence: 'pageup'},
    NEXT_PAGE: {name: 'Move to next page', sequence: 'pagedown'},
};

export default function FilteredSearchList({mapInfo, mapFunctions, formFunctions, refs, hasNextPage,
                                            searchStatus, filteredPlaceData, filteredPlaceTotalCount,
                                            loadNextChunk, searchBarInputValue,
                                            workTypeValues, searchStat, searchStatIsOpen,
                                            modeInfo, onModeSelect, flatPlacesets
                                           }){
    const classes = useStyles();
    const infiniRef = useRef();
    const [displayFilterForm, setDisplayFilterForm] = useState(false);
    const [selectedPlaceId, setSelectedPlaceId] = useState(mapInfo.listSelection);
    // HACK stickyState
    // Handlers registered in HotKeys are not updated at every render, thus moveByOffset and on[Prev|Next][Item|Page]
    // methods have their body from the first render.
    // In the class based component, this.state.selectedPlaceId and this.props.filteredPlaceData expressions resolved
    // to the most recent values. However in the functional component case, a simple selectedPlaceId or the
    // filteredPlaceData expression would resolve to the initial value. We need a container from the initialization
    // phase, that we can use to tunnel state to moveByOffset. stickyState is used for this.
    // NOTE: We assume that refs and formFunctions do not change.
    const stickyState = useState([0, 0])[0];
    stickyState[0] = selectedPlaceId;
    stickyState[1] = filteredPlaceData;
    useEffect(() => {
        setSelectedPlaceId(mapInfo.listSelection)
        const index = filteredPlaceData.findIndex( item => item.place_id === mapInfo.listSelection);
        if (index !== -1) {
            scrollListToIndex(index);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapInfo.listSelection]);

    const handleToggleFilterForm = () => {
        setDisplayFilterForm(!displayFilterForm);
    };

    const scrollListToIndex = (index) => {
        if (infiniRef.current) {
            const list = infiniRef.current._listRef;
            list.scrollToItem(index);
        }
    };
    const getListVisibleItemCount = () => {
        if (!infiniRef.current) {
            return 0;
        }
        const list = infiniRef.current._listRef;
        return list.props.height / list.props.itemSize | 0;
    }
    const moveByOffset = (offset) => {
        const place_id = stickyState[0];
        const filteredPlaceData = stickyState[1];

        if (filteredPlaceData.length === 0) {
            return;
        }
        let index = filteredPlaceData.findIndex((element) => element.place_id === place_id);
        index = lodash.clamp(index + offset, 0, filteredPlaceData.length - 1);
        scrollListToIndex(index);
        refs.filteredSearchListRef.current.focus();
        const newPlaceId = filteredPlaceData[index].place_id;
        setSelectedPlaceId(newPlaceId);
        formFunctions.showPlace(newPlaceId);
    };

    const onPrevItem = () => {
        moveByOffset(-1)
    };

    const onNextItem = () => {
        moveByOffset(1)
    };

    const onNextPage = () => {
        moveByOffset(getListVisibleItemCount())
    };

    const onPrevPage = () => {
        moveByOffset(-getListVisibleItemCount())
    };

    const renderPlacesetTooltipLabel = (place) => {
        const placesetInfo = flatPlacesets[place.placeset_id];
        if (!placesetInfo) {
            return "";
        }
        let result = [
            `Click to open placeset #${place.placeset_id}`
        ];
        if (!isClientMode && placesetInfo.client_name) {
            result.push(`Client: ${placesetInfo.client_name}`);
        }
        result.push(`Placeset: ${placesetInfo.name}`);
        return result;
    }

    const handleBulkEdit = () => {
        const placeset_id = modeInfo.related_placeset_id;
        if (!placeset_id) {
            showNotification({message: "You need to select a project/placeset with places", type:POPUP_TYPES.ERROR});
            return;
        }
        const url = `/admin/places?utf8=%E2%9C%93&q%5Bplaceset_id_equals%5D=${placeset_id}&q%5Bis_deleted_eq%5D=false&commit=Filter&order=place_id_desc`;
        window.location = url;
    }

    const isPlacesetMode = [SelectionMode.PLACESET, SelectionMode.PROJECT].includes(modeInfo.selection_mode);
    const isClientMode = SelectionMode.CLIENT === modeInfo.selection_mode;

    {
        const hasSearchStatus = searchStatus !== SearchStatus.NONE;
        // If there are more items to be loaded then add an extra row to hold a loading indicator.
        const itemCount = (hasNextPage || hasSearchStatus) ? filteredPlaceData.length + 1 : filteredPlaceData.length;

        // Only load 1 page of items at a time.
        // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
        const loadMoreItems = hasSearchStatus ? () => {} : loadNextChunk;

        // Every row is loaded except for our loading indicator row.
        const isItemLoaded = index => index < filteredPlaceData.length || (hasSearchStatus && !hasNextPage);

        const listRowStyle = {
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'row',
        };
        const ListRow = ({index, style}) => {
            // Render an item or a loading indicator.
            let content;
            if (index >= filteredPlaceData.length) {
                content = <div style={style}>{getSearchStatusLabel(searchStatus)}</div>
            } else {
                const isSelectedPlace = selectedPlaceId === filteredPlaceData[index].place_id;
                content = <div
                  className={`listItem ${isSelectedPlace ? 'selectedItem' : ""}`}
                  style={{...style, ...listRowStyle}} onClick={() => {
                    setSelectedPlaceId(filteredPlaceData[index].place_id)
                    formFunctions.showPlace(filteredPlaceData[index].place_id)
                }}>
                    <Box flexGrow={1}>
                        <h4 style={{whiteSpace: "nowrap"}}>{filteredPlaceData[index].has_geometry ? filteredPlaceData[index].name : "(*) " + filteredPlaceData[index].name}</h4>
                        <h6 style={{ marginTop: "-2em", fontWeight: "bold" }}>
                            {filteredPlaceData[index].address +
                            (filteredPlaceData[index].city ? ', ' + filteredPlaceData[index].city : '') +
                            (filteredPlaceData[index].region_code ? ', ' + filteredPlaceData[index].region_code : '') +
                            (filteredPlaceData[index].country_code ? ', ' + filteredPlaceData[index].country_code : '')}
                        </h6>
                    </Box>
                    {!isPlacesetMode &&
                        <Box>
                            <OptionalTooltip title={renderPlacesetTooltipLabel(filteredPlaceData[index])}>
                                <Chip
                                    className={classes.placesetChip}
                                    label={`#${filteredPlaceData[index].placeset_id}`}
                                    onClick={(e)=>{
                                        onModeSelect({[SelectionMode.PLACESET.selector]:filteredPlaceData[index].placeset_id});
                                        e.stopPropagation();
                                    }}
                                />
                            </OptionalTooltip>
                        </Box>
                    }
                </div>
            }
            return content;
        }

        const filterStatusLabel =
            searchStatus !== SearchStatus.NONE ? (
                getSearchStatusLabel(searchStatus)
            ) : (
                !!searchBarInputValue ? (
                    `Found ${(filteredPlaceTotalCount===-1)?"some":filteredPlaceTotalCount} records`
                ) : "Search POIs..."
            );

        return (
            <HotKeys
                innerRef={refs.filteredSearchListRef}
                style={{height: '100%', width: '100%', outline: 'none'}}
                keyMap={formKeyMap}
                handlers={{
                    PREV_ITEM: onPrevItem,
                    NEXT_ITEM: onNextItem,
                    PREV_PAGE: onPrevPage,
                    NEXT_PAGE: onNextPage,
                }}
            >
                <Paper className={classes.paper} style={{height: '100%', width: '100%'}}>
                    <Box
                        display="flex"
                        style={{height: '100%', width: '100%'}}
                        flexDirection="column"
                    >
                        <ObserveKeys only={['up', 'down', 'pageup', 'pagedown']}>
                            <Grid container={true} className={classes.formPadding}>
                                <React.Fragment>
                                    <FilterInput
                                        item={true}
                                        toggleHelp={mapFunctions.toggleHelp}
                                        reloadStat={formFunctions.reloadSearchPlaceStat}
                                        inputRef={refs.filterInputRef}
                                        value={searchBarInputValue}
                                        onInputChange={formFunctions.searchPlaceChange}
                                        label={filterStatusLabel}
                                    />
                                    <OptionalTooltip title="Display filter form">
                                        <Button
                                            style={{minWidth:"0px", padding:"6px"}}
                                            onClick={handleToggleFilterForm}
                                        >
                                            { displayFilterForm ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
                                        </Button>
                                    </OptionalTooltip>
                                    <OptionalTooltip title="Search result stat">
                                        <SearchStatInfo
                                            searchStat={searchStat}
                                            searchStatIsOpen={searchStatIsOpen}
                                            setSearchStatIsOpen={formFunctions.setSearchStatIsOpen}
                                            reloadStat={formFunctions.reloadSearchPlaceStat}
                                        />
                                    </OptionalTooltip>
                                    <OptionalTooltip title="Open bulk edit">
                                        <Button
                                            style={{minWidth:"0px", padding:"6px"}}
                                            aria-controls="simple-menu"
                                            onClick={handleBulkEdit}>
                                            <FormatColorFillIcon />
                                        </Button>
                                    </OptionalTooltip>
                                    <WorkTypeDropdown
                                        menuName={(<QueueIcon/>)}
                                        workTypeList={workTypeValues}
                                        workTypeSelected={(name)=>{formFunctions.addWorkTypeFilter(name)}}/>
                                    { displayFilterForm && <FilterForm
                                        item={true}
                                        toggleHelp={mapFunctions.toggleHelp}
                                        reloadStat={formFunctions.reloadSearchPlaceStat}
                                        value={searchBarInputValue}
                                        onInputChange={formFunctions.searchPlaceChange}
                                        label={filterStatusLabel}
                                    /> }
                                </React.Fragment>
                            </Grid>
                        </ObserveKeys>
                        <Box flexGrow={1}>
                            <AutoSizer>
                                {({height, width}) => (

                                    <InfiniteLoader
                                        isItemLoaded={isItemLoaded}
                                        itemCount={itemCount}
                                        loadMoreItems={loadMoreItems}
                                        ref={infiniRef}
                                        threshold={400}
                                    >
                                        {({ onItemsRendered, ref }) => (
                                            <List
                                                key={"b"}
                                                className="List"
                                                height={height}
                                                itemCount={itemCount}
                                                itemSize={40}
                                                onItemsRendered={onItemsRendered}
                                                ref={ref}
                                                width={width}
                                                style={{overflowX: "hidden"}}
                                            >
                                                {ListRow}
                                            </List>
                                        )}
                                    </InfiniteLoader>

                                )}
                            </AutoSizer>
                        </Box>
                    </Box>
                </Paper>
            </HotKeys>
        )
    }
}
