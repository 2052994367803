import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function WorkTypeDropdown({menuName,workTypeList, workTypeSelected}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (index) => {
        setAnchorEl(null);
        if (index !== undefined) {
            workTypeSelected(workTypeList[index]);
        }
    };

    return (
        <React.Fragment>
            <Button
                disabled={workTypeList.length === 0}
                style={{minWidth:"0px", padding:"6px"}}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                {menuName}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose(undefined)}
            >
                { workTypeList.map((item, index) => (
                    <MenuItem
                        key={index.toString()}
                        dense={true}
                        onClick={() => handleClose(index)}>
                        {item}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}