import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Paper } from '@material-ui/core';
import CarApi, {ARRIVALIST_CLIENT_ID} from "../api/CarApi";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import lodash from 'lodash';
import SendIcon from '@material-ui/icons/Send';
import Badge from "@material-ui/core/Badge";
import StatefulButton from "./StatefulButton";
import clsx from "clsx";
import {AriAutocomplete, fieldGetter} from "../utils/formUtils";
import {SelectionMode} from "../utils/modeSelectorUtils";
import {OptionalTooltip} from "./OptionalTooltip";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AdjustIcon from '@material-ui/icons/Adjust';
import {ModeSelectorButton} from "./ModeSelectorButton";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useValueset, useValuesets} from "../services/valuesets";
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from "@material-ui/core/IconButton";
import {evalExpr, getFieldValuesets, getPlaceField, validateFields, createEvalContext} from "../utils/fieldDefUtils";
import {withDebounceOnChange} from "../utils/withDebounceOnChange";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    littleMargin: {
        marginTop: '5px'
    },
    badgePadding: {
        padding: theme.spacing(0, 2),
    },
    valueChanged: {
        backgroundColor: "#ffc2c2",
    },
}));


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box style={{width: '100%', padding: 0}} p={4}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


/*
Uncomment to show Opacity slider, an Opacity property would need to be added to a feature in the createFeature function in Editor and then some way to accessing the draw control opacity in Map
The slider component should be placed under the <p> tag that displays geo.geo_id
<Slider
    defaultValue={2.5}
    aria-label='Opacity'
    aria-labelledby="discrete-slider"
    valueLabelDisplay="auto"
    step={0.5}
    marks
    min={0}
    max={5}
    onChangeCommitted={formFunctions.updateGeoJsonFeature([geo.map_geo_id, 'opacity'])}
/>
*/

function GeoRow({geo, geoOrig, formFunctions}) {
    const classes = useStyles();
    const geoProp = geo.properties;
    const geoOrigProp = geoOrig && geoOrig.properties;
    const typeChanged = geoOrigProp === undefined || ((geoProp.geo_type_id || 0)!==(geoOrigProp.geo_type_id || 0));
    const geomChanged = geoOrig === undefined || !lodash.isEqual(geo.geometry, geoOrig.geometry);
    return (
        <Paper classes={{root: classes.littleMargin}}>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item={true}>
                    <FormControlLabel
                        style={{
                            marginLeft: 1,
                            marginRight: 0,
                        }}
                        value="0"
                        control={
                            <Checkbox
                                checked={geoProp.show}
                                onChange={formFunctions.updateGeoJsonFeature([geoProp.map_geo_id, 'show'])}
                                color="primary" />
                        }
                    />
                    <Tooltip title="Zoom to polygon">
                        <Button
                            style={{minWidth: "0px", padding: "6px"}}
                            onClick={()=>formFunctions.zoomToPolygon(geoProp.map_geo_id)}
                        >
                          <CenterFocusStrongIcon/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Export to WKT">
                        <Button
                            style={{minWidth: "0px", padding: "6px"}}
                            onClick={()=>formFunctions.exportPolygon(geoProp.map_geo_id)}
                        >
                            <GetAppIcon/>
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item={true}>
                    <div
                        style={{paddingLeft:2, paddingRight:2}}
                        className={[...(geomChanged?[classes.valueChanged]:[])]}>
                        <b>Geo ID</b><br/>{geoProp.geo_id ? geoProp.geo_id : "In Progress"}
                    </div>

                </Grid>
                <Grid item={true}>
                    <TextField
                        select
                        className={clsx(typeChanged && classes.valueChanged)}
                        label={'Type'}
                        value={geoProp.geo_type_id ? geoProp.geo_type_id : 0 }
                        style={{width: 100, height: 70, paddingLeft:2, paddingRight:2}}
                        map_geo_id={geoProp.map_geo_id}
                        onChange={formFunctions.updateGeoJsonFeature([geoProp.map_geo_id, 'geo_type_id'])}
                    >
                        <MenuItem value={CarApi.const.GeoType.UNSPECIFIED}> Unspecified </MenuItem>
                        <MenuItem value={CarApi.const.GeoType.BUILDING}> Building </MenuItem>
                        <MenuItem value={CarApi.const.GeoType.PARKING_LOT}> Parking Lot </MenuItem>
                        <MenuItem value={CarApi.const.GeoType.BUILD_PLUS_PARKLOT}> Mixed </MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Paper>
    );
}

const OPTIONAL_BOOLEAN_OPTIONS = [
  {"label": "Unset", "value": undefined},
  {"label": "False", "value": false},
  {"label": "True", "value": true},
]

const REQUIRED_BOOLEAN_OPTIONS = [
  {"label": "False", "value": false},
  {"label": "True", "value": true},
]

const DebouncedTextfield = withDebounceOnChange(TextField, 200);

/**
 * Customize null_label with dynamic information about inferred timezone
 * @param fieldname current field
 * @param context form redering context
 * @param null_label current, null_label
 * @returns {string} updated null_label
 */
function patch_timezone_null_label_sc_45026(fieldname, context, null_label) {
    if (fieldname == "time_zone_manual") {
        const timezone_inferred = getPlaceField(context.evalContext.object, "time_zone_inferred");
        null_label = timezone_inferred ? `Auto (${timezone_inferred})` : "Auto (will be inferred)";
    }
    return null_label;
}

/**
 * Reorder timezone options based on country
 * @param fieldname current field
 * @param context form redering context
 * @param null_label current, options
 * @returns {string} updated options
 */
function patch_timezone_options_order_sc_51698(fieldname, context, options) {
    if (fieldname == "time_zone_manual" && options) {
        const country_code = getPlaceField(context.evalContext.object, "country_code");
        const priority_options = options.filter((item)=>item["countries"].includes(country_code) && item["priority"]);
        const preferred_options = options.filter((item)=>item["countries"].includes(country_code) && !item["priority"]);
        const rest_of_options = options.filter((item)=>!item["countries"].includes(country_code));
        options = [
            ...priority_options,
            ...preferred_options,
            ...rest_of_options,
        ];
    }
    return options;
}

/**
 * Extend option list with null_value and null_label if one is provided
 * NOTE: Assumes {label,value} format of options.
 * NOTE: Constructs new object, thus triggers rerendering all the time
 * @param options Original options
 * @param null_label Null label to be added
 * @returns {options} updated options
 */
function extend_with_null_label(options, null_label) {
    if (null_label) {
        options = [{
            "label": null_label,
            "value": null,
        }, ...(options||[])]
    }
    return options;
}

function PoiFormField({context, fieldname}) {
    const fieldConfig = context.evalContext.model[fieldname];
    if (fieldConfig === undefined) {
        console.log(`Cannot display PoiFormField(key='${fieldname}' due to missing metadata`);
        return null;
    }
    const label = fieldConfig.label;
    const required = fieldConfig.required;
    const tooltip = fieldConfig.description || undefined;
    let null_label = undefined;
    null_label = patch_timezone_null_label_sc_45026(fieldname, context, null_label);
    const hasError = fieldname in context.poiFormErrors;
    const value = getPlaceField(context.evalContext.object, fieldname);
    const valueOriginal = getPlaceField(context.dataOriginal, fieldname);
    const valueChanged = (value!=valueOriginal);
    const valuesetId = evalExpr(fieldConfig?.type_param?.valueset, context.evalContext);
    let optionsValueset = valuesetId ? context.evalContext.valuesets[valuesetId] : undefined;
    optionsValueset = patch_timezone_options_order_sc_51698(fieldname, context, optionsValueset);
    const optionsBool = fieldConfig.type === "boolean" ? (required ? REQUIRED_BOOLEAN_OPTIONS : OPTIONAL_BOOLEAN_OPTIONS) : undefined;
    const options = extend_with_null_label(optionsValueset || optionsBool, null_label);
    let fieldArgs = {
        label: label,
        autoComplete: 'new-password',  // common hack to disable suggestions
        margin: "dense",
        error: hasError,
        fullWidth: true,
        InputProps: {
            readOnly: context.readonly,
            endAdornment: [
                valueChanged && (
                    <OptionalTooltip title="Revert to original value" key="reset">
                        <IconButton
                            size="small"
                            tabIndex={-1}
                            onClick={(e)=>{context.updateValue(fieldname, valueOriginal); e.stopPropagation();}}
                        >
                            <RestoreIcon/>
                        </IconButton>
                    </OptionalTooltip>
                ),
                hasError && (
                    <OptionalTooltip title={context.poiFormErrors[fieldname]} key="validation">
                        <WarningIcon/>
                    </OptionalTooltip>
                ),
            ]
        },
    };
    if (hasError) {
        fieldArgs.style = {
            backgroundColor: "#ffff80",
        }
    } else if (valueChanged) {
        fieldArgs.style = {
            backgroundColor: "#8080ff",
        }
    }
    const isSelect = !!options && !context.readonly;
    return (
      <Grid item xs={12} lg={6}>
          <Box px={0.5}>
              <OptionalTooltip title={tooltip}>
                  {
                      isSelect ? (
                          <AriAutocomplete
                            options={options}
                            labelGetter={fieldGetter("label")}
                            valueGetter={fieldGetter("value")}
                            value={value}
                            onChange={(value)=>context.updateValue(fieldname, value)}
                            {...fieldArgs}
                          />
                      ) : (
                            <DebouncedTextfield
                              required={required}
                              value={value || ""}
                              onChange={(value) => context.updateValue(fieldname, value)}
                              {...fieldArgs}
                          />
                      )
                  }
              </OptionalTooltip>
          </Box>
      </Grid>
    );
}

/** Create evalContext with model and valuesets dynamically calculated */
function usePlaceEvalContext(place) {
    const evalContext = createEvalContext(place, null, null);
    const [fieldValueset] = useValueset("place_field");
    const [valuesets] = useValuesets(getFieldValuesets(fieldValueset, evalContext));
    evalContext.model = Object.fromEntries(fieldValueset.map(f=>[f.key, f]));
    evalContext.valuesets = valuesets;
    return evalContext;
}

export default function PoiForm({selectedPOI, selectedPOIOrig, geojson, geojsonOrig, formFunctions, poiFormRef, isPOIDirty, modeInfo, assignTargetInfo, onModeSelect}) {
    const poiHasWorkItems = lodash.size(selectedPOI.work_items) > 0;
    const poiIsOpenWorkItem = poiHasWorkItems && selectedPOI.work_items[0].status === CarApi.const.WorkItem.STATUS_OPEN;
    const poiIsMarkedWorkItem = poiHasWorkItems && selectedPOI.work_items[0].status === CarApi.const.WorkItem.STATUS_BLOCKED;
    const [tabValue, setValue] = React.useState(0);
    const [statusExtra, setStatusExtra] = React.useState((poiHasWorkItems && selectedPOI.work_items[0].status_extra) || "");
    const [displayStatusExtra, setDisplayStatusExtra] = React.useState(poiIsMarkedWorkItem);
    const [focusStatusExtra, setFocusStatusExtra] = React.useState(false);
    const [commitStatusExtraOnBlur, setCommitStatusExtraOnBlur] = React.useState(false);
    const statusExtraInputRef = React.createRef();
    const markButtonRef = React.createRef();
    const classes = useStyles();
    const evalContext = usePlaceEvalContext(selectedPOI);
    const [placeset, setPlaceset] = React.useState(null);

    React.useEffect(() => {  // Called on POI change
        setStatusExtra((poiHasWorkItems && selectedPOI.work_items[0].status_extra) || "");
        setDisplayStatusExtra(poiIsMarkedWorkItem);
    }, [selectedPOI.place_id, poiHasWorkItems, poiIsMarkedWorkItem, selectedPOI.work_items]);

    React.useEffect(() => {
        (async() => {
            const placeset_id = modeInfo?.related_placeset_id;
            if (placeset_id) {
                const res = await CarApi.getPlaceset(placeset_id);
                setPlaceset(res.data);
            } else {
                setPlaceset(null);
            }
        })();
    }, [modeInfo?.related_placeset_id || -1]);

    React.useEffect(() => {
        if (focusStatusExtra && statusExtraInputRef.current) {
            statusExtraInputRef.current.focus();
            setFocusStatusExtra(false);
        }
    }, [focusStatusExtra, statusExtraInputRef]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleDone = () => {
        formFunctions.doneWorkItem();
        setDisplayStatusExtra(false);
    };

    const handleMark = () => {
        // Handle mark became quite complex due to aiming for intuitive UI
        // handleMark is basically a mark/unmark switch for the currently selected POI.
        // If the item is marked, it is unmarking it by setting it into OPEN status.
        // If the item is not marked, it is doing the following:
        // - displayes the field for textual status context and adds focus to it
        // - if ESC is pressed, the status text is removed, and item is marked without status text.
        // - if Enter is pressed, the item is marked with the entered status text.
        // - if the input field looses focus, it behaves as if Enter was hit.
        //
        // There are some tricks here due to the async nature of some operations.
        // 1. As the input text field is hidden, we can only set focus to it once it is rendered. Hence we
        //   use the focusStatusExtra state, and a useEffect listener to act on it.
        // 2. When the user first clicks Mark, we do not yet know if they want to enter a text as context.
        //   We set commitStatusExtraOnBlur state to mark this and all input actions delegate control towards
        //   the onBlur handler, that commits the state change of the work item into MARKED (aka BLOCKED).
        if (!poiHasWorkItems) {
            return;
        }
        if (commitStatusExtraOnBlur) {
            // This happens on a canceled onBlur. See handleStatusExtraBlur for details.
            handleStatusExtraBlur();
            return;
        }
        if (poiIsMarkedWorkItem) {
            formFunctions.markWorkItem(CarApi.const.WorkItem.STATUS_OPEN, "");
            setDisplayStatusExtra(false);
        } else {
            setDisplayStatusExtra(true);
            setFocusStatusExtra(true);
            setCommitStatusExtraOnBlur(true);
        }
    };

    const handleMarkWithExtra = () => {
        formFunctions.markWorkItem(CarApi.const.WorkItem.STATUS_BLOCKED, statusExtra);
    }

    const handleMarkWithExtraKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            statusExtraInputRef.current.blur();
            ev.preventDefault();
        }
        if (ev.key === 'Escape') {
            setStatusExtra("");
            statusExtraInputRef.current.blur();
            ev.preventDefault();
        }
    };
    const handleNew = async () => {
        await formFunctions.createPlace()
    };

    const handleSave = async (event) => {
        await formFunctions.savePlace(event.shiftKey)
    };

    const handleReset = async () => {
        await formFunctions.resetPlace()
    };

    const handleStatusExtraChange = (event) => {
        setStatusExtra(event.target.value);
    }

    const handleStatusExtraBlur = (ev) => {
        if (ev && ev.relatedTarget === markButtonRef.current) {
            // If user clicks on the MARK button, we would first submit due to onBlur. Once the MARK button is hit,
            // it is already switched to UNMARK state. To avoid this, we cancel the onBlur, and check that the var
            // commitStatusExtraOnBlur is still set. If it is, we call this method again from the button click handler.
            return;
        }
        if (commitStatusExtraOnBlur) {
            setCommitStatusExtraOnBlur(false);
            handleMarkWithExtra();
        }
    }

    // Export some operations through a ref, as these are also changing internal state of the component.
    React.useImperativeHandle(poiFormRef, () => ({
        workItemDone: () => { handleDone(); },
        workItemMark: () => { handleMark(); },
    }));

    const handleFieldUpdate = (name, value) => {
        if (name == "country_code") {
            // reset region on country change
            formFunctions.updatePOIForm("region_code", null);
        }
        formFunctions.updatePOIForm(name, value);
    }
    const readonly = selectedPOI.access_level !== CarApi.const.AccessLevel.ADMIN
    const formContext = {
        evalContext: evalContext,
        dataOriginal: selectedPOIOrig,
        updateValue: handleFieldUpdate,
        poiFormErrors: !readonly && validateFields(evalContext) || {},
        readonly: readonly,
    };
    // access handling
    const CANNOT_MODIFY_MSG = "You cannot modify this Placeset. Copy this place into a Placeset you own to modify it";
    const isPlacesetMode = [SelectionMode.PLACESET, SelectionMode.PROJECT].includes(modeInfo.selection_mode);
    const isNewPoi = !selectedPOI.place_id;
    const hasExtraFields = isNewPoi ? (
        isPlacesetMode && modeInfo.client_id !== ARRIVALIST_CLIENT_ID
    ) : (
        selectedPOI.client_id !== ARRIVALIST_CLIENT_ID
    );
    const canAdminPlaceset = modeInfo.access_level === CarApi.const.AccessLevel.ADMIN;
    const newButtonTooltip = (
        !isPlacesetMode ? (
            "Select a Project or a Placeset to create a new Place"
        ) : (
            !canAdminPlaceset ? (
                CANNOT_MODIFY_MSG
            ) : (
                "Create a new Place in the active Placeset/Project"
            )
        )
    );
    const hasValidationErrors = Object.entries(formContext.poiFormErrors).length > 0;
    const saveButtonTooltip = (
        formContext.readonly ? (
            CANNOT_MODIFY_MSG
        ) : hasValidationErrors ? (
            "The form has validation errors. Fix those to be able to save."
        ) : (
            "Save changes"
        )
    );
    const hasAssignTarget = !!assignTargetInfo;
    const placesetInfoMessage = hasAssignTarget ?
        `(Target: ${assignTargetInfo.selection_mode.urlform} #${assignTargetInfo.selection_id} - ${assignTargetInfo.title})`
        : "(No target selected)";
    const activePolygonCount = geojson.filter(geo => !geo.properties.delete).length;
    const allowSaveAttempt = selectedPOI.is_overlapping || isPOIDirty;
    return (
        <React.Fragment>
          <Grid container={true}>
              <ButtonGroup variant="contained" style={{marginBottom:"3px"}}>
                  <OptionalTooltip title={newButtonTooltip}>
                      <StatefulButton onClick={handleNew} size="small" variant='contained' color='primary' disabled={!isPlacesetMode || !canAdminPlaceset}>
                          <AddIcon/>
                      </StatefulButton>
                  </OptionalTooltip>
                  <OptionalTooltip title={saveButtonTooltip}>
                      <StatefulButton onClick={handleSave} size="small" variant='contained' color='primary' disabled={!allowSaveAttempt || hasValidationErrors}>
                          <SaveIcon/>
                      </StatefulButton>
                  </OptionalTooltip>
                  <OptionalTooltip title={"Revert changes"}>
                      <StatefulButton onClick={handleReset} size="small" variant='contained' color='primary' disabled={!isPOIDirty}>
                          <RestoreIcon/>
                      </StatefulButton>
                  </OptionalTooltip>
                  <OptionalTooltip title={"Delete place"}>
                      <StatefulButton onClick={formFunctions.openDeletePopup} size="small" variant='contained' color='secondary' disabled={!selectedPOI.place_id || formContext.readonly}>
                          <DeleteIcon/>
                      </StatefulButton>
                  </OptionalTooltip>
              </ButtonGroup>
              <Grid item={true} style={{flexGrow:1}}></Grid>
              <ButtonGroup variant="contained" style={{marginBottom:"3px"}}>
                <OptionalTooltip
                    interactive
                    title={[
                        `${hasAssignTarget?"Clear":"Select"} copy target.`,
                        placesetInfoMessage,
                        hasAssignTarget?(
                            <ModeSelectorButton
                              id={assignTargetInfo.selection_id}
                              label={assignTargetInfo.title}
                              mode={assignTargetInfo.selection_mode}
                              onModeSelect={onModeSelect}
                            />
                        ):null
                    ]}
                >
                  <StatefulButton onClick={()=>formFunctions.handleAssignTarget(hasAssignTarget?null:undefined)} size="small" variant='contained' color='primary'>
                    {hasAssignTarget?<NotInterestedIcon/>:<AdjustIcon/>}
                  </StatefulButton>
                </OptionalTooltip>
                <OptionalTooltip title={["Copy selected place", placesetInfoMessage]}>
                  <StatefulButton onClick={formFunctions.handleAssignPlace} size="small" variant='contained' color='primary' disabled={!hasAssignTarget || !selectedPOI.place_id}>
                    <AddBoxIcon/>
                  </StatefulButton>
                </OptionalTooltip>
                <OptionalTooltip title={["Copy all results", placesetInfoMessage]}>
                  <StatefulButton onClick={formFunctions.handleAssignFilter} size="small" variant='contained' color='primary' disabled={!hasAssignTarget}>
                    <AddToPhotosIcon/>
                  </StatefulButton>
                </OptionalTooltip>
              </ButtonGroup>
              {poiHasWorkItems &&
                  <ButtonGroup variant="contained"
                               style={{paddingBottom:"5px",
                                   backgroundColor:"#88f",
                                   borderBottomLeftRadius:"0px",
                                   borderBottomRightRadius:"0px",
                                   boxShadow:"none"}}>
                      <Button onClick={handleDone} size="small" style={{borderBottomLeftRadius:"0px"}}>
                          { (poiIsOpenWorkItem && "Mark Completed") || "Work Still Needed" }
                      </Button>
                      <Button onClick={handleMark}
                              size="small"
                              ref={markButtonRef}
                              style={{borderBottomRightRadius:"0px"}}>
                          { (poiIsMarkedWorkItem && "Remove Issue") || "Flag Issue" }
                      </Button>
                  </ButtonGroup>
              }
          </Grid>
          { displayStatusExtra && (
              <Grid container={true}
                    style={{backgroundColor:"#88f",
                        borderRadius:"4px",
                        borderTopRightRadius:"0px"}}>
                  <TextField
                      style={{flexGrow:1}}
                      InputProps={{
                          readOnly: poiIsMarkedWorkItem,
                      }}
                      onKeyDown={(ev) => handleMarkWithExtraKeyPress(ev)}
                      onChange={handleStatusExtraChange}
                      onBlur={handleStatusExtraBlur}
                      inputRef={statusExtraInputRef}
                      placeholder={(!poiIsMarkedWorkItem && "Reason cannot do this assignment?") || ""}
                      value={statusExtra}/>
                      {!poiIsMarkedWorkItem && (<Button onClick={handleMarkWithExtra}><SendIcon/></Button>)}
              </Grid>
          )}
          <Tabs
             value={tabValue}
             onChange={handleChange} 
             aria-label="simple tabs example"
             TabIndicatorProps={{
                 style: {
                     backgroundColor: "#26ce12"
                 }
             }}
             centered
          >
            <Tab label="Attributes" {...a11yProps(0)} />
            <Tab label={
                <Badge className={classes.badgePadding} color="primary" badgeContent={activePolygonCount}>
                    Polygons
                </Badge>
            } {...a11yProps(1)} />
          </Tabs>

          <TabPanel style={{width: '100%', overflow:'auto'}} value={tabValue} index={0}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container>
                <PoiFormField context={formContext} fieldname='name'/>
                {hasExtraFields &&
                    [
                        <PoiFormField key='.hyperlocal_destination_type' context={formContext} fieldname='.hyperlocal_destination_type' />,
                        <PoiFormField key='.hyperlocal_destination_category' context={formContext} fieldname='.hyperlocal_destination_category' />,
                        <PoiFormField key='.is_target_poi' context={formContext} fieldname='.is_target_poi' />,
                    ]
                }
                <PoiFormField context={formContext} fieldname='address'/>
                <PoiFormField context={formContext} fieldname='city'/>
                <PoiFormField context={formContext} fieldname='postal_code'/>
                <PoiFormField context={formContext} fieldname='region_code'/>
                <PoiFormField context={formContext} fieldname='country_code'/>
                <PoiFormField context={formContext} fieldname='naics_code'/>
                <PoiFormField context={formContext} fieldname='brand_name'/>
                <PoiFormField context={formContext} fieldname='phone_number'/>
                <PoiFormField context={formContext} fieldname='address_extended'/>
                {hasExtraFields &&
                  [
                      <PoiFormField context={formContext} fieldname='.min_dwell_time_minutes' key='.min_dwell_time_minutes'/>,
                      <PoiFormField context={formContext} fieldname='.max_dwell_time_minutes' key='.max_dwell_time_minutes'/>,
                      <PoiFormField context={formContext} fieldname='time_zone_manual' key='time_zone_manual'/>
                  ]
                }
                {(placeset?.extra_fields || []).map(extraFieldKey => (
                  <PoiFormField context={formContext} fieldname={extraFieldKey} key={extraFieldKey}/>
                ))}
              </Grid>
            </form>
      </TabPanel>
      <TabPanel style={{height:"100%"}} value={tabValue} index={1}>
          {lodash.zip(geojson, geojsonOrig).filter(([geo]) => !geo.properties.delete).map(([geo, geoOrig]) => { return <GeoRow formFunctions={formFunctions} geo={geo} geoOrig={geoOrig} key={geo.properties.map_geo_id} />})}
      </TabPanel>
    </React.Fragment>
    );
}