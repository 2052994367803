import React from 'react';
import CarApi from "../../api/CarApi";
import {Box} from "@material-ui/core";
import {POPUP, POPUP_TIMING, POPUP_TYPES, showNotification} from "../../notifications";
import StatefulButton from "../StatefulButton";

export function ValidatePlaceset({placesetId:placesetId}) {
    const handleValidate = async () => {
        try {
            const res = await CarApi.validatePlaceset(placesetId);
            if (res.data) {
                showNotification({message: "The placeset is valid"});
            } else {
                showNotification({
                    message: "The placeset is not valid. Use `has_overlap:1` filter to find erroneous places",
                    type: POPUP_TYPES.ERROR,
                    timing: POPUP_TIMING.LONG,
                });
            }
        } catch (exc) {
            console.log(exc);
            showNotification({
                message: "Error during processing request",
                type: POPUP_TYPES.ERROR
            });
        }
    };
    return (
        <Box pt={2}>
            Rerun validation of the places:
            <StatefulButton
                onClick={()=>handleValidate()}
                color="primary"
            >
                Validate places
            </StatefulButton>
        </Box>
    );
}
