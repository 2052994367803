import {normalizeForCss} from "../../utils/utils";
import {Box, Grid} from "@material-ui/core";
import {AssignButton} from "./utils";
import {AssignValueType, MERGE_GRID_COLUMN} from "./constants";
import React from "react";
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";
import TextField from "@material-ui/core/TextField";

function MergeTaskTableHeaderFilter({columnData, dataKey}) {
    const filters = columnData.tableData.filters;
    const setFilterValue = columnData.tableData.setFilterValue;
    const value = filters[dataKey] || "";
    if (columnData.options) {
        return (
            <AriAutocomplete
                options={columnData.options}
                labelGetter={fieldGetter("label")}
                valueGetter={fieldGetter("value")}
                value={value}
                fullWidth
                className="prodtour-header-filter"
                onChange={(value) => setFilterValue(dataKey, value)}
            />
        )
    } else {
        return (
            <TextField
                className="prodtour-header-filter"
                value={value}
                fullWidth
                onChange={(event) => setFilterValue(dataKey, event.target.value)}
            />
        )
    }
}

function MergeTaskTableHeaderAssign({columnData, dataKey}) {
    const assigners = columnData.tableData.assigners;
    const setAssignValue = columnData.tableData.setAssignValue;
    const value = assigners[dataKey] || "";
    if (dataKey === MERGE_GRID_COLUMN.action.key) {
        return (
            <Box
                className="prodtour-header-assign"
            >
                <AriAutocomplete
                    options={columnData.options}
                    labelGetter={fieldGetter("label")}
                    valueGetter={fieldGetter("value")}
                    value={value}
                    fullWidth
                    onChange={(value) => setAssignValue(dataKey, value)}
                    outerStyle={{
                        width: "calc(100% - 20px)",
                        display: "inline-flex",
                    }}
                />
                <AssignButton
                    handler={columnData.tableData.handleAssignValue}
                    valueType={AssignValueType.ACTION}
                    value={value}
                />
            </Box>
        );
    } else if (dataKey === MERGE_GRID_COLUMN.value.key) {
        return (
            <Box
                className="prodtour-header-assign"
            >
                <TextField
                    value={value}
                    onChange={(event) => setAssignValue(dataKey, event.target.value)}
                    style={{width: "calc(100% - 20px)"}}
                />
                <AssignButton
                    handler={columnData.tableData.handleAssignValue}
                    valueType={AssignValueType.VALUE}
                    value={value}
                />
            </Box>
        );
    } else {
        throw `Unsupported column: '${dataKey}`;
    }
}

function MergeTaskTableHeaderLabel({label, columnData}) {
    const labelClassName = `prodtour-header-col-${normalizeForCss(label)}`;
    return (
        <Box
            className={`${labelClassName} ${columnData.assignDecisionValue ? "prodtour-header-labelassign" : ""}`}
        >
            {label}
            {columnData.assignDecisionValue && <AssignButton
                handler={columnData.tableData.handleAssignValue}
                valueType={AssignValueType.DECISION}
                value={columnData.assignDecisionValue}
            />}
        </Box>
    );
}

export function MergeTaskTableHeader({columnData, dataKey, disableSort, label, sortBy, sortDirection}) {
    const hasFilter = dataKey !== 'place';
    const hasAssign = ['value', 'action'].includes(dataKey);
    return <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
    >
        <Grid item style={{width: '100%', height: 30}}>
            {hasFilter && (
                <MergeTaskTableHeaderFilter columnData={columnData} dataKey={dataKey}/>
            )}
        </Grid>
        <Grid item style={{width: '100%', height: 30}}>
            {hasAssign && (
                <MergeTaskTableHeaderAssign columnData={columnData} dataKey={dataKey}/>
            )}
        </Grid>
        <Grid item style={{width: '100%', height: 30}}>
            <MergeTaskTableHeaderLabel label={label} columnData={columnData}/>
        </Grid>
    </Grid>
}
