/**
 * localStorage based cache
 *
 * Usage:
 * setValue(['a', 34, 23], "expensiveData", 10000);  // set data for 10 seconds
 * const data = getValue(['a', 34, 23]);  // read back data
 * pruneCache();  // remove expired items;
 * wipeCache();  // remove all items;
 * const data2 = getValue(['a', 34, 23]);  // data2 will be undefined
 *
 */
import {useEffect, useState} from "react";

const CACHE_PREFIX = "lsc:";
const DEFAULT_CACHE_TIMEOUT_MS = 15*60*1000;

function getCacheKey(key) {
    return CACHE_PREFIX + JSON.stringify(key)
}

if (localStorage === undefined) {
    console.log("No cache detected");
}

export function getValue(key) {
    if (localStorage === undefined) {
        return;
    }
    const cacheKey = getCacheKey(key);
    return getCachedValueInternal(cacheKey);
}

function getCachedValueInternal(rawKey) {
    const value = JSON.parse(localStorage.getItem(rawKey));
    if (value === null || value.data === undefined) {
        return;
    }
    if (value.timestamp === undefined || (Date.now() > value.timestamp)) {
        localStorage.removeItem(rawKey);
        return;
    }
    return value.data;
}

export function setValue(key, value, timeout_ms) {
    if (localStorage === undefined) {
        return;
    }
    const cacheKey = getCacheKey(key);
    const item = JSON.stringify({
        data: value,
        timestamp: Date.now() + (timeout_ms || DEFAULT_CACHE_TIMEOUT_MS),
    });
    localStorage.setItem(cacheKey, item);
}

function iterCache(functor) {
    const prefix = CACHE_PREFIX;
    if (localStorage === undefined) {
        return;
    }
    Object.entries(localStorage).map(
        x => x[0]  // get keys
    ).filter(
        x => x.substring(0, prefix.length) === prefix
    ).map(
        functor
    )
}

/**
 * Remove all cached entries
 */
export function wipeCache() {
    iterCache(
        x => localStorage.removeItem(x)
    )
}

/**
 * Remove expired items from cache
 */
export function pruneCache() {
    iterCache(
        x => getCachedValueInternal(x)
    )
}
