import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import TextField from '@material-ui/core/TextField';
import wkt from 'terraformer-wkt-parser';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import geohash from 'ngeohash';

/**
 * Convert list of geohashes into a GeoJson polygon or multipolygon
 * Uses ngeohash for conversion.
 * Does geohash validation as ngeohash does no do it.
 * Returns null on invalid input.
 * @param geohashes A list of geohashes, one geohash per line.
 * @returns {DirectGeometryObject|GeometryCollection|null}
 */
function getGeoJsonFromGeoHash(geohashes) {
  let reGeoHash = /^[0123456789bcdefghjkmnpqrstuvwxyz]{3,12}$/;
  const wkt_polygons = geohashes.trim().split("\n").map((value)=>{
    const clean_geohash = value.trim();
    if (!reGeoHash.test(clean_geohash)) {
      // Not a valid geohash, fail
      return null;
    }
    const [minLat, minLon, maxLat, maxLon] = geohash.decode_bbox(clean_geohash);
    const data = `((${minLon} ${minLat}, ${maxLon} ${minLat}, ${maxLon} ${maxLat}, ${minLon} ${maxLat}, ${minLon} ${minLat}))`;
    return data;
  });
  if (wkt_polygons.some(x => x === null)) {
    // Input had some invalid polygons, fail
    return null;
  }
  const wkt_data = wkt_polygons.length === 1 ? `POLYGON ${wkt_polygons[0]}` : `MULTIPOLYGON (${wkt_polygons.join(', ')})`;
  return Object.assign({}, wkt.parse(wkt_data.toUpperCase()));
}

export function ImportPolygonDialog({onSave, open}) {
  const [value, setValue] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [geoJson, setGeoJson] = React.useState(null);
  const handleClose = () => {
    onSave(null);
  };
  const handleSave = async () => {
    onSave(geoJson);
    setValue("");
  };
  React.useEffect(() => {
    let type = null;
    let geoJsonData = null;
    let status = null;
    try {
      geoJsonData = wkt.parse(value.toUpperCase());
      geoJsonData = Object.assign({}, geoJsonData);
      type = "WKT";
    } catch (err) {}
    if (type === null) {
      try {
        geoJsonData = getGeoJsonFromGeoHash(value);
        if (geoJsonData !== null) {
          type = "Geohash";
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (geoJsonData === null) {
      status = "ERROR: Unknown or invalid format";
    } else if (geoJsonData.type !== "Polygon") {
      status = `WARNING: Expected polygon but received ${geoJsonData.type}. Your data might be filtered or split into multiple separate Geos on save.`;
    } else {
      status = `Valid ${type} polygon`;
    }
    setStatus(status);
    // FIXME: Should validate that the geojson is correct
    //   Have seen cases where the LinearRing was not ending at the same coords as the start,
    //   this caused mapbox-gl-draw to throw:
    //   Error('the first and last positions in a LinearRing of coordinates must be the same')
    setGeoJson(geoJsonData);
  }, [value]);
  return (
      <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Import polygon from text</DialogTitle>
        <DialogContent style={{width:600}}>
          Supported formats:&nbsp;
          <a
              href="https://en.wikipedia.org/wiki/Well-known_text_representation_of_geometry"
              target="_blank"
              rel="noopener noreferrer"
          >
            WKT
            <OpenInNewIcon
                fontSize="small"
                style={{verticalAlign:"bottom"}}
            />
          </a>
          ,&nbsp;
          <Tooltip title={(
              <Box>
                Geohash parsing is added mainly for debugging purposes, it is not a useful way for polygon data input.<br/>
                You can enter multiple geohashes, each on a separate line.<br/>
                Only geohash codes with length between 3..12 are supported.<br/>
              </Box>)}
          >
            <a
                href="https://en.wikipedia.org/wiki/Geohash"
                target="_blank"
                rel="noopener noreferrer"
            >
              Geohash
              <OpenInNewIcon
                  fontSize="small"
                  style={{verticalAlign:"bottom"}}
              />
            </a>
          </Tooltip>
          <TextField
              value={value}
              onChange={e=>setValue(e.target.value)}
              placeholder="Paste your data here"
              multiline
              fullWidth
              rows={15}
          /><br/>
          {status}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave}  color="primary" disabled={geoJson===null}>
            Add polygon
          </Button>
        </DialogActions>
      </Dialog>
  );
}
