import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/styles";

/**
 * Stateful button - Disable while onclick is running
 *
 * NOTE: Added a fix to support tooltips on disabled buttons:
 *   https://stackoverflow.com/a/63276424/1442987
 */
const StatefulButtonBase = React.forwardRef((props, ref) => {
    const [executing, setExecuting] = useState(false);

    const {
        disabled,
        onClick,
        ...otherProps
    } = props;
    const realDisabled = executing || disabled;

    const onRealClick = async (event) => {
        setExecuting(true);
        try {
            await onClick(event);
        } finally {
            setExecuting(false);
        }
    };

    return (
        <Button
            onClick={realDisabled?undefined:onRealClick}
            disabled={realDisabled}
            component={realDisabled?"div":undefined}
            ref={ref}
            {...otherProps}
        />
    )
});

const StatefulButton = withStyles({
    root: {
        "&.Mui-disabled": {
            pointerEvents: "auto"
        }
    }
})(StatefulButtonBase);

export default StatefulButton;
