import {MERGE_GRID_COLUMN} from "./constants";
import {COLUMNS_WITH_COMPARE_TOOLTIP} from "./MergeTaskTooltip";

/**
 * Get row-col info from mouse event over the table
 * NOTE: Assumes that this is the only table on the page
 * @param event
 * @returns {null|{field: *, placeId, colIndex: string, gridNode: (() => (Node | null)) | ActiveX.IXMLDOMNode | (Node & ParentNode)}}
 */
export function decodeMouseEvent(event) {
    let node = event.target;
    while (node !== null && node.getAttribute('role') !== 'gridcell') {
        node = node.parentNode;
        if (node.nodeName === "#document") {
            node = null;
        }
    }
    if (node === null) {
        return;
    }
    const firstGrid = node.parentNode.firstChild;
    return {
        gridNode: node,
        placeId: firstGrid.title,
        field: firstGrid.nextSibling.title,
        colIndex: node.getAttribute('aria-colindex') | 0,
        rowIndex: node.parentNode.getAttribute('data-rowindex') | 0,
    }
}

function isNodeOverflowing(node) {
    const isOverflowing = node.clientWidth < node.scrollWidth || node.clientHeight < node.scrollHeight;
    return isOverflowing;
}

function isGridCellOverflowing(gridCell) {
    return isNodeOverflowing(gridCell)
        || Array.from(gridCell.childNodes).some(node =>
            node.hasAttribute("data-preview")
            && isNodeOverflowing(node));
}

export function gridHasTooltip(info) {
    if (!info) {
        return false;
    }
    if (info.colIndex === MERGE_GRID_COLUMN.place.id) {
        return true;
    }
    if ([MERGE_GRID_COLUMN.origin.id, MERGE_GRID_COLUMN.current.id].includes(info.colIndex) &&
        (COLUMNS_WITH_COMPARE_TOOLTIP.includes(info.field) || isGridCellOverflowing(info.gridNode))) {
        return true;
    }
    return false;
}

export function gridHasEditor(info) {
    return [MERGE_GRID_COLUMN.action.id, MERGE_GRID_COLUMN.value.id].includes(info?.colIndex);
}
