import React, {useCallback, useEffect, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import lodash from "lodash";
import WarningIcon from '@material-ui/icons/Warning';
import {getQueryValidationMessages} from "../utils/filterFormUtils";
import {OptionalTooltip} from "./OptionalTooltip";

const TEXT_DEBOUNCE_TIME_MS = 200;

export default function FilterInput({value: valueProp, onInputChange: onInputChangeProp, label: labelProp, inputRef: refProp, toggleHelp: toggleHelpProp, reloadStat: reloadStatProp}){
    const [value, setValue] = useState(valueProp)
    const [hasLocalChange, setHasLocalChange] = useState(false);
    useEffect(() => {
        if (!hasLocalChange) {
            setValue(valueProp)
        }
        valuePropValue.current = valueProp;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueProp]);
    const valuePropValue = useRef(valueProp);
    const onInputChangeDebounced = useRef(lodash.debounce((p1, p2) => {
        setHasLocalChange(false);
        let result = onInputChangeProp(p1, p2);
        if (result === false) {
            setValue(valuePropValue.current);
        }
    }, TEXT_DEBOUNCE_TIME_MS));

    const onInputChange = (event) => {
        let newValue = event.target.value;
        setHasLocalChange(true);
        onInputChangeDebounced.current(newValue, {action:'input-change'});
        setValue(newValue);
    }

    const onKeyDown = (event) => {
        if ((event.key === 'i' || event.key === 'I') && event.ctrlKey) {
            event.preventDefault();
            reloadStatProp();
        }
    };

    const onKeyPress = (event) => {
        if (event.key === '?') {
            // allow propagation, so hotkey detects it, but prevent
            // default so it wont show up in the input.
            event.preventDefault();
            toggleHelpProp();
        }
    };

    const validationMessages = getQueryValidationMessages(value);
    const hasValidationMessages = validationMessages.length > 0;

    return (
        <TextField
            inputRef={refProp}
            style={{
                marginTop: '6px',
                flexGrow: 1,
                background: hasValidationMessages ? '#ffff66' : '#ffffff',
            }}
            id="outlined-basic"
            autoComplete="off"
            placeholder="Search POIs..."
            margin="dense"
            value={value}
            onChange={onInputChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            label={hasValidationMessages?"Query has errors...":labelProp}
            variant="outlined"
            InputProps={!hasValidationMessages ? {} : {
                endAdornment: (
                    <OptionalTooltip title={validationMessages}>
                        <WarningIcon/>
                    </OptionalTooltip>
                ),
            }}
        />
    )
}
