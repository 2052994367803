import React from 'react';
import {Box} from "@material-ui/core";

export const PRODUCT_TOUR_STEPS = [
    {
        target: '.prodtour-merge-help',
        content: (
            <Box width={450} style={{textAlign:"left"}}>
                Merge allows you to update copied places with changes made to the origin.<br/>
                <br/>
                The typical usecase looks like this:<br/>
                1. We import millions of places from SafeGraph<br/>
                2. You filter for some subset of these records (e.g.: brand ACME)<br/>
                3. You copy all ACME places to a new placeset, and run some projects with it<br/>
                4. SafeGraph changes the NAICS code for all the ACME places<br/>
                <br/>
                Automatically accepting the changes from SafeGraph would potentially change the
                reports, so this is where you jump in, and decide what changes are fine, and what
                are not.<br/>
                <br/>
                The system automatically collects all updates (one row for each field changed), and provides
                you with this UI to resolve these updates.<br/>
                <br/>
                Read through this tutorial to familiarize yourself with the UI and the process.
            </Box>
        ),
        disableBeacon: true,
    },
    {
        target: '.prodtour-header-col-place',
        content: 'Displays the ID of the place. Hovering rows displays more info about the place.',
    },
    {
        target: '.prodtour-header-col-field',
        content: 'The name of the field whose value changed.',
    },
    {
        target: '.prodtour-header-col-change-in-origin',
        content: 'How the value changed in the original data. Hovering rows displays more info about the change.',
    },
    {
        target: '.prodtour-header-col-current',
        content: 'Displays the data in our current copy. Hovering rows displays more info.',
    },
    {
        target: '.prodtour-header-col-value',
        content: 'Displays the value to be used as the result of the merge. Clicking on a' +
            ' cell opens up an editor to change the value in place.',
    },
    {
        target: '.prodtour-header-col-action',
        content: (
            <Box width={450} style={{textAlign:"left"}}>
                The action merge should take with the value.

                <dl>
                    <dt>Hold</dt>
                    <dd>The operator (you) marked this change for being held. The merge of this
                        place won't be completed.</dd>

                    <dt>Resolved</dt>
                    <dd>The operator decided the value, the merging can take place.</dd>

                    <dt>Undecided</dt>
                    <dd>The operator did not made a decision yet. (This is the default)</dd>
                </dl>

                When the merge tasks are generated, they are initially created in <i>Undecided</i>
                state.<br/>
                The merge only completes when all the tasks for a place are in the <i>Resolved</i> state.<br/>
                Once you assign a value to a task, it automatically sets into <i>Resolved</i> except if it is set into <i>Hold</i> state.<br/>
                Thus <i>Hold</i> can be used to mark records requiring further review.<br/>
                <br/>
                Protip: If you are fine with the tools decision you just need to mass assign (see later) <i>Resolved</i> to all records and save.<br/>
            </Box>
        ),
    },
    {
        target: '.prodtour-header-col-decision',
        content: (
            <Box width={450} style={{textAlign:"left"}}>
                Which value to be used by the merge.<br/>
                <br/>
                By default the system tries to come up with a decision, so the initial state
                shows what the system thinks should be done. If it has no idea, it will use the
                <i>Conflict</i> state to mark those records.<br/>

                <dl>
                    <dt>Theirs</dt>
                    <dd>The value only changed in the original source, lets use that.</dd>

                    <dt>Ours</dt>
                    <dd>Our change is more relevant.</dd>

                    <dt>Conflict</dt>
                    <dd>Both the origin and our copy changed in different ways. Manual decision needed.</dd>

                    <dt>Inplace</dt>
                    <dd>The operator provided an edited, in-place, value for the field.</dd>
                </dl>
            </Box>
        ),
    },
    {
        target: '.prodtour-stats',
        content: 'The stats table gives you a quick overview of the records and your prograss via some statistics on Record/Decision/Action basis.',
    },
    {
        target: '.prodtour-header-filter',
        content: 'This row of controls lets you filter some records. Actions are only applied to filtered records.',
    },
    {
        target: '.prodtour-header-assign',
        content: 'You can mass assign a single value to all filtered rows.',
    },
    {
        target: '.prodtour-header-labelassign',
        content: 'The arrow on the right lets you assign value from the original source or use our current value.',
    },
    {
        target: '.prodtour-undo',
        content: (
            <Box width={450} style={{textAlign:"left"}}>
                Would you realize you made some premature decisions the undo/redo buttons
                let you correct these.<br/>
                <br/>
                This also means that you can experiment with the tool without the risk of
                breaking/changing something.<br/>
                <br/>
                NOTE: The only button that finalizes changes is the `Save` button.
            </Box>
        ),
    },
];
