import React from 'react';
import {Box, Button, Checkbox, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CarApi from "../../api/CarApi";
import lodash from 'lodash';
import {useValueset} from "../../services/valuesets";

export function PlacesetSettingsExtraFieldsTab({placeset, onChange}) {
    const [fieldValueset] = useValueset("place_field");
    const [extraFields, setExtraFields] = React.useState([]);
    const [placesetExtraFieldsOriginal, setPlacesetExtraFieldsOriginal] = React.useState(new Set([]));
    const [placesetExtraFields, setPlacesetExtraFields] = React.useState(new Set([]));
    React.useEffect(() => {
        setExtraFields(fieldValueset.filter(row => row.group_label=="Extra"))
    }, [fieldValueset]);
    const updateFromPlaceset = async (placeset) => {
        setPlacesetExtraFields(new Set(placeset?.extra_fields || []));
        setPlacesetExtraFieldsOriginal(new Set(placeset?.extra_fields || []));
    }
    React.useEffect(() => {
        updateFromPlaceset(placeset);
    }, [placeset]);
    const handleReset = () => {
        updateFromPlaceset(placeset);
    }
    const handleSave = async () => {
        await CarApi.updatePlaceset({
            placeset_id: placeset?.id,
            extra_fields: Array.from(placesetExtraFields),
        });
        onChange();
    }
    const handleExtraFieldChange = (extraFieldKey, value) => {
        const newPlacesetExtrafields = new Set(placesetExtraFields);
        if (value) {
            newPlacesetExtrafields.add(extraFieldKey);
        } else {
            newPlacesetExtrafields.delete(extraFieldKey);
        }
        setPlacesetExtraFields(newPlacesetExtrafields);
    }
    const isDirty = !lodash.isEqual(placesetExtraFields, placesetExtraFieldsOriginal)
    // FIXME: Move save/reset back to bottom once the flex-height of the Area/POI component is fixed
    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <Button
                                onClick={handleReset}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}>
                                Reset
                            </Button>
                            <Button
                                onClick={handleSave}
                                style={{marginLeft:8}}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}>
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                {
                    extraFields.map((extraField) =>
                        <Grid item xs={4} key={extraField.key}>
                            <Paper elevation={2} style={{padding: 8, margin: 8}}>
                                <Checkbox
                                    style={{padding: 2}}
                                    size="small"
                                    checked={placesetExtraFields.has(extraField.key)}
                                    onChange={(event) => handleExtraFieldChange(extraField.key, event.target.checked)}/>
                                {extraField.label} ({extraField.key})<br/>
                                {extraField.description || "No description yet."}
                            </Paper>
                        </Grid>
                    )
                }
                </Grid>
            </Box>
        </Box>
    );
}
