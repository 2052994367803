import React from "react";
import {Box, Button, Grid, Paper, Popper} from "@material-ui/core";
import StaticPlaceGeoMap from "../StaticPlaceGeoMap";
import CarApi from "../../api/CarApi";
import TextField from "@material-ui/core/TextField";
import {isEmptyArray} from "../../utils/utils";
import {MERGE_GRID_COLUMN} from "./constants";

function PaperValue({value}) {
    return !!value ? (<Paper>
        {String(value)}
    </Paper>) : (<Box>
        {"<empty>"}
    </Box>);
}

function TwoWayTextCompareTooltip({field, leftLabel, left, rightLabel, right}) {
    return (
        <Box>
            {leftLabel}
            <PaperValue value={left}/>
            {rightLabel}
            <PaperValue value={right}/>
        </Box>
    );
}

/**
 * Return the whole list or filter for only a single element of it
 * returns an empty-list if the list is not provided
 * @returns {*[]}
 */
function getIndexedOrAll(list, index) {
    return index === null ? (list||[]) : [list[index]];
}

function TwoWayGeoCompareTooltipGeoList({label, list, index, updateIndex}) {
    const isEmpty = list === null || list === undefined || isEmptyArray(list);
    const handleClick = idx => {
        if (index === idx) {
            idx = null;
        }
        updateIndex(idx);
    }
    return (
        <React.Fragment>
            <Box>
                {label}
            </Box>
            {
                isEmpty ? (
                    <i>has no polygons</i>
                ) : list.map((geo, idx) =>
                    <Button
                        variant="contained"
                        color={index===idx?"primary":undefined}
                        onClick={()=>handleClick(idx)}
                    >
                        {idx+1} - {geo.geo_type}
                    </Button>
                )
            }
        </React.Fragment>
    );
}

function TwoWayGeoCompareTooltip({field, leftLabel, left, rightLabel, right}) {
    const [geos, setGeos] = React.useState([]);
    const [leftIndex, setLeftIndex] = React.useState(null);
    const [rightIndex, setRightIndex] = React.useState(null);
    React.useEffect(() => {
        const geos = [
            ...getIndexedOrAll(left, leftIndex).map((geo) => {
                return {
                    geo_text: geo.geo_text,
                    color: "blue",
                }
            }),
            ...getIndexedOrAll(right, rightIndex).map((geo) => {
                return {
                    geo_text: geo.geo_text,
                    color: "yellow",
                }
            }),
        ];
        setGeos(geos);
    }, [left, right, leftIndex, rightIndex]);
    const hasSome = (left||[]).length > 0 || (right||[]).length > 0;
    return (
        <Box>
            <StaticPlaceGeoMap
                geos={geos}
                height={500}
                width={500}
            />
            { hasSome &&
            <Paper>
                <Box p={1}>
                    If a source has multiple polygons, you can select to display a single one.
                    <TwoWayGeoCompareTooltipGeoList
                        label={`${leftLabel} (blue)`}
                        list={left}
                        index={leftIndex}
                        updateIndex={setLeftIndex}
                    />
                    <TwoWayGeoCompareTooltipGeoList
                        label={`${rightLabel} (yellow)`}
                        list={right}
                        index={rightIndex}
                        updateIndex={setRightIndex}
                    />
                </Box>
            </Paper>
            }
        </Box>
    );
}

function TwoWayLatLonCompareTooltip({field, leftLabel, left, rightLabel, right}) {
    const point2geos = (value, label) => {
        const [lat, lon] = value.split(",");
        const geometry = {
            "type": "Point",
            "coordinates": [
                parseFloat(lon),
                parseFloat(lat),
            ],
        }
        return [{
            geo_text: JSON.stringify(geometry),
            geo_type: label,
        }];
    }
    return (
        <TwoWayGeoCompareTooltip
            field={field}
            leftLabel={leftLabel}
            left={point2geos(left, leftLabel)}
            rightLabel={rightLabel}
            right={point2geos(right, rightLabel)}
        />
    );
}

const COMPARE_IMPLS = {
    "geo": TwoWayGeoCompareTooltip,
    "lat-lon": TwoWayLatLonCompareTooltip,
};

export const COLUMNS_WITH_COMPARE_TOOLTIP = Object.keys(COMPARE_IMPLS);

function TwoWayCompareTooltip({field, leftLabel, left, rightLabel, right}) {
    const TooltipRenderer = COMPARE_IMPLS[field] || TwoWayTextCompareTooltip
    return (
        <TooltipRenderer
            field={field}
            leftLabel={leftLabel}
            left={left}
            rightLabel={rightLabel}
            right={right}/>
    );
}

const POI_FIELDS = [
    {label:'Name', fieldname:'name',},
    {label:'Address', fieldname:'address',},
    {label:'City', fieldname:'city',},
    {label:'Postal Code', fieldname:'postal_code',},
    {label:'State/Region', fieldname:'region_code',},
    {label:'Country', fieldname:'country_code',},
    {label:'NAICS', fieldname:'naics_code',},
    {label:'Brand Name', fieldname:'brand_name',},
    {label:'Phone Number', fieldname:'phone_number',},
    {label:'Address Extended', fieldname:'address_extended',},
];

function PlaceTooltip({row}) {
    const [place, setPlace] = React.useState();
    const [geos, setGeos] = React.useState([]);
    const [status, setStatus] = React.useState();
    const refresh = React.useCallback(async () => {
        try {
            const res = await CarApi.getPlace(row.place_id);
            const place = res.data;
            setPlace(place);
            setGeos(place.place_geos.map(geo => {
                return {
                    geo_text: geo.geo_text,
                }
            }));
        } catch (err) {
            if (err.response.status === 410) {
                setStatus("Place is deleted");
            } else {
                setStatus("Connection or server error");
            }
        }
    }, [row]);
    React.useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Paper style={{width:'480px', height:"480px"}}>
            { !place ? (
                status
            ) : (
                <React.Fragment>
                    <Box width={480} height={250}>
                        <StaticPlaceGeoMap location={place} geos={geos} height={250}/>
                    </Box>
                    <Grid container>
                        {
                            POI_FIELDS.map( ({label, fieldname}) => (
                                <Grid item xs={6} key={fieldname}>
                                    <TextField
                                        fullWidth
                                        label={label}
                                        value={place[fieldname]}
                                        inputProps={{
                                            readonly: true,
                                        }}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </React.Fragment>
            )}
        </Paper>
    );
}

function OriginTooltip({row}) {
    return (
        <TwoWayCompareTooltip
            field={row.field}
            leftLabel={"old"}
            left={row.us_from_value}
            rightLabel={"new"}
            right={row.us_to_value}
        />
    );
}

function CurrentTooltip({row}) {
    return (
        <TwoWayCompareTooltip
            field={row.field}
            leftLabel={"origin"}
            left={row.us_to_value}
            rightLabel={"current"}
            right={row.ds_value}
        />
    );
}

export function MergeTaskTooltip({info, mergeTasks}) {
    const row = mergeTasks[info?.rowIndex];
    return (
        <Popper
            id="merge-task-tooltip"
            open={!!info}
            anchorEl={info?.gridNode}
            placement="top-start"
        >
            <Box
                style={{
                    backgroundColor: "rgba(97, 97, 97, 0.9)",
                    color: "#fff",
                    padding: "4px 8px",
                    fontSize: "0.875em",
                    maxWidth: "900px",
                    borderRadius: "4px",
                }}
            >{
                info?.colIndex === MERGE_GRID_COLUMN.place.id ? (
                    <PlaceTooltip row={row}/>
                ) : info?.colIndex === MERGE_GRID_COLUMN.origin.id ? (
                    <OriginTooltip row={row}/>
                ) : info?.colIndex === MERGE_GRID_COLUMN.current.id ? (
                    <CurrentTooltip row={row}/>
                ) : ""
            }</Box>
        </Popper>
    );
}
