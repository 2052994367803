import React from "react";
import lodash from "lodash";
import {Box, Button, makeStyles, Modal, Paper} from "@material-ui/core";
import StaticPlaceGeoMap from "../StaticPlaceGeoMap";
import TextField from "@material-ui/core/TextField";
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";
import {ACTION_OPTIONS, AssignValueType, MERGE_GRID_COLUMN} from "./constants";

const useStyles = makeStyles((theme) => ({
    modelPaper: {
        position: 'absolute',
        minWidth: 400,
        maxWidth: 1000,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

function GenericValueEditor({value, onUpdate}) {
    const [realValue, setRealValue] = React.useState(null);
    React.useEffect(() => {
        setRealValue(value);
    }, [value]);
    let fieldArgs = {
        autoComplete: 'new-password',  // common hack to disable suggestions
        margin: "dense",
        fullWidth: true,
    };
    const handleChange = (value) => {
        setRealValue(value);
    }
    const handleKeyDown = (event) => {
        if (event.which === 13) {
            if (realValue !== value) {
                onUpdate(AssignValueType.VALUE, realValue);
            } else {
                onUpdate(AssignValueType.NULL);
            }
        }
    }
    const handleSave = (realValue) => {
        const changed = realValue !== value;
        if (changed) {
            onUpdate(AssignValueType.VALUE, realValue);
        }
    }
    return (
        <TextField
            autoFocus={true}
            value={realValue||""}
            onBlur={()=>handleSave(realValue)}
            onChange={(event) => handleChange(event.target.value)}
            onKeyDown={handleKeyDown}
            {...fieldArgs}
        />
    );
}

function ActionEditor({value, onUpdate}) {
    const [realValue, setRealValue] = React.useState(null);
    React.useEffect(() => {
        setRealValue(value);
    }, [value]);
    const handleSave = (realValue) => {
        const changed = realValue !== value;
        if (changed) {
            onUpdate(AssignValueType.ACTION, realValue);
        }
    }
    return (
        <AriAutocomplete
            autoFocus={true}
            options={ACTION_OPTIONS}
            labelGetter={fieldGetter("label")}
            valueGetter={fieldGetter("value")}
            value={value}
            fullWidth
            disableClearable
            onChange={handleSave}
        />
    );
}

function GeoValueEditor({value:valueInit, valueset, onUpdate}) {
    const [value, setValue] = React.useState([]);
    const [geos, setGeos] = React.useState([]);
    React.useEffect(() => {
        setValue([...valueInit]);
    }, [valueInit]);
    React.useEffect(() => {
        const geos = value.map((geo) => {
            return {
                geo_text: geo.geo_text,
                color: "blue",
            }
        });
        setGeos(geos);
    }, [value]);
    const handleClick = (geo) => {
        const idx = lodash.findIndex(value, geo);
        if (idx > -1 ) {
            value.splice(idx, 1);
        } else {
            value.push(geo);
        }
        setValue([...value]);
    }
    const handleSave = () => {
        onUpdate(AssignValueType.VALUE, value);
    };
    return (
        <Box>
            <StaticPlaceGeoMap
                geos={geos}
                height={500}
                width={500}
            />
            <Paper>
                <Box p={1}>
                    Select the polygons to be included in the value.
                    {
                        valueset.map((geo, idx) =>
                            <Button
                                variant="contained"
                                color={lodash.findIndex(value, geo)>-1?"primary":undefined}
                                onClick={()=>handleClick(geo)}
                            >
                                {idx+1} - {geo.geo_type}
                            </Button>
                        )
                    }
                    <Button onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Paper>
        </Box>
    );

}

/** Get all possible values for geo field */
function getGeoValueset(row) {
    const all_value = [
        ...(row.us_to_value||[]),
        ...(row.ds_value||[]),
    ];
    const result = lodash.uniqBy(all_value, v=>JSON.stringify(v));
    return result;
}

export function MergeTaskValueEditor({info, mergeTasks, handleAssignValue}) {
    const classes = useStyles();
    const handleClose = () => {
        handleAssignValue(null, null);
    }
    const handleUpdate = (valueType, value) => {
        handleAssignValue(info.rowIndex, valueType, value);
    }
    const rowData = info && mergeTasks[info.rowIndex];
    return (<Modal
        open={!!info}
        onClose={handleClose}
        autoFocus={false}
    >
        <Box className={classes.modelPaper}>
            { info?.colIndex === MERGE_GRID_COLUMN.action.id ? (
                <ActionEditor value={rowData.action} onUpdate={handleUpdate} />
            ) : info?.colIndex === MERGE_GRID_COLUMN.value.id ? (
                rowData.field === "geo" ? (
                    <GeoValueEditor value={rowData.value} valueset={getGeoValueset(rowData)} onUpdate={handleUpdate}/>
                ) : (
                    <GenericValueEditor value={rowData.value} onUpdate={handleUpdate}/>
                )
            ) : ""}
        </Box>
    </Modal>);
}
