import React, {useState} from 'react';
import CarApi from "../api/CarApi";

const RELOAD_TIME_SUCCESS_SEC = 600;
const RELOAD_TIME_FAILURE_SEC = 30;

const { createContext, useContext } = React;

const UserinfoContext = createContext(null);

export const UserinfoProvider = (props) => {
  const value = {
    _queryUserinfo: props.queryUserinfo || (async () => {
      const data = await CarApi.getUserDetails();
      return data;
    }),
    _data: {
        ready: false,
    },
    _listeners: new Set(),
  };
  value.updateUserinfo = updateUserinfo.bind(value);
  setTimeout(value.updateUserinfo, 0);
  return (
    <UserinfoContext.Provider value={value}>
      {props.children}
    </UserinfoContext.Provider>
  );
};

/**
 * Userinfo accessor component
 *
 * NOTE: If you are using Class components, you cannot use the useUserinfo
 * hook. Instead create a Userinfo component, and add a function inside. This
 * function will receive the same response as parameter what useUserinfo
 * returns. So if you want to display the user's email, you can do it with:
 *     <Userinfo>
 *         {(userinfo) => (
 *             <b>The email of the user is: {userinfo.email}</b>
 *         )}
 *     </Userinfo>
 */
export const Userinfo = ({children}) => {
    const userinfo = useUserinfo();
    return children(userinfo);
}

export const useUserinfo = () => {
  /**
   * Get data about the user
   * The return value auto-updates in the background
   * The fields in the result:
   *   - ready {boolean} If data is available
   *   - username {string} Name of the user
   *   - email {string} Email address of the user
   *   - roles {Array} A list of {string} role names
   *   - redirect_url {string} Signs that the user is not logged in. Provides the url to display
   * @returns {Object} The userinfo object
   */
  const context = useContext(UserinfoContext);
  const [userinfo, setUserinfo] = useState(context._data);
  React.useEffect(() => {
    context._listeners.add(setUserinfo);
    return () => {
      context._listeners.delete(setUserinfo);
    }
  }, [context._listeners]);
  return userinfo;
};

async function updateUserinfo() {
    let success = false;
    try {
        const resp = await this._queryUserinfo();
        success = true;
        this._data = {
            ready: true,
            redirect_url: resp.data.redirect_url,
            username: resp.data.username,
            email: resp.data.email,
            roles: resp.data.roles,
            apps: resp.data.apps || [],
        };
        for (let setUserinfoListener of this._listeners) {
            setUserinfoListener(this._data);
        }
    } catch (err) {
        // nop
    }
    setTimeout(this.updateUserinfo.bind(this), (success?RELOAD_TIME_SUCCESS_SEC:RELOAD_TIME_FAILURE_SEC) * 1000);
}
