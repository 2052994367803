import React from "react";
import {Avatar, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: theme.spacing(2) + "px !important",
  },
}));

export function ModeSelectorButton({mode, id, label, onModeSelect}) {
  const classes = useStyles();
  const url = mode.path_template.replace(":id", id);
  // NOTE: We both have a link AND eventHandler. Bit dirty, but does the job
  const handleOpen = (mode, id) => {
    onModeSelect({[mode.selector]:id});
  };
  return (
      <Link to={url}>
        <Button
            size="small"
            variant='contained'
            color='primary'
            startIcon={
              <Avatar className={classes.small}>
                {mode.avatar_letter}
              </Avatar>
            }
            onClick={()=>handleOpen(mode, id)}
        >
          {label}
        </Button>
      </Link>
  );
}
