import React, { Component } from 'react';
import CenteredGrid from './CenteredGrid';
import Help from './Help';
import CarAjax from '../api/CarAjax';
import CarApi from '../api/CarApi';
//import { set } from 'gl-matrix/src/gl-matrix/mat4';
import lodash from 'lodash';
import {GlobalHotKeys} from "react-hotkeys";
import {POPUP, POPUP_TIMING, POPUP_TYPES, showNotification} from "../notifications";
import {GpsSource, SearchStatus, qs_decode, qs_encode} from './utils';
import {getDistance, getLatitude, getLongitude} from 'geolib';
import { Beforeunload } from 'react-beforeunload';
import DeleteConfirmation from './DeleteConfirmation';
import * as DrawConst from "@mapbox/mapbox-gl-draw/src/constants";
import {withUserPreferences} from "../services/userpreferences-service";
import {withRouter} from "react-router-dom";
import {
    assignValidator,
    buildApiQueryFromString,
    getQueryValidationMessages,
    normalizeApiQuery,
} from "../utils/filterFormUtils";
import {
    getAssignTargetSelectorFromUrlForm,
    getAssignTargetUrlFormFromSelector,
    getSelectionModeInfo,
    SelectionMode,
    SELECTOR_PARAMS
} from "../utils/modeSelectorUtils";
import {PlacesetCreateDialog} from "./Settings/PlacesetCreateDialog";
import {AssignTargetDialog} from "./AssignTargetDialog";
import {ModeSelectorTooltipInfo} from "./ModeSelectorTooltipInfo";
import {MDAppBar} from "./MDAppBar";
import {Box} from "@material-ui/core";
import {FeatureCollectionItem, geocodePoiAddress} from "../utils/mapBoxUtils";
import {ImportPolygonDialog} from "./ImportPolygonDialog";
import {LongOperationModal} from "./LongOperationModal";
import {
    createFeatureFromPlaceGeo,
    getCircleBoundingBox,
    getPlaceBoundingBox, getPlaceGeoCentroid,
    getPlaceGeoRadiusEstimateMeters,
    getPointsBoundingBox,
    getGeoJsonBoundingBox,
    scaleBoundingBox,
    getGeoJsonFromViewport,
} from "../utils/geoUtils";
import {createRandomUID} from "../utils/utils";
import {EditorValuesetLoader} from "./EditorValuesetLoader";
import {setPlaceField} from "../utils/fieldDefUtils";


const wkt = require('terraformer-wkt-parser');

const INITIAL_COORDINATE = [-98.5795, 39.8283];

const emptyPlace ={
    place_geos: [],
    lat: null,
    lon: null,
    country_code: 'US',
    access_level: CarApi.const.AccessLevel.READONLY,
};

const globalKeyMap = {
    SHOW_DIALOG: {name: 'Display keyboard shortcuts', sequence: 'shift+?', action: 'keyup'},
    SHOW_FILTER_INFO: {name: 'Display filter info', sequence: 'ctrl+i', action: 'keyup'},
    POI_SAVE: {name: 'Save current item', sequence: 's', action: 'keyup'},
    POI_RESET: {name: 'Reset current item', sequence: 'r', action: 'keyup'},
    POI_NEW: {name: 'Create new item', sequence: 'n', action: 'keyup'},
    WORK_ITEM_DONE: {name: 'Close the current workitem', sequence: 'd', action: 'keyup'},
    WORK_ITEM_MARK: {name: 'Mark/Unmark the current workitem. When marking, you can provide context. Hit ESC to skip context, or Enter to store it.', sequence: 'm', action: 'keyup'},
    FOCUS_FILTER: {name: 'Jump to filter input', sequence: 'f', action: 'keyup'},
    TOGGLE_DRAWER: {name: 'Toggle POI drawer', sequence: 'p', action: 'keyup'},
};

const showPlaceDebounceTime = 200;

class Editor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasNextPage: true,
            isOverlapMode: false,  // "has_overlap:1" used in the filter, nearby should return only overlaps with selected place
            searchStatus: SearchStatus.NONE,
            searchStat: {},  // Holds statistics about the search resultset
            searchStatIsOpen: false,  // Holds openness state of search info box
            filteredPlaceData: [],
            filteredPlaceTotalCount: 0,
            workItemWorkTypes: [],
            polygonsMapLayerData: null,
            iso:null,
            isoParams:{
                isoPolyVisible: false,
                travelMode: 'walking',
                travelTime: '10',
                isoPolyGeo: null,
                lat: null,
                lon:null
            },
            viewportStatus: {
                center: INITIAL_COORDINATE,
            },  // Read-only: Info about viewport of map component. See Map.js for fields
            viewportArea: getCircleBoundingBox(INITIAL_COORDINATE, 2700000),  // Write-only: What to show on the map
            geogpsdataholder: [
                // list with {source_name, data:{type, features}}
            ],
            boundedPoly: false,
            mode: 'direct_select',
            token: 'pk.eyJ1IjoiY2Fyc29uZ3JlZ29yeSIsImEiOiJjamM1cG0yODkwb2JwMnp0NnMwMHFrajJnIn0.nP_3l_b3yAmMDANoyRugrA',
            mapStyle: 'mapbox://styles/mapbox/satellite-streets-v10',
            modeInfo: {
                selection_mode: SelectionMode.ALL,
                selector: {},  // stores the selector value. One of SelectionMode.X.selector
            },  // stores mode selection info
            searchBarInputValue: "",
            listSelection: false,  // Id of the selected POI
            leftDrawerOpen: true,
            helpIsOpen: false,
            deleteClicked: false,
            selectedPOI: null,  // Inited below, the poi object
            placesets: [],  // Hierarchical placeset information ({clients:[projects,placesets]})
            flatPlacesets: [], // This is truly just placesets by id
            displayModeSelectorList: false,  // Displat status of the mode selector panel
            createPlacesetIsOpen: false,
            assignTargetInfo: undefined,
            assignTargetDialogOpen: false,
            importPolygonDialogOpen: false,
            copyPlacesModalOpen: false,
        };
        Object.assign(this.state, this.getStateUpdateFromPOI(emptyPlace));
        this.toggleHelp = this.toggleHelp.bind(this);
        // NOTE: url processing is done in updateFromUrl called from componentDidMount
    }

    // Editor Refs
    poiFormRef = React.createRef();
    filteredSearchListRef = React.createRef();
    filterInputRef = React.createRef();
    mapRef = React.createRef();

    //Utility Functions
    toggleHelp() {
        this.setState(state => ({
            helpIsOpen: !state.helpIsOpen
        }));
    }

    setSearchStatIsOpen = (state) => {
      this.setState({
          searchStatIsOpen: state,
      })
    };

    //Map Functions

    setViewportStatus = viewportStatus => {
        this.setState({
            viewportStatus: viewportStatus,
        });
    };

    basemapChange = event => {
        this.setState({mapStyle:'mapbox://styles/mapbox/'+event.target.value});
    };
    changeMode = newMode => {
        this.setState({ mode: newMode});
    };

    hideIso = () => {
        this.setState({ iso: null });
    };

    setIsoParams = (isoParams) => {
        //set param and call getiso
        this.setState({ isoParams: isoParams });
        if (this.state.isoParams.isoPolyVisible){
            this.getIso(this.state.isoParams.lat, this.state.isoParams.lon);
        }
    };

    getIso = async (lat, lon) => {
        let isoFeatures=[];
        let isoFeaturesRes= await CarApi.getIso(lat, lon, this.state.isoParams.travelMode, this.state.isoParams.travelTime);
        for (let f of isoFeaturesRes.data.features) {
            isoFeatures.push(f);
        }
        this.setState({
            iso: {
                type: 'FeatureCollection',
                features: isoFeatures
            }
        });
    };

    openGoogleMapView = (viewportStatus) => {
        let url = `https://www.google.com/maps/@?api=1&map_action=map&center=${getLatitude(viewportStatus.center)},${getLongitude(viewportStatus.center)}&zoom=${Math.ceil(viewportStatus.zoom)}&basemap=satellite`;
        window.open(url, '_blank');
    };

    loadPlacesets = async () => {
      const resp = await CarApi.getPlacesets();
      const placesetLut = lodash.keyBy(resp.data.map(client => client.placesets).flat(), "id");
      this.setState({
        placesets: resp.data,
        flatPlacesets: placesetLut,
      });
    };

    handleCategoryValueset = async (valueset) => {
        assignValidator("category", valueset.map((x) => x["label"]));
    };

    handleOpenModeSelector = () => {
      this.setState({
        displayModeSelectorList: !this.state.displayModeSelectorList,
      });
    };

    /**
     * Activate mode denoted by selector
     * @param selector is of format {entity_id: id}
     * @returns {Promise<void>}
     */
    handleModeSelect = async (selector, withOverlapFilter, keepFilter) => {
        let modeInfo = await getSelectionModeInfo(selector, this.handleModeSelect);
        this.setState(state =>({
            filteredPlaceData: [],
            filteredPlaceTotalCount: 0,
            searchBarInputValue: keepFilter ? state.searchBarInputValue : (withOverlapFilter ? "has_overlap:1" : ""),
            hasNextPage: true,
            displayModeSelectorList: false,
            modeInfo: modeInfo,
        }), () => {
            this.updateUrl();
            this.createPlace();
            // HACK: createPlace assigns location, but we only really want to init access_level here
            this.setState(state => {
                state.selectedPOI.lat = null;
                state.selectedPOI.lon = null;
                state.originalPOI.selectedPOI.lat = null;
                state.originalPOI.selectedPOI.lon = null;
                return state;
            });
            this.loadNextChunk();
        });
    };

    handleModeAction = () => {
      if (this.state.modeInfo.selection_mode === SelectionMode.PROJECT) {
        this.props.history.push(`/projects/${this.state.modeInfo.selector.project_id}/settings`);
      } else if (this.state.modeInfo.selection_mode === SelectionMode.PLACESET) {
        this.props.history.push(`/placesets/${this.state.modeInfo.selector.placeset_id}/settings`);
      } else {
        this.setState({
            createPlacesetIsOpen: true,
        });
      }
    };

    handleCreatePlaceset = async (data) => {
        this.setState({
            createPlacesetIsOpen: false,
        });
        if (!data) {
            return;
        }
        await CarApi.createPlaceset({
            client_id: data.client_id,
            name: data.name,
        });
        showNotification({message: "Placeset created"});
        await this.loadPlacesets();
        // TODO: If needed, set assignment target to the placeset
    }

    handleAssignTarget = async (selector) => {
        if (selector === null) {
            this.setState({
                assignTargetInfo: undefined,
                assignTargetDialogOpen: false,
            }, () => {
                this.updateUrl();
            });
            return
        }
        this.setState({
            assignTargetDialogOpen: !selector && !this.state.assignTargetDialogOpen,
        });
        if (!selector) {
            return;
        }
        const info = await getSelectionModeInfo(selector, this.handleModeSelect);
        this.setState({
            assignTargetInfo: info
        }, () => {
            this.updateUrl();
        });
    }

    handleAssignPlace = async () => {
        // FIXME: This should call handleAssignFilter
        const selector = this.state.assignTargetInfo?.selector;
        const place_id = this.state.selectedPOI?.place_id;
        if (!selector || !place_id) {
            showNotification({message: "No target or no place selected.", type:POPUP_TYPES.ERROR})
            return;
        }
        try {
            await CarApi.assignPlaceToPlaceset(selector, place_id);
            showNotification({message: `Copied to ${this.state.assignTargetInfo.title} ${this.state.assignTargetInfo.selection_mode.urlform}`});
        } catch (err) {
            const message = `Unknown error with status code ${err.response.status} during operation`
            showNotification({
                message: message,
                type: POPUP_TYPES.ERROR,
                timing: POPUP_TIMING.LONG,
            });
        }
    }

    handleAssignFilter = async () => {
        const selector = this.state.assignTargetInfo?.selector;
        if (!selector) {
            showNotification({message: "No target selected.", type:POPUP_TYPES.ERROR})
            return;
        }
        if (![SelectionMode.PLACESET, SelectionMode.PROJECT].includes(this.state.modeInfo.selection_mode)) {
            showNotification({message: "Copy all only supported from a single project/placeset.", type:POPUP_TYPES.ERROR})
            return;
        }
        if (this.state.modeInfo.related_placeset_id === this.state.assignTargetInfo.related_placeset_id) {
            showNotification({
                message: "You are trying to copy places into their own placeset. This would create duplicates. Operation not permitted.",
                type: POPUP_TYPES.ERROR,
                timing: POPUP_TIMING.LONG,
            })
            return;
        }
        const filter = this.getSearchExpr();
        if (filter === null) {
            showNotification({message: "Filter has errors.", type:POPUP_TYPES.ERROR});
            return;
        }
        try {
            this.setState({
                copyPlacesModalOpen: true,
            });
            await CarApi.assignFilterToPlaceset(selector, filter);
            showNotification({message: `Copied to ${this.state.assignTargetInfo.title} ${this.state.assignTargetInfo.selection_mode.urlform}`});
        } catch (err) {
            const MESSAGES = {
                413: `Error. Can only copy at most ${CarApi.const.ASSIGN_PLACE_LIMIT} places at once. Contact your admin about your usecase.`,
                503: `Error. The operation timed out. You might want to retry 1 or 2 times. If still no luck, contact your admin about your usecase.`,
                default: `Unknown error with status code ${err.response.status} during operation`,
            }
            const message = MESSAGES[err.response.status] || MESSAGES.default;
            showNotification({
                message: message,
                type: POPUP_TYPES.ERROR,
                timing: POPUP_TIMING.LONG,
            });
        } finally {
            this.setState({
                copyPlacesModalOpen: false,
            });
        }
    }

    loadWorkItems = async () => {
        let workRes = await CarApi.getWorkItems();
        workRes = lodash.filter(workRes.data, wItem => wItem.item_type === "Place")
        let workTypes = lodash.uniq(lodash.map(workRes, wItem => wItem.work_type));
        this.setState({
            workItemWorkTypes: workTypes,
        });
    }

    /**
     * Compile search tree expression for API call
     * @return null if query is invalid
     * @return {*}
     */
    getSearchExpr = () => {
        let search_string = this.state.searchBarInputValue;
        if (getQueryValidationMessages(search_string).length > 0) {
            return null;
        }
        let expr = normalizeApiQuery(buildApiQueryFromString(search_string));
        return Object.assign(expr, this.state.modeInfo.selector);
    };

    /**
     * Load next chunk of data of places list
     *
     * The operation is called by react-window-infinite-loader so the arguments must conform to `loadMoreItems`:
     * @see: https://github.com/bvaughn/react-window-infinite-loader/blob/master/README.md#documentation
     * @param startIndex First record to load
     * @param stopIndex Last record to load
     * @returns {Promise<void>}
     */
    loadNextChunk = async (startIndex, stopIndex) => {
        if (startIndex === 0 && stopIndex === 0) {
            return;
        }
        startIndex = startIndex || 0;
        const searchExpr = this.getSearchExpr();
        const isOverlapMode = String(searchExpr?.has_overlap) === "1";
        this.setState({
            searchStatus: SearchStatus.LOADING,
        });
        try {
            const response = await CarApi.getPlacesOffset(searchExpr, startIndex);
            const meta = response.data.meta;
            const resultset = response.data.data;

            function updateTotalCount(state, resultset, startIndex, total) {
                const totalCount = startIndex === 0 ? meta.total : state.filteredPlaceTotalCount;
                if (resultset.length === 0 && totalCount === -1) {
                    return state.filteredPlaceData.length;
                } else {
                    return totalCount;
                }
            }
            this.setState(state => ({
                hasNextPage: resultset.length > 0,
                isOverlapMode: isOverlapMode,
                searchStatus: SearchStatus.NONE,
                filteredPlaceTotalCount: updateTotalCount(state, resultset, startIndex, meta.total),
                filteredPlaceData: startIndex > 0 ? [...state.filteredPlaceData].concat(resultset) : resultset,
            }));
        } catch (err) {
            if (CarAjax.isCancel(err)) {
                // The request got canceled, we should not do anything
                return;
            }
            this.setState({
                hasNextPage: false,
                isOverlapMode: false,
            });
            if (err.response.status === 503) {
                this.setState({
                    searchStatus: SearchStatus.TIMEOUT,
                });
                showNotification(POPUP.SEARCH_TIMEOUT_ERROR);
            } else {
                this.setState({
                    searchStatus: SearchStatus.FAILED,
                });
                throw err;
            }
        }
        if (this.onload_show_place_id) {
            await this.showPlace(this.onload_show_place_id);
            this.onload_show_place_id = undefined;
        }
    };

    getStateUpdateFromPOI = (poiData) => {
        // Data about the edited POI is stored in 3 place:
        //   selectedPOI stores the form fields and workitem related data
        //   geojson stores the data about polygons
        // we store a clean copy of these in originalPOI to detect if data changed
        poiData = lodash.cloneDeep(poiData);
        const poiFeatures = poiData.place_geos.map(feature => this.createFeature(feature));
        delete poiData.place_geos;
        const originalPOI = {
            selectedPOI: poiData,
            geojson: {
                type: 'FeatureCollection',
                features: poiFeatures
            },
        };
        return {
            originalPOI: originalPOI,
            selectedPOI: lodash.cloneDeep(originalPOI.selectedPOI),
            geojson: lodash.cloneDeep(originalPOI.geojson),
        };
    };

    isPOIDirty = () => {
        const selectedPOIEq = lodash.isEqual(this.state.originalPOI.selectedPOI, this.state.selectedPOI);
        const geojsonEq = lodash.isEqualWith(this.state.originalPOI.geojson, this.state.geojson, (othValue, objValue, key, other, object, stack) => {
            if (key === "show") {  // skip show field with match, unconditionally
                return true;
            }
            return undefined;
        });
        return !selectedPOIEq || !geojsonEq;
    };

    isDrawingActive = () => {
        return this.mapRef.current && this.mapRef.current.drawControl && this.mapRef.current.drawControl.getMode() === DrawConst.modes.DRAW_POLYGON;
    }

    setStatePOI = (poiData) => {
        this.setState(this.getStateUpdateFromPOI(poiData));
    };

    openImportPolygonDialog = () => {
        if (!this.state.selectedPOI?.place_id) {
            showNotification({
                message: "Import requires a place to be open",
                type: POPUP_TYPES.WARNING,
            });
            return;
        }
        this.setState({
            importPolygonDialogOpen: true,
        });
    }

    handleImportPolygon = (polygonData) => {
        this.setState({
            importPolygonDialogOpen: false,
        });
        if (this.state.selectedPOI?.place_id && polygonData) {
            this.state.geojson.features.push(this.createFeatureFromRawGeoJSON(polygonData));
        }
    }

    getPolygonGeoJsonByMapGeoId = (map_geo_id) => {
        for (let feature of this.state.geojson.features) {
            if (feature.properties.map_geo_id === map_geo_id) {
                return feature;
            }
        }
    }

    zoomToPolygon = async (map_geo_id) => {
        const feature = this.getPolygonGeoJsonByMapGeoId(map_geo_id);
        if (feature) {
            const scale = 100/80;
            this.setState({
                viewportArea: scaleBoundingBox(getGeoJsonBoundingBox(feature), scale),
            });
        }
    };

    exportPolygon = async (map_geo_id) => {
        const feature = this.getPolygonGeoJsonByMapGeoId(map_geo_id);
        if (feature) {
            const wktData = wkt.convert(feature.geometry);
            try {
                // NOTE: Not supported in IE, well, sad story
                // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
                await navigator.clipboard.writeText(wktData);
                showNotification({
                    message: "Copied to the clipboard",
                    type: POPUP_TYPES.INFO,
                });
            } catch (e) {
                showNotification({
                    message: "Error! Cannot copy the polygon",
                    type: POPUP_TYPES.ERROR,
                });
            }
        } else {
            showNotification({
                message: "Error! Cannot find the polygon",
                type: POPUP_TYPES.ERROR,
            });
        }
    };

    /** Check if collision with  */
    checkAndShowGeoCollision = async (updateParams) => {
        const placeset_id = this.state.modeInfo.related_placeset_id;
        if (!placeset_id) {
            // No place in the placeset yet, so no collision
            return false;
        }
        const polygons = updateParams.place_geos.map((geo) => geo.geo_text);
        const nearbyPolygonsResponse = await CarApi.getOverlappingGeos(
            polygons,
            updateParams.place_id,
            placeset_id,
            true,
        );
        const collidingPolygons = nearbyPolygonsResponse.data?.data || [];
        if (!collidingPolygons.length) {
            return false;
        }
        this.setNearbyPolygons(null);
        showNotification({
            message: `Detected ${collidingPolygons.length} colliding geos, displaying them on the map. Use Shift+click to force save.`,
            timing: POPUP_TIMING.LONG,
        });
        // TODO: Blinking overlaps or somehow increasing where they are would help AMs/Adam visually
        this.setNearbyPolygons(collidingPolygons);
        return true;
    };

    displayNearbyPolygons = async (viewportStatus, excludePlaceId, distanceMeters) => {
        console.log(["nearbyPolygons:call", viewportStatus, excludePlaceId, distanceMeters]);
        if (!viewportStatus.center || this.state.polygonsMapLayerData !== null) {
            showNotification({
                message: "Hiding nearby POIs",
                type: POPUP_TYPES.INFO,
            });
            this.setNearbyPolygons(null);
            return;
        }
        if (this.state.isOverlapMode && excludePlaceId) {
            // if has_overlap filter is on, and has excludePlaceId => exclude_maz, use polygon not bbox
            const updateParams = this.preparePlaceUpdateObjectFromState();
            const hasCollisions = await this.checkAndShowGeoCollision(updateParams);
            if (!hasCollisions) {
                showNotification({
                    message: "No colliding geos to display",
                });
            }
            return;
        }
        const nearbyPolygonsResponse = await CarApi.getOverlappingGeos(
            [JSON.stringify(getGeoJsonFromViewport(viewportStatus))],
            excludePlaceId,
            this.state.modeInfo.related_placeset_id,
            false,
            viewportStatus.densityMPP,
        );
        const nearbyPolygons = nearbyPolygonsResponse.data?.data || [];
        console.log(["nearbyPolygons:showLayer", excludePlaceId, this.state.filteredPlaceData.length, nearbyPolygons.length]);
        if (!nearbyPolygons.length) {
            showNotification({
                message: "No nearby POIs to display",
                type: POPUP_TYPES.WARNING,
            });
            return;
        }
        const trimWarning = (nearbyPolygons.length == CarApi.const.NEARBY_GEO_LIMIT) && " WARNING: Results are trimmed." || "";
        showNotification({
            message: `Showing ${nearbyPolygons.length} nearby POI(s).${trimWarning}`,
        });
        this.setNearbyPolygons(nearbyPolygons);
    };

    setNearbyPolygons = (geoObjects) => {
        const polygonsMapLayerData = geoObjects === null ? null : {
            type: 'FeatureCollection',
            features: geoObjects.map(feature => ({
                type: "Feature",
                geometry: JSON.parse(feature.geo_text),
            })),
        };
        this.setState({
            polygonsMapLayerData: polygonsMapLayerData,
        });
    };

    displayAllFilteredPOIs = async () => {
        const points = this.state.filteredPlaceData.filter(item => [item.lon, item.lat]);
        this.setState({
            viewportArea: scaleBoundingBox(getPointsBoundingBox(points), 1.1),
        });
    };

    resetGpsData = () => {
        this.setState({
            geogpsdataholder: [],
        });
    };

    showGpsDataI = 0;
    showGpsData = async (gpsSource, viewportStatus) => {
        // viewBounds: https://github.com/mapbox/mapbox-gl-js/blob/master/src/geo/lng_lat_bounds.js
        let center = viewportStatus.center;
        let width = Math.abs(viewportStatus.bounds[0][0] - viewportStatus.bounds[1][0]);
        let height = Math.abs(viewportStatus.bounds[0][1] - viewportStatus.bounds[1][1]);
        let apiQuery = gpsSource === GpsSource.CAR?CarApi.getCarData:CarApi.getPhoneData;
        const res = await apiQuery(center.lat, center.lng, width * 1.1, height * 1.1);
        let features = res.data.results.map((item) => FeatureCollectionItem(item.lat, item.lon));
        this.showGpsDataI += 1;
        this.state.geogpsdataholder.push({
            source_name: 'car_data_' + this.showGpsDataI,
            data: {
                type: 'FeatureCollection',
                features: features,
            },
            source_type: gpsSource,
        });
        this.setState({
            geogpsdataholder: this.state.geogpsdataholder,
        });
    };

    resetPlace = async () => {
        this.showPlaceInternal(this.state.selectedPOI.place_id, true);
    }

    showPlace = async (id, noViewportUpdate, forceInternalUpdate) => {
        const useEmpty = id===null || id===false || id===undefined || id==="";
        if (!useEmpty && id === this.state.selectedPOI.place_id && !forceInternalUpdate) {
            return;
        }
        if (this.isPOIDirty() || this.isDrawingActive()) {
            const hasNoLocation = (!this.state.selectedPOI.lat || !this.state.selectedPOI.lon);
            const isNewPlace = !this.state.selectedPOI.place_id;
            if (isNewPlace && hasNoLocation && useEmpty) {
                // Workaround: Handled the case when the editor is freshly open, and the initial new place
                //   does not have a lat/lon assigned.
                this.state.selectedPOI.lat = getLatitude(this.state.viewportStatus.center);
                this.state.selectedPOI.lon = getLongitude(this.state.viewportStatus.center);
                showNotification({message: "Lat/lon assigned to place."});
                return;
            }
            if (!window.confirm("If you proceed, you will lose unsaved changes. Continue?")) {
                // HACK: We update the selected item in advance inside FilteredSearchList.
                // As we figured out we wont proceed, we have to rollback this change.
                if (this.state.listSelection !== id) {
                    this.setState({
                        listSelection: id,
                    })
                }
                return;
            } else {
                if (this.isDrawingActive()) {
                    this.mapRef.current.drawControl.trash();
                }
            }
        }
        this.showPlaceInternal(id, noViewportUpdate);
    };

    // Providing null/false/undefined as the id will reset to empty.
    showPlaceInternal =  async (id, noViewportUpdate)  => {
        const useEmpty = id===null || id===false || id===undefined || id==="";
        let res = null;
        if (useEmpty) {
            id = false;
            noViewportUpdate = true;
            res = {data: lodash.cloneDeep(emptyPlace)};
            res.data.lat = getLatitude(this.state.viewportStatus.center);
            res.data.lon = getLongitude(this.state.viewportStatus.center);
            const isPlacesetMode = [SelectionMode.PLACESET, SelectionMode.PROJECT].includes(this.state.modeInfo.selection_mode);
            res.data.access_level = isPlacesetMode ? this.state.modeInfo.access_level : CarApi.const.AccessLevel.READONLY;
        } else {
            try {
                res = await CarApi.getPlace(id);
            } catch (err) {
                const MESSAGES = {
                    410: `The requested place_id (${id}) has been deleted`,
                    404: `The requested place_id (${id}) is invalid, cannot be found`,
                    default: `Unknown error with status code ${err.response.status} during requesting place_id ${id}`,
                }
                const message = MESSAGES[err.response.status] || MESSAGES.default;
                showNotification({
                    message: message,
                    type: POPUP_TYPES.ERROR,
                    timing: POPUP_TIMING.LONG,
                });
                return;
            }
            const isPlaceInList = this.state.filteredPlaceData.findIndex(item => item.place_id === res.data.place_id) > -1;
            if (!isPlaceInList && !this.state.isOverlapMode) {
                this.setState(state => ({
                    // Note: Adding to the front so we wont trigger loading the next chunk
                    filteredPlaceData: [res.data].concat([...state.filteredPlaceData]),
                }));
            }
        }

        if (!noViewportUpdate) {
            const scale = 100/Math.min(200, Math.max(5, this.props.userPreferences.place_initial_zoom));
            this.setState({
                viewportArea: scaleBoundingBox(getPlaceBoundingBox(res.data), scale),
            });
        }
        this.setStatePOI(res.data);
        this.setState({
            listSelection: id,
            iso: null,
        }, () => {
            this.updateUrl();
        });
        this.focusPoiSelectionList();
    };

    exportGeojson = (geojsonExport) => {
        if (geojsonExport) {
            const blob = new Blob([wkt.convert(geojsonExport.features[0].geometry)], { type: 'octet/stream' });
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'polygon.wkt';
            a.click();
        }
    };

    // Feature CRUD

    createFeatureFromRawGeoJSON = geoJson => {
        return this.createFeature({
            geo_id: null,
            place_id: this.state.selectedPOI?.place_id,
            geo_type_id: CarApi.const.GeoType.UNSPECIFIED,
            geo_text: geoJson,
        });
    }

    createFeature = featureData => {
        const result = createFeatureFromPlaceGeo(featureData);
        /**
         * HACK: When creating a new polygon and saving, we reload the
         * data from the server, but the polygons diplayed on the Map
         * are not updated. Newly created polygons (on the Map) have a
         * feature.id field to be identified. We have to patch this
         * back into the data, otherwise deletion won't work.
         * See: isFeatureEqual (used in deletePOIGeo)
         * (NOTE: We only update the Map representation of polygons if
         * the geometries do not match up, so there is no flicker, or
         * reset in drawing state.)
         */
        if (this.state.geojson) {
            this.state.geojson.features.forEach(feature => {
                if (feature.id && lodash.isEqual(feature.geometry, result.geometry)) {
                    console.log("PATCHED");
                    result.id = feature.id;
                }
            });
        }
        return result;
    };

    createPlace = () => {
        this.showPlace(null);
    };

    /**
     * Returns the payload to be sent to Place API to create/update
     * If state is invalid, displayed validation error, and returns undefined.
     * @return {*}
     */
    preparePlaceUpdateObjectFromState = () => {
        const updateParams = lodash.cloneDeep(this.state.selectedPOI);
        updateParams.place_geos = [];

        this.state.geojson.features.forEach( geo => {
            let newGeo = {};
            if (geo.properties.delete) {
                return;
            }
            if (geo.properties.geo_id){
                newGeo.geo_id = geo.properties.geo_id;
            }
            if (geo.properties.place_id){
                newGeo.place_id = geo.properties.place_id;
            }
            newGeo.geo_text = JSON.stringify(geo.geometry);
            newGeo.geo_type_id = geo.properties.geo_type_id;
            updateParams.place_geos.push(lodash.cloneDeep(newGeo));
        });

        const isCreate = !updateParams.place_id;
        if (isCreate) {
            updateParams[this.state.modeInfo.selection_mode.selector] = this.state.modeInfo.selection_id;
        }
        return updateParams;
    }

    /**
     * Updates/ensures that place has a centroid set
     * Tries to derive from Polygons of the place.
     * If there are not polygons, falls back to geocoding.
     * If all fails, prompts the user, to move the map to the center and press +
     * NOTE: Updates centroid based on polygons on subsequent saves.
     * @param updateParams
     * @return {Promise<boolean>} Success. Signals if the save operation can be continued.
     */
    updatePlaceCentroid = async (updateParams) => {
        const isCreate = !updateParams.place_id;
        let centroid = getPlaceGeoCentroid(updateParams);
        console.log(["Poly", centroid]);
        if (centroid === undefined && isCreate) {
            centroid = await geocodePoiAddress(updateParams);
            console.log(["Geo", centroid]);
        }
        if (centroid !== undefined) {
            updateParams.lat = getLatitude(centroid);
            updateParams.lon = getLongitude(centroid);
        }
        if (updateParams.lat === null || updateParams.lon === null) {
            showNotification({
                title: "Missing lat/lon",
                message: "The place does not have a lat/lon and we cannot autodetect it. Position the center of the map to the center of the place. Press the + button. You will be able to save the POI now.",
                type: POPUP_TYPES.ERROR,
                timing: POPUP_TIMING.LONG,
            });
            return false;
        }
        return true;
    }

    checkPlaceCategoryMissing_sc51710 = (updateParams) => {
        if (updateParams?.custom_attributes?.hyperlocal_destination_category == null) {
            showNotification({
                message: `Category is required. Use Shift+click to force save as empty.`,
                timing: POPUP_TIMING.LONG,
            });
            return true;
        }
        return false;
    }

    savePlace = async (force) => {
        const updateParams = this.preparePlaceUpdateObjectFromState();
        if (!force && await this.checkAndShowGeoCollision(updateParams)) {
            return;
        }
        if (!force && this.checkPlaceCategoryMissing_sc51710(updateParams)) {
            return;
        }
        if (updateParams === undefined) {
            return;
        }
        const isCreate = !updateParams.place_id;
        if (!await this.updatePlaceCentroid(updateParams)) {
            return;
        }
        try {
            if ((updateParams.name||"").toLowerCase().includes("main arrival zone")) {
                // TODO(vhermecz): Remove after 2021-dec-01 (CH27027 rollout safeguard)
                showNotification({
                    message: (
                        <React.Fragment>
                            MAZ should be defined on the project settings page, not in the name. See:&nbsp;
                            <b><a target='_blank' href='https://app.gitbook.com/@arrivalist/s/release-notes/maz-config-updates-2021-sept'>
                                the documentation
                            </a></b>
                        </React.Fragment>
                    ),
                    type: POPUP_TYPES.WARNING,
                    timing: POPUP_TIMING.EXTRALONG,
                })
            }
            if (isCreate) {
                const response = await CarApi.createPlace(updateParams);
                updateParams.place_id = response.data.place_id;
            } else {
                await CarApi.updatePlace(updateParams.place_id, updateParams);
            }
        } catch (e) {
            console.log('Error: ', e.message, `place not ${isCreate?'created':'updated'}`);
            showNotification(POPUP.SAVE_ERROR);
            return
        }

        this.setStatePOI(updateParams);
        // clear value of geocoder
        document.getElementById('geocoderCtrl').childNodes[0].getElementsByTagName("INPUT")[0].value = "";
        this.loadWorkItems();
        this.showPlace(updateParams.place_id, true, true);
        showNotification(POPUP.SAVE_SUCCESS);
        if (this.state.isOverlapMode) {
            // Refresh list as some places might got resolved
            this.setState({
                filteredPlaceData: [],
                filteredPlaceTotalCount: 0,
                hasNextPage: true,
            }, () => {
                this.loadNextChunk();
            });
        }
    };

    deletePlace = async () => {
        const placeId = this.state.selectedPOI.place_id;
        await CarApi.deletePlace(placeId)
            .catch(e => {
                console.log('Error: ', e.message, 'place not deleted');
                showNotification(POPUP.UNKNOWN_ERROR);
            });
        this.closeDeletePopup();
        showNotification(POPUP.DELETE_SUCCESS);
        this.getStateUpdateFromPOI(emptyPlace)
        this.setState((state, props) => ({
            filteredPlaceData : state.filteredPlaceData.filter(item => item.place_id !== placeId),
        }));
        this.showPlace(null);
        //this.focusPoiSelectionList();

    }

    doneWorkItem = async () => {
        // Switch between open and closed state. (marked treated as open)
        if (lodash.size(this.state.selectedPOI.work_items) > 0) {
            // TODO: if has multiple, should popup which one
            // TODO: filter could help in determining which one
            let workItem = this.state.selectedPOI.work_items[0];
            workItem.status = (workItem.status === CarApi.const.WorkItem.STATUS_CLOSED) ?
                CarApi.const.WorkItem.STATUS_OPEN :
                CarApi.const.WorkItem.STATUS_CLOSED;
            await CarApi.updateWorkItem(workItem.id, workItem);
            this.setState( (state, props) => ({
                selectedPOI: state.selectedPOI
            }));
        }
        this.focusPoiSelectionList();
    }

    markWorkItem = async (status, message) => {
        if (lodash.size(this.state.selectedPOI.work_items) > 0) {
            let workItem = this.state.selectedPOI.work_items[0];
            workItem.status = status;
            workItem.status_extra = message || null;
            console.log("Sending mark " + status + ", " + message);
            await CarApi.updateWorkItem(workItem.id, workItem);
            this.setState((state, props) => ({
                selectedPOI: state.selectedPOI
            }))
        }
        this.focusPoiSelectionList();
    }

    createPOIGeo = feature => {
        let createGeos = this.state.geojson;
        feature.properties.map_geo_id = createRandomUID();
        feature.properties.show = true;
        feature.properties.geo_type_id = CarApi.const.GeoType.UNSPECIFIED;
        createGeos.features.push(feature);
        this.setState({
            geojson: createGeos,
        });
    };

    // NOTE: mapbox-gl-draw does not allow to change fields on a
    // feature once added. This also means that when createPOIGeo
    // adds the map_geo_id field, that is not visible for gl-draw.
    // As it creates feature.id, we use it here as an alternative
    // of map_geo_id to identify features added by the tool.
    isFeatureEqual = (feature1, feature2) =>
        (feature1.id && feature1.id === feature2.id) ||
        feature1.properties.map_geo_id === feature2.properties.map_geo_id;

    updatePOIGeo = feature => {
        let updatedGeos = this.state.geojson;
        updatedGeos.features.forEach( geo => {
            if (this.isFeatureEqual(geo, feature)) {
                geo.geometry = feature.geometry;
            }
        });
        this.setState({
            geojson:updatedGeos,
        });
    };

    deletePOIGeo = feature => {
        let updatedGeos = this.state.geojson;
        // handle persisted ones
        updatedGeos.features.forEach( geo => {
            if (this.isFeatureEqual(geo, feature) && geo.properties.geo_id) {
                geo.properties.show = false;
                geo.properties.delete = true;
            }
        });
        // handle created ones
        updatedGeos.features = updatedGeos.features.filter(geo => !(this.isFeatureEqual(geo, feature) && !geo.properties.geo_id));

        this.setState({
            geojson: updatedGeos,
        });
    };

    deleteGeos = async (geo_ids) => {
        for (let geo_id of geo_ids) {
            await CarApi.deletePlaceGeo(geo_id)
                .catch(e => {
                    console.log('Error: ', e.message, 'geo not deleted');
                    showNotification(POPUP.UNKNOWN_ERROR);
                });
        }
    };

    //POI Information and Form functions
    // Return false is update failed (user cancelled)
    searchPlaceChange = (option, {action}) => {
        if (action === "input-change") {
            if (this.state.selectedPOI.place_id || this.isPOIDirty() || this.isDrawingActive()) {
                if (this.isPOIDirty() || this.isDrawingActive()) {
                    if (!window.confirm("Proceeding will discard the current place. You will lose unsaved changes. Continue?")) {
                        return false;
                    }
                }
                if (this.isDrawingActive()) {
                    this.mapRef.current.drawControl.trash();
                }
                this.setState(this.getStateUpdateFromPOI(emptyPlace));
                this.setState({
                    listSelection: false,
                });
            }
            this.setState({
                filteredPlaceData: [],
                filteredPlaceTotalCount: 0,
                hasNextPage: true,
                searchBarInputValue: option
            }, () => {
                this.updateUrl();
                this.loadNextChunk();
            });
        }
    };

    reloadSearchPlaceStat = async () => {
        const searchExpr = this.getSearchExpr();
        if (searchExpr === null) {
            showNotification({message: "Filter has errors.", type:POPUP_TYPES.ERROR});
            return;  // filter has errors
        }
        try {
            this.setState({searchStat: {}});
            this.setSearchStatIsOpen(true);
            const response = await CarApi.getPlacesStat(searchExpr);
            this.setState({
                searchStat: response.data,
                filteredPlaceTotalCount: response.data.cnt_total,
            });
        } catch (err) {
            if (CarAjax.isCancel(err)) {
                return;
            }
            showNotification({
                message: "Search stat request failed.",
                type: POPUP_TYPES.ERROR,
            });
            this.setState({
                searchStat: {
                    error: true,
                },
            });
        }
    };

    searchAddWorkTypeFilter = (name) => {
        const newFilterString = " wt:" + name + " ws:open";

        // Only add the filter if not already there
        let newValue;
        if (this.state.searchBarInputValue.includes(newFilterString)) {
            newValue = this.state.searchBarInputValue;
        } else {
            newValue = this.state.searchBarInputValue + newFilterString;
        }

        this.setState( {
            filteredPlaceData: [],
            filteredPlaceTotalCount: 0,
            hasNextPage: true,
            searchBarInputValue: newValue,
        }, () => {
            this.updateUrl();
            this.loadNextChunk();
        });
    };

    updatePOIForm = (name, value) => {
        let newData = this.state.selectedPOI;
        if (value === "" || value === undefined) {
            // Use null instead of empty string
            value = null;
        }
        setPlaceField(newData, name, value);
        this.setState({
            selectedPOI: newData,
        })
    };

    updateGeoJsonFeature = info => event => {
        // info is an Array that consists of a map_geo_id and the feature property to be updated
        let geoJson = this.state.geojson;
        for (let feature of geoJson.features) {
            if (feature.properties.map_geo_id === info[0]){
                if (info[1] === 'show') {
                    feature.properties[info[1]] = event.target.checked;
                } else {
                    feature.properties[info[1]] = event.target.value;
                }
            }
        }

        this.setState({
            geojson: geoJson,
        })
    };

    formatPlaceSummary = () => {
        let formattedStr;
        const poi = this.state.selectedPOI;
        if (poi.name) {
            formattedStr = <React.Fragment>
                {poi.brand_name?poi.brand_name + ", ":""}
                {poi.name + ", "}{(poi.city?poi.city:(<i>(Missing city)</i>))}
                {poi.region_code?", " + poi.region_code:""}
            </React.Fragment>;
            //formattedStr = formattedStr.replace("_", " ");
            //formattedStr = formattedStr.toUpperCase();
        } else {
            formattedStr = <React.Fragment>
                No name
            </React.Fragment>;
        }
        if (!this.state.listSelection) {
            formattedStr = (
                <React.Fragment>
                    <i>New POI: </i>
                    { formattedStr }
                </React.Fragment>
            );
        }
        return formattedStr;
    };

    focusPoiSelectionList = () => {
        this.filteredSearchListRef.current.focus();
    }

    toggleLeftDrawer = () =>  {
       this.setState({leftDrawerOpen: !this.state.leftDrawerOpen});
    };

    updateUrl = () => {
        let qs = {};
        if (this.state.selectedPOI && this.state.selectedPOI.place_id) {
            qs.place_id = this.state.selectedPOI.place_id;
        }
        if (this.state.searchBarInputValue) {
            qs.filter = this.state.searchBarInputValue;
        }
        if (this.state.assignTargetInfo) {
            qs.assignTo = getAssignTargetUrlFormFromSelector(this.state.assignTargetInfo.selector);
        }
        let basepath = '/editor';
        if (this.state.modeInfo.selection_mode !== SelectionMode.ALL) {
            const mode_info = this.state.modeInfo;
            const base_path = mode_info.selection_mode.base_path;
            const id = mode_info.selection_id;
            basepath = `/${base_path}/${id}`;
        }
        this.props.history.replace(basepath + "?" + qs_encode(qs));
    };

    updateFromUrl = (callback = null) => {
        const initParams = (new URL(window.location.href)).searchParams;
        const legacyParams = qs_decode(window.location.hash.substring(1));
        const place = initParams.get('place_id') || legacyParams.place_id;
        if (place) {
            this.onload_show_place_id = place|0;
        }
        const assignToSelector = getAssignTargetSelectorFromUrlForm(initParams.get('assignTo'));
        const filter = initParams.get('filter') || legacyParams.filter || "";
        let modeSelector = {};
        SELECTOR_PARAMS.forEach(param => {
            const value = this.props.match.params[param]|0;
            if (value > 0) {
                modeSelector[param] = value;
            }
        });
        const real_callback = async () => {
            // FIXME: run in parallel
            if (assignToSelector) {
                await this.handleAssignTarget(assignToSelector);
            }
            await this.handleModeSelect(modeSelector, false, true);
            callback();
        }
        if (filter) {
            this.setState({
                searchBarInputValue: filter,
            }, real_callback);
        } else {
            real_callback();
        }
    };

    openDeletePopup = () => {
        this.setState({deleteClicked: true})
    };


    closeDeletePopup = () => {
        this.setState({deleteClicked: false});
    };

    hasRole = (role) => {
        return this.props.userinfo.roles.indexOf(role) > -1;
    };

    //Lifecycle events
    componentDidMount() {
        this.updateFromUrl(() => {
            this.loadWorkItems();
            this.loadPlacesets();
        })
    }

    render() {
        const mapInfo = {
            viewportArea: this.state.viewportArea,
            viewportStatus: this.state.viewportStatus,
            geojson: this.state.geojson,
            geojsonOrig: this.state.originalPOI.geojson,
            geogpsdataholder: this.state.geogpsdataholder,
            selectedFeatureId: this.state.selectedFeatureId,
            selectionTool: this.state.selectionTool,
            token: this.state.token,
            searchResultLayer: this.state.searchResultLayer,
            mapStyle: this.state.mapStyle,
            mode: this.state.mode,
            listSelection: this.state.listSelection,
            iso: this.state.iso,
            polygonsMapLayerData: this.state.polygonsMapLayerData,
            isoParams: this.state.isoParams,
            leftDrawerOpen: this.state.leftDrawerOpen,
            mapActions: [
                {
                    icon: "directions_car",
                    tooltip: "Retrieve car event data for visible area (max 1sqmile)",
                    action: (async () => await this.showGpsData(GpsSource.CAR, this.state.viewportStatus))
                },
                {
                    icon: "phone_android",
                    tooltip: "Retrieve phone location data for visible area (max 1sqmile)",
                    action: (async () => await this.showGpsData(GpsSource.PHONE, this.state.viewportStatus))
                },
                {
                    icon: "not_interested",
                    tooltip: "Reset car and phone location data",
                    action: (async () => await this.resetGpsData())
                },
                {
                    icon: "select_all",
                    tooltip: `Show/hide ${this.state.isOverlapMode ? "overlapping" : "nearby"} POIs`,
                    action: (async () => await this.displayNearbyPolygons(
                        this.state.viewportStatus,
                        this.state.selectedPOI?this.state.selectedPOI.place_id:undefined
                    ))},
                {
                    icon: "zoom_out_map",
                    tooltip: "Zoom all POI results to view",
                    action: this.displayAllFilteredPOIs,
                },
                {
                    icon: "note_add",
                    tooltip: "Import polygon to current place",
                    action: () => this.openImportPolygonDialog(),
                },
            ]
        };

        const mapFunctions = {
            setViewportStatus: this.setViewportStatus,
            basemapChange: this.basemapChange,
            handleOnResult: this.handleOnResult,
            changeMode: this.changeMode,
            download: this.download,
            createPOIGeo: this.createPOIGeo,
            updatePOIGeo: this.updatePOIGeo,
            deletePOIGeo: this.deletePOIGeo,
            openGoogleMapView: this.openGoogleMapView,
            getIso: this.getIso,
            hideIso: this.hideIso,
            setIsoParams: this.setIsoParams,
            exportGeojson: this.exportGeojson,
            toggleHelp: this.toggleHelp,
        };

        const formFunctions = {
            updatePOIForm: this.updatePOIForm,
            searchPlaceChange: this.searchPlaceChange,
            updateGeoJsonFeature: this.updateGeoJsonFeature,
            createPlace: this.createPlace,
            savePlace: this.savePlace,
            resetPlace: this.resetPlace,
            showPlace: lodash.debounce(this.showPlace, showPlaceDebounceTime),  // TODO: should be debounced on caller side
            openDeletePopup: this.openDeletePopup,
            closeDeletePopup: this.closeDeletePopup,
            doneWorkItem: this.doneWorkItem,
            markWorkItem: this.markWorkItem,
            addWorkTypeFilter: this.searchAddWorkTypeFilter,
            reloadSearchPlaceStat: this.reloadSearchPlaceStat,
            setSearchStatIsOpen: this.setSearchStatIsOpen,
            handleAssignTarget: this.handleAssignTarget,
            handleAssignPlace: this.handleAssignPlace,
            handleAssignFilter: this.handleAssignFilter,
            zoomToPolygon: this.zoomToPolygon,
            exportPolygon: this.exportPolygon,
        };

        const refs = {
            poiFormRef: this.poiFormRef,
            filteredSearchListRef: this.filteredSearchListRef,
            filterInputRef: this.filterInputRef,
            mapRef: this.mapRef,
        };

        const globalHandlers = {
            SHOW_DIALOG: () => this.toggleHelp(),
            SHOW_FILTER_INFO: () => this.reloadSearchPlaceStat(),
            WORK_ITEM_DONE: () => refs.poiFormRef.current.workItemDone(),
            WORK_ITEM_MARK: () => refs.poiFormRef.current.workItemMark(),
            POI_NEW: () => this.createPlace(),
            POI_SAVE: () => this.savePlace(),  // NOTE: Skips form validation, but the server should reject it anyways
            POI_RESET: () => this.resetPlace(),
            FOCUS_FILTER: () => this.filterInputRef.current.focus(),
            TOGGLE_DRAWER: () => this.toggleLeftDrawer(),
        };

        const isPOIDirtyValue = this.isPOIDirty();

        return (
           <Box display="flex" flexDirection="column" style={{height:"100%"}}>
               <GlobalHotKeys
                   keyMap={globalKeyMap}
                   handlers={globalHandlers}
                   global
               />
               <Beforeunload onBeforeunload={() => (isPOIDirtyValue || this.isDrawingActive())?"You'll loose data":null} />
               <Help open={this.state.helpIsOpen} toggle={this.toggleHelp}/>
               <DeleteConfirmation potentialDelete={this.state.deleteClicked} deletePlace={this.deletePlace} handleClose={this.closeDeletePopup} />
               <MDAppBar
                   onHelp={this.toggleHelp}
                   onDehaze={this.toggleLeftDrawer}
                   modeInfo={this.state.modeInfo}
               >
                   {this.formatPlaceSummary()}
               </MDAppBar>
               <Box flexGrow={1}>
                   <CenteredGrid
                       selectedPOI={this.state.selectedPOI}
                       selectedPOIOrig={this.state.originalPOI.selectedPOI}
                       mapInfo={mapInfo}
                       mapFunctions={mapFunctions}
                       formFunctions={formFunctions}
                       refs={refs}
                       hasNextPage={this.state.hasNextPage}
                       searchStatus={this.state.searchStatus}
                       filteredPlaceData={this.state.filteredPlaceData}
                       filteredPlaceTotalCount={this.state.filteredPlaceTotalCount}
                       loadNextChunk={this.loadNextChunk}
                       searchBarInputValue={this.state.searchBarInputValue}
                       searchStat={this.state.searchStat}
                       searchStatIsOpen={this.state.searchStatIsOpen}
                       workTypeValues={this.state.workItemWorkTypes}
                       isPOIDirty={isPOIDirtyValue}
                       modeInfo={this.state.modeInfo}
                       placesets={this.state.placesets}
                       flatPlacesets={this.state.flatPlacesets}
                       displayModeSelectorList={this.state.displayModeSelectorList}
                       onOpenModeSelector={this.handleOpenModeSelector}
                       onModeSelect={this.handleModeSelect}
                       onModeAction={this.handleModeAction}
                       assignTargetInfo={this.state.assignTargetInfo}
                   />
               </Box>
               <PlacesetCreateDialog
                   onSave={this.handleCreatePlaceset}
                   open={this.state.createPlacesetIsOpen}
                   clientId={this.state.modeInfo.selector.client_id||undefined}/>
               <AssignTargetDialog
                   onSave={this.handleAssignTarget}
                   open={this.state.assignTargetDialogOpen}
               />
               <ImportPolygonDialog
                   onSave={this.handleImportPolygon}
                   open={this.state.importPolygonDialogOpen}
               />
               <LongOperationModal
                   open={this.state.copyPlacesModalOpen}
                   title="Copying places"
                   description="Please wait while we perform the copy operation."
               />
               <EditorValuesetLoader
                   name="place_destination_category"
                   onLoad={this.handleCategoryValueset}
               />
           </Box>
        );
    }
}

export default withUserPreferences(withRouter(Editor));
