import React from 'react';
import {useValueset} from "../services/valuesets";

/**
 * NOTE: This is a hack to get around class components not supporting hooks.
 */
export function EditorValuesetLoader({name, onLoad}) {
    if (!Array.isArray(name)) {
        name = [name]
    }
    const [valueset] = useValueset(...name);
    React.useEffect( () => {
        onLoad(valueset);
    }, [valueset]);
    return (<React.Fragment />);
}
