// This module defines the client API interface,
//  and uses CarAjax to call REST endpoints with corresponding HTTP verbs

import CarAjax from './CarAjax';

const isoUrlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
const accessToken =  "pk.eyJ1IjoiY2Fyc29uZ3JlZ29yeSIsImEiOiJjazBwc2d4cjAwMTUyM2hxaHN0Y3VxZzl2In0.V6uLRkPhxTrHs37mhcvmnQ";
const BASE_URL = ''; // our client.package.proxy config bypasses CORS need and routes our requests

// placeholders for request cancellation tokens
var lastPlaceSearchQuery = null;
var lastPlaceStatQuery = null;
var lastPlaceNearbyQuery = null;

export const ARRIVALIST_CLIENT_ID = 1;

const CarApi = {

    // Isochrones
    getIso: (lat, lon, travelMode, travelTime)=> {
       // lat = 42.35633; lon= -71.061904;
        //42.35633, -71.061904,
       
        return CarAjax.get(isoUrlBase + travelMode + '/' + lon + ',' + lat + '?contours_minutes='+travelTime+'&polygons=true&access_token=' + accessToken);
        //console.log(data);
    },
    // Place
    // deprecating:
    //getPlaces: () => {
    //    return CarAjax.get(
    //      BASE_URL + '/api/places/',
    //    )
    //},
    getClients: () => {
        return CarAjax.get(
            BASE_URL + '/api/clients/',
        )
    },
    getClient: (client_id) => {
        return CarAjax.get(
            BASE_URL + '/api/clients/' + client_id,
        )
    },
    getPlacesets: () => {
      return CarAjax.get(
          BASE_URL + '/api/placesets/',
      )
    },
    createPlaceset: (data) => {
        return CarAjax.post(
            BASE_URL + '/api/placesets/',
            data,
        )
    },
    getPlaceset: (placeset_id) => {
        return CarAjax.get(
            BASE_URL + '/api/placesets/' + placeset_id,
        )
    },
    updatePlaceset: (data) => {
        return CarAjax.put(
            BASE_URL + '/api/placesets/' + data.placeset_id,
            data,
        )
    },
    deletePlaceset: (placeset_id) => {
        return CarAjax.delete(
            BASE_URL + '/api/placesets/' + placeset_id,
        )
    },
    validatePlaceset: (placeset_id) => {
        return CarAjax.post(
            BASE_URL + '/api/placesets/' + placeset_id + '/validate',
        )
    },
    assignPlaceToPlaceset: (selector, place_id) => {
        return CarAjax.put(
            BASE_URL + `/api/places-assign-single`,
            Object.assign({id: place_id}, selector),
        )
    },
    assignFilterToPlaceset: (selector, filter) => {
        return CarAjax.put(
            BASE_URL + `/api/places-assign-filter`,
            Object.assign({ filter: JSON.stringify(filter)}, selector),
        )
    },
    getPlacesetMergeTasks: (placeset_id) => {
        return CarAjax.get(
            BASE_URL + '/api/placesets/' + placeset_id + '/merge-tasks',
        )
    },
    updatePlacesetMergeTasks: (placeset_id, data) => {
        return CarAjax.put(
            BASE_URL + '/api/placesets/' + placeset_id + '/merge-tasks',
            {
                data: data,
            },
        )
    },
    getProject: (project_id) => {
        return CarAjax.get(
            BASE_URL + '/api/projects/' + project_id,
        )
    },
    getProjectByDestination: (destination_id) => {
        return CarAjax.get(
            BASE_URL + '/api/destinations/' + destination_id,
        )
    },
    updateProject: (data) => {
        return CarAjax.put(
            BASE_URL + '/api/projects/' + data.id,
            data,
        )
    },
    getPlacesOffset: (search_expr = {}, offset = 0) => {
        if (lastPlaceSearchQuery) {
            lastPlaceSearchQuery.cancel("New search fired");
        }
        lastPlaceSearchQuery = CarAjax.CancelToken.source();
        if (search_expr === null) {
            // fake a response on missing (invalid) query
            return {
                data: {
                    meta: {
                        total: 0
                    },
                    data: [],
                },
            };
        }
        return CarAjax.get(
          BASE_URL + '/api/places/',
          {
              cancelToken: lastPlaceSearchQuery.token,
              params: {
                  search_string: JSON.stringify(search_expr),
                  offset: offset,
              }
          }
        )
    },
    getPlacesStat: (search_expr = {}) => {
        if (lastPlaceStatQuery) {
            lastPlaceStatQuery.cancel("New search-stat fired");
        }
        lastPlaceStatQuery = CarAjax.CancelToken.source();
        return CarAjax.get(
            BASE_URL + '/api/places-stat',
            {
                cancelToken: lastPlaceStatQuery.token,
                params: {
                    search_string: JSON.stringify(search_expr),
                }
            }
        )
    },
    getOverlappingGeos: (polygons, exclude_place, include_placeset, exclude_maz, density_mpp) => {
        if (lastPlaceNearbyQuery) {
            lastPlaceNearbyQuery.cancel("New search-nearby fired");
        }
        lastPlaceNearbyQuery = CarAjax.CancelToken.source();
        return CarAjax.post(
            BASE_URL + '/api/places-overlapping-geos',
            {
                polygons: polygons,
                exclude_place: exclude_place,
                filter_placeset: include_placeset,
                exclude_maz: exclude_maz || false,
                limit: CarApi.const.NEARBY_GEO_LIMIT,
                density_mpp: density_mpp || 0,
            },
            {
                cancelToken: lastPlaceNearbyQuery.token,
            }
        )
    },
    createPlace: (data) => {
        return CarAjax.post(
            BASE_URL + '/api/places/',
            data,
        )
    },
    getPlace: (place_id) => {
        return CarAjax.get(
            BASE_URL + '/api/places/' + place_id,
        )
    },
    updatePlace: (place_id, data) => {
        return CarAjax.put(
            BASE_URL + '/api/places/' + place_id,
            data,
        )
    },
    deletePlace: (place_id) => {
        return CarAjax.delete(
            BASE_URL + '/api/places/' + place_id,
        )
    },

    // Categories (NAICS)
    getCategories: () => {
        return CarAjax.get(
            BASE_URL + '/api/categories/',
        )
    },
    // Valuesets
    getValueset: (name) => {
        return CarAjax.get(
            BASE_URL + '/api/valuesets/',
            {
                params: {
                    name: JSON.stringify(name),
                },
            }
        )
    },

    // Layers
    //createLayer: () => {}
    //getLayer: () => {}
    //updateLayer: () => {}
    //deleteLayer: () => {}
    getLayers: () => {
        return CarAjax.get(
            BASE_URL + '/api/layers/',
        )
    },

    // Tags
    //createTag: () => {}
    //updateTag: () => {}
    //deleteTag: () => {}
    getTags: () => {
        return CarAjax.get(
            BASE_URL + '/api/tags/',
        )
    },

    // User
    createUser: (email, password) => {
        return CarAjax.post(
            BASE_URL + '/auth/register/',
            {email: email, password: password},
        )
    },
    loginUser: (email, password) => {
        return CarAjax.post(
            BASE_URL + '/auth/login/',
            {email: email, password: password},
        )
    },
    signoutUser: () => {
        return CarAjax.delete(
          BASE_URL + '/auth/signout/',
        )
    },
    getUserDetails: () => {
        return CarAjax.get(
          BASE_URL + '/userdetails/',
        )
    },
    getUserPreferences: () => {
        return CarAjax.get(
          BASE_URL + '/api/userpreferences/'
        )
    },
    updateUserPreferences: (data) => {
        return CarAjax.put(
          BASE_URL + '/api/userpreferences/',
          { preferences: data },
        )
    },

    // Car Data
    getCarData: (lat, lon, width, height) => {
        return CarAjax.get(
            BASE_URL + '/api/car_gps/1?' + dictToURI({lat: lat, lon: lon, width: width, height: height}),
        )
    },
    getCarDataDebug: (lat, lon, cnt) => {
        // Helper method used in dev env
        if (cnt === undefined) cnt = 100000;
        let res = { data: { results: [] } };
        for (let i = 0; i < cnt; i++) {
            let rad = Math.random() * Math.PI * 2;
            let r = Math.random() * 0.005;
            let x = r * Math.sin(rad);
            let y = r * Math.cos(rad) * 1.4;
            res.data.results.push({lat: lat + x, lon: lon + y})
        }
        return res;
    },

    // Phone Data
    getPhoneData: (lat, lon, width, height) => {
        return CarAjax.get(
            BASE_URL + '/api/phone_gps/1?' + dictToURI({lat: lat, lon: lon, width: width, height: height}),
        )
    },

    // WorkItems
    getWorkItems: () => {
        return CarAjax.get(
            BASE_URL + '/api/work_items/',
        )
    },
    getWorkItem: (id) => {
        return CarAjax.get(
            BASE_URL + '/api/work_items/' + id,
        )
    },
    updateWorkItem: (work_item_id, data) => {
        return CarAjax.put(
            BASE_URL + '/api/work_items/' + work_item_id,
            data,
        )
    },
    getDECountries: () => {
        return CarAjax.get(
            BASE_URL + '/api/countries/',
        )
    },
    getDERegions: () => {
        return CarAjax.get(
            BASE_URL + '/api/regions/',
        )
    },
    getDECities: () => {
        return CarAjax.get(
            BASE_URL + '/api/cities/',
        )
    },
    const: {
        WorkItem: {
            STATUS_OPEN: "open",
            STATUS_CLOSED: "closed",
            STATUS_BLOCKED: "blocked",
        },
        GeoType: {
            UNSPECIFIED: 0,
            BUILDING: 1,
            PARKING_LOT: 2,
            BUILD_PLUS_PARKLOT: 3,
        },
        Role: {
            ADMIN: "md:admin",
            WORKER: "md:worker",
        },
        AccessLevel: {
            ADMIN: "admin",
            READONLY: "readonly",
        },
        ASSIGN_PLACE_LIMIT: 1000,
        NEARBY_GEO_LIMIT: 1000,
        MergeDecision: {
            theirs: 1,
            ours: 2,
            conflict: 3,
            inplace: 4,
        },
        MergeAction: {
            hold: 1,
            resolved: 2,
            undecided: 3,
        },
        Product: {
            A2_DESTINATION: 1,
            TRIP_MODEL: 2,
            VISITOR_MODEL: 3,
            DAILY_TRAVEL_INDEX: 4,
            A3_AM: 5,
            CAR_VM: 6,
            DATA_FEED: 8,
            MISC: 9,
            LED: 10,
        },
        ProductIdCategories: "See below. Cannot be here as references Products.",
    }
}

CarApi.const.ProductIdCategories = {
    MD_PRODUCT_IDS: [
        CarApi.const.Product.TRIP_MODEL,
        CarApi.const.Product.VISITOR_MODEL,
        CarApi.const.Product.A3_AM,
        CarApi.const.Product.CAR_VM,
    ],
    MD_MAZ_PRODUCT_IDS: [  // Projects that have MAZ settings
        // NOTE: A2_DESTINATION also has MAZ but we dont display it, it is readonly
        CarApi.const.Product.TRIP_MODEL,
        CarApi.const.Product.VISITOR_MODEL,
        CarApi.const.Product.A3_AM,
        CarApi.const.Product.DAILY_TRAVEL_INDEX,
        CarApi.const.Product.CAR_VM,
        CarApi.const.Product.LED,
    ],
}

// @todo re-factor this into the CarAjax.get to take additional parameter
function dictToURI(dict) {
    var str = [];
    for(var p in dict){
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
    }
    return str.join("&");
}

export default CarApi;
