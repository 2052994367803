import validator from 'validator';
import fromEntries from 'fromentries';  // NOTE: Upgrade to node v12 would resolve this
import {PhoneNumberUtil, metadata as phoneMetadata} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * This module is the javascipt incarnation of the field_def_validator.rb module
 */

/**
 * Create an evaluation context
 * @param instance Model instance
 * @param model Model descripors
 * @param valuesets Valuesets used by the model
 * @returns {{valuesets, model, object}}
 */
export function createEvalContext(instance, model, valuesets) {
    return {
        object: instance,
        model: model,
        valuesets: valuesets,
    }
}

/**
 * Expression evaluator
 * This is the heart of the expression evaluator.
 * The currently supported operators are:
 * - get: Retrieve value from a model field
 * @param expr
 * @param context
 * @returns {*}
 */
function evalExprInner(expr, context) {
    if (!isExpr(expr)) {
        throw "evalExprInner called with a non expression";
    }
    const op = expr[1];
    if (op === 'get') {
        const field = expr[2];
        return getPlaceField(context.object, field);
    }
}

/**
 * Test if value is an expression object
 * Expression objects are arrays with the 'EXPR' string as their first value
 * @param value
 * @returns {boolean}
 */
function isExpr(value) {
    return Array.isArray(value) && value[0] === 'EXPR';
}

/**
 * Evaluate an expression
 *
 * We are using something similar to [Mapbox's Expressions](https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/).
 * Scans value for expressions to be evaluated and resolves them.
 * An array starting with 'EXPR' as first value is such an expression.
 * For supported operators, see `evalExprInner`
 * @param expr
 * @param context
 * @returns {*}
 */
export function evalExpr(value, context) {
    if (isExpr(value)) {
        return evalExprInner(value, context);
    } else if (Array.isArray(value)) {
        return value.map((v) => evalExpr(v, context));
    } else if (value?.constructor == Object) {
        return fromEntries(Object.entries(value).map(([k, v]) => [k, evalExpr(v, context)]));
    } else {
        return value;
    }
}

/**
 * Get all the valuesets used by the fields
 * @param fields
 * @param context
 * @returns {*[]}
 */
export function getFieldValuesets(fields, context) {
    let result = []
    fields.forEach((f) => {
        if (f.type_param?.valueset) {
            let r = evalExpr(f.type_param.valueset, context);
            if (!Array.isArray(r)) {
                r = [r]
            }
            result.push(r);
        }
    });
    return result;
}

/**
 * Run validation on all fields available in the context
 * @param context The evaluation context
 * @param errors_ Uses the provided dict or creates and returns a new one
 * @returns {{}} A dict of field keys and error message arrays.
 */
export function validateFields(context, errors_) {
    let errors = errors_ || {};
    Object.keys(context.model).forEach(f => {
       validateField(context.model[f], context, errors);
    });
    return errors;
}

/**
 * Add an error message to the validation dictionary
 * @param errors
 * @param field
 * @param message
 */
function addError(errors, field, message) {
    if (!(field in errors)) {
        errors[field] = [];
    }
    errors[field].push(message);
}

/**
 * Validate a single field
 * @param def The FieldDef of the field
 * @param context The evaluation context
 * @param errors_ Uses the provided dict or creates and returns a new one
 * @returns {{}} A dict of field keys and error message arrays.
 */
export function validateField(def, context, errors_) {
    let errors = errors_ || {};
    const value = evalExpr(["EXPR", "get", def.key], context);
    const is_empty = value === undefined || value === null || value === '';
    if (def.required && is_empty) {
        addError(errors, def.key, "required");
    }
    if (!is_empty) {
        switch (def.type) {
            case 'boolean':
                if (value !== true && value !== false) {
                    addError(errors, def.key, "needs to be true/false. Unknown value");
                }
                break;
            case 'string':
                if (typeof(value) !== "string") {
                    addError(errors, def.key, "non string value");
                } else {
                    if (def.type_param?.phone) {
                        const countryCode = evalExpr(def.type_param.phone, context);
                        const number = phoneUtil.parseAndKeepRawInput(value + "", countryCode);
                        const wrongCountryCode = phoneUtil.getRegionCodeForNumber(number) !== null && phoneUtil.getRegionCodeForNumber(number) !== countryCode;
                        if (!phoneUtil.isPossibleNumber(number) || wrongCountryCode) {
                            addError(errors, def.key, "invalid phone number");
                        }
                    }
                    if (def.type_param?.postal_code) {
                        var countryCode = evalExpr(def.type_param.postal_code, context);
                        if (!validator.isPostalCodeLocales.includes(countryCode)) {
                            console.log("Country code missing from validator.isPostalCodeLocales, fallback to 'any'");
                            countryCode = 'any';
                        }
                        if (!validator.isPostalCode(value + "", countryCode)) {
                            // TODO use?: https://www.npmjs.com/package/postal-codes-js
                            addError(errors, def.key, "invalid postal code");
                        }
                    }
                }
                break;
            case 'integer':
            case 'float':
                if (value*1+""!==value+"" || def.type=='integer' && (''+value).includes('.')) {
                    addError(errors, def.key, `Invalid ${def.type} value`);
                } else {
                    const numeric_value = value * 1;
                    const min_limit = evalExpr(def.type_param?.min || -Infinity, context);
                    if (numeric_value < min_limit) {
                        addError(errors, def.key, `Cannot be smaller than ${min_limit}`);
                    }
                    const max_limit = evalExpr(def.type_param?.max || +Infinity, context);
                    if (numeric_value > max_limit) {
                        addError(errors, def.key, `Cannot be greater than ${max_limit}`);
                    }
                }
                break;
        }
        if (def.type_param?.valueset) {
            const valuesetId = evalExpr(def.type_param.valueset, context);
            const valueset = context.valuesets[valuesetId];
            const match = valueset?.find(item => item.value === value);
            if (match === undefined) {
                addError(errors, def.key, "Invalid value");
            }
        }
    }
    return errors;
}

/**
 * Get a field from the object
 * For `custom_attributes` prefix the name with a single dot.
 * The `custom_attributes` part can be customized via the `dotField`.
 * @param object
 * @param fieldname
 * @param dotField
 * @returns {*}
 */
export function getPlaceField(object, fieldname, dotField = "custom_attributes") {
    if (fieldname[0] === ".") {
        object = object[dotField];
        fieldname = fieldname.substr(1);
    }
    return object?.[fieldname];
}

/**
 * Set a field of the object
 * For `custom_attributes` prefix the name with a single dot.
 * The `custom_attributes` part can be customized via the `dotField`.
 * @param object Model instance to update
 * @param fieldname Field to write (. prefix refers to the `dotField`
 * @param value Value to be set
 * @param dotField The container for dot-prefixed fields. Defaults to `customer_attributes`
 */
export function setPlaceField(object, fieldname, value, dotField = "custom_attributes") {
    if (fieldname[0] === ".") {
        // NOTE: Could use logicalAssignment once not experimental
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR_assignment
        if (!object[dotField]) {
            object[dotField] = {}
        }
        object = object[dotField]
        fieldname = fieldname.substr(1);
    }
    object[fieldname] = value;
}
