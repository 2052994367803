import React from "react";
import CarApi from "../../api/CarApi";
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";

function countStats(tasks) {
    const placeIds = new Set();
    const decisions = {
        [CarApi.const.MergeDecision.theirs]: 0,
        [CarApi.const.MergeDecision.ours]: 0,
        [CarApi.const.MergeDecision.conflict]: 0,
        [CarApi.const.MergeDecision.inplace]: 0,
    };
    const actions = {
        [CarApi.const.MergeAction.undecided]: 0,
        [CarApi.const.MergeAction.resolved]: 0,
        [CarApi.const.MergeAction.hold]: 0,
    };
    tasks.forEach(row => {
        placeIds.add(row.place_id);
        decisions[row.decision]+=1;
        actions[row.action]+=1;
    });
    return {
        "places": placeIds.size,
        "fields": tasks.length,
        "decisions": decisions,
        "actions": actions,
    };
}

export function MergeTaskStat({rawMergeTasks, mergeTasks}) {
    const rawStat = countStats(rawMergeTasks);
    const filterStat = countStats(mergeTasks);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" className="prodtour-stats">
                <TableHead>
                    <TableRow>
                        <TableCell
                            rowSpan={2}
                            align="center"
                            style={{
                                borderRight:"1px solid #e0e0e0",
                            }}
                        >
                            Stat
                        </TableCell>
                        <TableCell colSpan={2} align="center"  padding="none">Record</TableCell>
                        <TableCell
                            colSpan={4}
                            align="center"
                            padding="none"
                            style={{
                                borderLeft:"1px solid #e0e0e0",
                                borderRight:"1px solid #e0e0e0",
                            }}
                        >
                            Decision
                        </TableCell>
                        <TableCell colSpan={3} align="center" padding="none">Action</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" padding="none">
                            Places<br/>
                            {filterStat.places}/{rawStat.places}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Fields<br/>
                            {filterStat.fields}/{rawStat.fields}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Theirs<br/>
                            {filterStat.decisions[CarApi.const.MergeDecision.theirs]}/
                            {rawStat.decisions[CarApi.const.MergeDecision.theirs]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Ours<br/>
                            {filterStat.decisions[CarApi.const.MergeDecision.ours]}/
                            {rawStat.decisions[CarApi.const.MergeDecision.ours]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Conflict<br/>
                            {filterStat.decisions[CarApi.const.MergeDecision.conflict]}/
                            {rawStat.decisions[CarApi.const.MergeDecision.conflict]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Inplace<br/>
                            {filterStat.decisions[CarApi.const.MergeDecision.inplace]}/
                            {rawStat.decisions[CarApi.const.MergeDecision.inplace]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Undecided<br/>
                            {filterStat.actions[CarApi.const.MergeAction.undecided]}/
                            {rawStat.actions[CarApi.const.MergeAction.undecided]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Resolved<br/>
                            {filterStat.actions[CarApi.const.MergeAction.resolved]}/
                            {rawStat.actions[CarApi.const.MergeAction.resolved]}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            Hold<br/>
                            {filterStat.actions[CarApi.const.MergeAction.hold]}/
                            {rawStat.actions[CarApi.const.MergeAction.hold]}
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>
    );
}
