import TextField from "@material-ui/core/TextField";
import {fixAutocompleteBug} from "./utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import lodash from 'lodash';

export const AriAutocomplete = React.forwardRef(({value, onChange, options, autoSelect, blurOnSelect, outerStyle, labelGetter, valueGetter, valueEquals:valueEqualsParam, disableClearable, ...fieldArgs}, ref) => {
  const defaultValueEquals = (a, b) => a===b;
  const valueEquals = valueEqualsParam || defaultValueEquals
  return <Autocomplete
      ref={ref}
      options={options}
      autoSelect={autoSelect}
      blurOnSelect={blurOnSelect}
      disableClearable={disableClearable}
      style={outerStyle}
      getOptionLabel={(...args) => labelGetter(...args) || ""}
      value={[...options.filter((option)=>valueEquals(valueGetter(option), value)), null][0]}
      onChange={(_, value) => onChange(valueGetter(value))}
      renderInput={(params) => (
          <TextField
              {...lodash.merge(fixAutocompleteBug(params), fieldArgs)}
          />)}
  />;
});

export function fieldGetter(fieldName) {
  return (object) => object?.[fieldName];
}
