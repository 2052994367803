/**
 * * Cache backend valuesets for faster UI rendering
 */
import {useEffect, useState} from "react";
import {getValue, setValue} from "../utils/localStorageCache";
import CarApi from "../api/CarApi";
import lodash from "lodash";

const CACHE_TIMEOUT_MS = 1*60*60*1000;

/**
 * Cache backend valuesets for faster UI rendering
 *
 * The hook returns an empty dict of valuesets when it is created.
 *   This means you should expect data[valuesetId] to return undefined
 *   initially.
 * It updates this immediately if a response is available in the cache.
 * Cache is invalidated if it is too old. (CACHE_TIMEOUT_MS)
 * If cache is empty it calls the server, and requests fresh data.
 *
 * @param name Label to identify this data in the cache. Required.
 * @param params Params for the valueset. Optional.
 * @returns {[data, status, reloadDataFn]}
 */
export function useValuesets(keys) {
    let [status, setStatus] = useState({});
    let [data, setData] = useState({});
    let [oldKeys, setOldKeys] = useState([]);
    useEffect(() => {
        const oldKeyLut = Object.fromEntries(oldKeys.map((k)=>[k+"", k]));
        const keyLut = Object.fromEntries(keys.map((k)=>[k+"", k]));
        Object.keys(oldKeyLut).forEach((k) => {
            if (!(k in keyLut)) {
                delete data[k];
                delete status[k];
            }
        });
        Object.keys(keyLut).forEach((k) => {
            if (!(k in oldKeyLut)) {
                data[k] = [];
                status[k] = "ready";
                reloadDataWithCache(keyLut[k]);
            }
        });
        setOldKeys(lodash.cloneDeep(keys));
    }, [keys.join("//")]);
    const reloadDataWithCache = async (key) => {
        const resp = getValue(key);
        if (resp) {
            if (!(key in data)) return;
            data[key] = resp.data;
            setData(data);
        } else {
            await reloadData(key);
        }
    };
    const reloadData = async (key) => {
        if (!(key in status)) return;
        status[key] = "loading";
        setStatus(status);
        const resp = await CarApi.getValueset(key);
        setValue(key, resp, CACHE_TIMEOUT_MS);
        if (!(key in data)) return;
        data[key] = resp.data;
        setData(data);
        if (!(key in status)) return;
        status[key] = "ready";
        setStatus(status);
    };
    return [data, status, reloadData];
}

/**
 * Cache backend valueset for faster UI rendering
 *
 * The hook returns an empty valueset when it is created.
 * It updates this immediately if a response is available in the cache.
 * Cache is invalidated if it is too old. (CACHE_TIMEOUT_MS)
 * If cache is empty it calls the server, and requests fresh data.
 *
 * @param name Label to identify this data in the cache. Required.
 * @param params Params for the valueset. Optional.
 * @returns {[data, status, reloadDataFn]}
 */
export function useValueset(...key) {
    const [datas, statuses, reloadDatas] = useValuesets([key]);
    return [datas[key] || [], statuses[key] || "ready", ()=>reloadDatas(key)];
}
