import React from 'react';
import lodash from "lodash";

/**
 * Debounces the onChange callback of a TextInput
 * WARNING! This solution alters the value the onChange callback receives.
 *   Originally an Event object is passed, but we replace it with `event.target.value`.
 * @param WrappedComponent TextInput to be wrapped
 * @param debounce_time_ms The debounce time in milliseconds
 * @param valueParam Name of the value parameter (defaults to `value`)
 * @param onChangeParam Name of the onChange parameter (defaults to `onChange`)
 *   If the onChange call returns `false`, the value is rejected, and reset to the latest received in `valueParam`.
 * @returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export function withDebounceOnChange(WrappedComponent, debounce_time_ms, valueParam='value', onChangeParam='onChange') {
    return React.forwardRef((props, ref) => {
        const { [valueParam]:valueProp, [onChangeParam]:onChangeProp, ...passThroughProps } = props;
        const [value, setValue] = React.useState(valueProp)
        const [hasLocalChange, setHasLocalChange] = React.useState(false);
        React.useEffect(() => {
            if (!hasLocalChange) {
                setValue(valueProp);
            }
            valuePropValue.current = valueProp;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [valueProp]);
        const valuePropValue = React.useRef(valueProp);
        const onInputChangeDebounced = React.useRef(lodash.debounce((p1) => {
            setHasLocalChange(false);
            let result = onChangeProp(p1);
            if (result === false) {
                setValue(valuePropValue.current);
            }
        }, debounce_time_ms));
        const onInputChange = (event) => {
            let newValue = event.target.value;
            setHasLocalChange(true);
            onInputChangeDebounced.current(newValue);
            setValue(newValue);
        }
        return (
            <WrappedComponent
                ref={ref}
                {...{
                    [valueParam]:value,
                    [onChangeParam]:onInputChange,
                    ...passThroughProps
                }}
            />
        );
    });
}
