import {AssignValueType} from "./constants";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {Box} from "@material-ui/core";
import {OptionalTooltip} from "../OptionalTooltip";
import React from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {isEmptyArray} from "../../utils/utils";

export function AssignButton({handler, rowIndex, valueType, value}) {
    const isDownDirection = valueType !== AssignValueType.DECISION;
    const ArrowDirIcon = isDownDirection ? ArrowDownwardIcon : ArrowForwardIcon;
    const tooltip = rowIndex !== undefined ?
        "Apply value to this record"
        :
        "Apply value to all filtered records";
    return (
        <Box
            style={{
                cursor: "pointer",
                display: "inline-block",
                verticalAlign: "bottom",
            }}
            onClick={() => handler(rowIndex, valueType, value)}
        >
            <OptionalTooltip
                title={tooltip}
            >
                <ArrowDirIcon
                    fontSize="small"
                    style={{verticalAlign: "bottom"}}
                />
            </OptionalTooltip>
        </Box>
    );
}

export function lowstr(value) {
    if (value === undefined || value === null) {
        return ''
    } else {
        return ('' + value).toLowerCase();
    }
}

export function ValuePreview({value, maxWidth}) {
    const empty = (value === null || value === undefined || value === "" || isEmptyArray(value));
    return (
        <Box
            data-preview
            style={{
                maxWidth: maxWidth,
                display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                verticalAlign: 'bottom',
            }}>
            {empty ? (
                <i style={{color: 'grey'}}>
                    empty
                </i>
            ) : Array.isArray(value) ? (
                `${value.length} item(s)`
            ) : String(value)
            }
        </Box>
    )
}

export function ValueDeltaIcon() {
    return (
        <Box
            style={{
                cursor: "pointer",
                display: "inline-block",
                verticalAlign: "bottom",
            }}
        >
            <OptionalTooltip
                title="Value updated in origin from value on the left to value on the right"
            >
                <ArrowRightAltIcon
                    fontSize="small"
                    style={{verticalAlign: "bottom"}}
                />
            </OptionalTooltip>
        </Box>
    );
}

const PENCIL_CHARACTER = String.fromCharCode(9998);

export function EditableCellRenderer({value, maxWidth}) {
    return (
        <React.Fragment>
            {PENCIL_CHARACTER}
            <ValuePreview value={value} maxWidth={!maxWidth ? maxWidth : (maxWidth - 20)}/>
        </React.Fragment>
    );
}

/**
 * Customized rowRenderer to add in rowIndex in data-rowindex for each row.
 *
 * See: https://github.com/bvaughn/react-virtualized/blob/master/source/Table/defaultRowRenderer.js
 * @returns {JSX.Element}
 */
export function mergeRowRenderer({index, className, key, style, columns, rowData}) {
    const a11yProps = {'aria-rowindex': index + 1};
    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
            data-rowindex={rowData.rowIndex}
        >
            {columns}
        </div>
    );
}
