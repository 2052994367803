import React from 'react';
import {Box, Button, FormControlLabel, Paper, Radio, RadioGroup} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {PlacesetSettingsMazArea} from "./PlacesetSettingsMazArea";
import {PlacesetSettingsMazPoi} from "./PlacesetSettingsMazPoi";
import CarApi from "../../api/CarApi";
import lodash from 'lodash';

const MAZ_MODE_AREA = "area";
const MAZ_MODE_POI = "poi";

export function PlacesetSettingsMazTab({placeset:placesetParam, project, onChange}) {
  if (placesetParam && project) {
    throw new Error("Cannot specify both placeset and project. Use only one of them.")
  }
  const placeset = project?.placeset || placesetParam;
  const [hasPois, setHasPois] = React.useState(true);
  const [mazMode, setMazMode] = React.useState(MAZ_MODE_AREA);
  const [mazAreas, setMazAreas] = React.useState([]);
  const [mazPois, setMazPois] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const updateFromPlaceset = async (placeset) => {
    setLoading(true);
    setMazMode(placeset?.maz?.pois?.length ? MAZ_MODE_POI : MAZ_MODE_AREA);
    setMazAreas(placeset?.maz?.areas || []);
    setMazPois(placeset?.maz?.pois || []);
    if (!placeset) {
      setHasPois(false);
    } else {
      const res = await CarApi.getPlacesOffset({placeset_id: placeset?.id}, 0);
      setHasPois(res?.data?.data?.length > 0);
    }
    setLoading(false);
  }
  React.useEffect(() => {
    updateFromPlaceset(placeset);
  }, [placeset]);
  const handleMazModeChange = (e) => {
    const mode = e.target.value;
    setMazMode(mode);
  }
  const handleReset = () => {
    updateFromPlaceset(placeset);
  }
  const handleSave = async () => {
    if (placeset) {
      await CarApi.updatePlaceset({
        placeset_id: placeset?.id,
        maz: {
          pois: (mazMode === MAZ_MODE_POI && mazPois) || [],
          areas: (mazMode === MAZ_MODE_AREA && mazAreas) || [],
        },
      });
    } else {
      await CarApi.updateProject({
        id: project?.id,
        maz: {
          pois: (mazMode === MAZ_MODE_POI && mazPois) || [],
          areas: (mazMode === MAZ_MODE_AREA && mazAreas) || [],
        },
      });
    }
    onChange();
  }
  const hasBoth = mazAreas.length > 0 && mazPois.length > 0;
  const hasCityArea = Math.max(...mazAreas.map(x => x.length)) > 2;
  const allowCityAreaEdit = window.location.hash.length > 0;  // Tricky override, if # is present allow edit
  const isDirty = !lodash.isEqual(mazAreas, placeset?.maz?.areas || []) ||
      !lodash.isEqual(mazPois, placeset?.maz?.pois || []);
  // FIXME: Move save/reset back to bottom once the flex-height of the Area/POI component is fixed
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box>
        { (hasCityArea && !allowCityAreaEdit) ? (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12} style={{color:"red"}}>
                  MAZ has city level areas selected. This setting is non-editable on the current UI.
                  If you want to switch to POI based MAZ, ping @carson or @vhermecz. (CH37775)
                </Grid>
                <Grid item xs={12}>
                  The currently set value is:
                </Grid>
              </Grid>
              <PlacesetSettingsMazArea value={mazAreas}/>
            </React.Fragment>
        ) : (
            <React.Fragment>
              <Grid container>
                { hasBoth && (
                    <Grid item xs={12} style={{color:"red"}}>
                      Both area and Poi based MAZ is selected. Only the visible one will be saved and active.
                    </Grid>
                )}
                { !hasPois && (
                    <Grid item xs={12} style={{color:"green"}}>
                      Does not have any places defined. Only area based MAZ is allowed.
                    </Grid>
                )}
                <Grid item xs={12}>
                  <Box p={1}>
                    <Button
                        onClick={handleReset}
                        variant="contained"
                        color="primary"
                        disabled={!isDirty}>
                      Reset
                    </Button>
                    <Button
                        onClick={handleSave}
                        style={{marginLeft:8}}
                        variant="contained"
                        color="primary"
                        disabled={!isDirty}>
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Paper>
                <Box p={1}>
                  <RadioGroup aria-label="mazMode" name="mazMode" value={mazMode} onChange={handleMazModeChange}>
                    <Grid container>
                      <Grid item xs={6}>
                        <FormControlLabel value={MAZ_MODE_AREA} control={<Radio />} label="Area" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel value={MAZ_MODE_POI} control={<Radio />} label="POI" disabled={!hasPois}/>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Box>
                {
                  !loading && (
                    mazMode === MAZ_MODE_AREA ?
                        <PlacesetSettingsMazArea value={mazAreas} onChange={setMazAreas}/>
                        :
                        <PlacesetSettingsMazPoi value={mazPois} placeset={placeset} onChange={setMazPois}/>
                  )
                }
              </Paper>
            </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
