import React from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CarApi from "../../api/CarApi";
import {Box} from "@material-ui/core";
import {ValidatePlaceset} from "./ValidatePlaceset";

export function PlacesetSettingsMainTab({placeset, onChange}) {
    const [placesetName, setPlacesetName] = React.useState("");
    const refresh = React.useCallback(async () => {
        setPlacesetName(placeset?.name);
    }, [placeset]);
    React.useEffect(() => {
        refresh();
    }, [refresh]);
    const handleUpdate = async () => {
        await CarApi.updatePlaceset({
            placeset_id: placeset?.id,
            name: placesetName,
        });
        onChange();
    };
    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box>
                <TextField
                    value={placesetName}
                    onChange={(e) => setPlacesetName(e.target.value)}
                    fullWidth
                    label="Name"
                />
                <Button
                    onClick={handleUpdate}
                >
                    Save changes
                </Button>
                <ValidatePlaceset placesetId={placeset?.id}/>
            </Box>
        </Box>
    );
}
