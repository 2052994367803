import React from 'react';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box} from "@material-ui/core";

const SearchStatInfo = React.forwardRef(({searchStat, searchStatIsOpen, setSearchStatIsOpen, reloadStat}, ref) => {
    const handleClick = event => {
        reloadStat();
    };

    const handleClose = (index) => {
        setSearchStatIsOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                ref={ref}
                style={{minWidth:"0px", padding:"6px"}}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <InfoIcon/>
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={searchStatIsOpen}>
                <DialogTitle id="simple-dialog-title">Search stat info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            searchStat.error !== undefined ? (<Box>
                                Error/timeout during query.
                            </Box>) : (
                                searchStat.cnt_total === undefined ?(<Box>
                                    Loading...
                                </Box>) : (<Box>
                                    # of places: { searchStat.cnt_total}<br/>
                                    # of places with polygon types:<br/>
                                    unspec: {searchStat.cnt_geom_unspec}<br/>
                                    building: {searchStat.cnt_geom_building}<br/>
                                    parking: {searchStat.cnt_geom_parking}<br/>
                                    mixed: {searchStat.cnt_geom_mixed}<br/>
                                    any: {searchStat.cnt_geom}<br/>
                                </Box>)
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});

export default SearchStatInfo;
