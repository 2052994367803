import React, { Component } from 'react'
import {getApplicationKeyMap, HotKeys, ObserveKeys} from "react-hotkeys";
import lodash from 'lodash';

const styles = {
    DIALOG: {
        width: 1200,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '24px',
        backgroundColor: 'white',
        zIndex: 100,
        borderRadius: 10,
        border: '3px solid black',
        color: 'rgba(0,0,0,0.87)'
    },
    MAIN_TABLE: {
        tableLayout: 'fixed',
        width: '100%',
    },
    MAIN_TABLE_TD: {
        verticalAlign: 'top',
    },
    KEYMAP_TABLE_CELL: {
        padding: 8
    }
};

class Help extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            filter: '',
            keyMap: null,
        };
    }

    renderKeyMapTable(keyMap, filter) {
        const _filter = filter.toUpperCase();
        return (
            <table>
                <tbody>
                { Object.keys(keyMap).reduce((memo, actionName) => {
                    if (filter.length === 0 || actionName.indexOf(_filter) !== -1) {
                        const { sequences, name } = keyMap[actionName];

                        memo.push(
                            <tr key={name || actionName}>
                                <td style={styles.KEYMAP_TABLE_CELL}>
                                    {sequences.map(({sequence}) => <span className='kbd'
                                                                         key={sequence}>{sequence}</span>)}
                                </td>
                                <td style={styles.KEYMAP_TABLE_CELL}>
                                    { name }
                                </td>
                            </tr>
                        )
                    }
                    return memo;
                }, []) }
                </tbody>
            </table>);
    }

    renderFilterExpressionHelp() {
        return (
            <React.Fragment>
            Places filter can use field prefixes to filter for only specific fields:<br/>
            E.g.: 'country:us' => Shows only places from the US.<br/>
            E.g.: 'us' => Shows places if their description (any field) contains 'us'.<br/>
            <br/>
            If you add filters for the same prefix multiple times, any of those matching are returned. E.g.: 'brand:alfa,omega' or 'brand:alfa brand:omega'<br/>
            <br/>
            Available field filters:<br/>
            <ul>
                <li><b>all</b>: Filter in name, city, brand and address fields e.g.: all:Main</li>
                <li><b>address</b>: Filter in address e.g.: address:Kennedy</li>
                <li><b>brand</b>: brand_name</li>
                <li><b>city</b>: Filter for city e.g.: city:denver</li>
                <li><b>country</b>: Filter for country e.g.: country:US</li>
                <li><b>has_overlap</b>: (bool) Overlaps with other places</li>
                <li><b>has_building</b>: (bool) Has building geo</li>
                <li><b>has_mixed</b>: (bool) Has mixed (building+parking) geo</li>
                <li><b>has_parking</b>: (bool) Has parking geo</li>
                <li><b>has_unspec</b>: (bool) Has unspecified geo</li>
                <li><b>naics</b>: Searches in naics code prefixes</li>
                <li><b>name (default)</b>: name</li>
                <li><b>phone</b>: phone_number</li>
                <li><b>place_id</b>: supports lists e.g. place_id:123,456</li>
                <li><b>postal_code (or post, zip)</b>: postal_code</li>
                <li><b>region</b>: Filter for region e.g.: region:CA</li>
                <li><b>work_status (or ws, wstatus)</b>: work status (open, close, blocked)</li>
                <li><b>work_type (or work, wt)</b>: work_types</li>
            </ul>
            For bool types: true, false, 1, 0 values can be used.<br/>
            </React.Fragment>
        );
    }

    renderMapHotkeyHelp() {
        return (
            <React.Fragment>
                <table>
                    <tbody>
                    <tr><td colSpan={2}><b>Navigation</b></td></tr>
                    <tr>
                        <td><span className='kbd' key='shift+arrows'>shift+arrows</span></td>
                        <td>rotate, pitch</td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='arrows'>arrows</span></td>
                        <td>pan (move)</td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='+'>+</span><span className='kbd' key='-'>-</span></td>
                        <td>zoom</td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>Selection</b></td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='shift'>shift</span>+click</td>
                        <td>select multiple</td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='shift'>shift</span>+mousemove</td>
                        <td>select polygons touching bbox</td>
                    </tr>
                    <tr>
                        <td>click</td>
                        <td>
                            select polygons, or point within polygon<br/>
                            Allows splitting line in middle.
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>Drawing</b></td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='1'>1</span></td>
                        <td>Point drawing mode. FIXME (causes error, should be disabled)</td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='3'>3</span></td>
                        <td>Draw new polygon</td>
                    </tr>
                    <tr>
                        <td><span className='kbd' key='del'>del</span>/<span className='kbd' key='bkspc'>bkspc</span></td>
                        <td>Delete selected item</td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    render () {
        if (this.props.open) {
            // start of CH-240 hack
            let keyMap = this.state.keyMap;  // CH-240: getApplicationKeyMap();
            if (this.state.keyMap === null) {
                const keyMapValue = lodash.cloneDeep(getApplicationKeyMap());
                this.setState({
                    keyMap: keyMapValue,
                });
                keyMap = keyMapValue;
            }
            // end of CH-240 hack
            const { filter } = this.state;

            return (
                <HotKeys
                    keyMap={{CLOSE_DIALOG: 'Escape' }}
                    handlers={{ CLOSE_DIALOG: () => this.props.toggle()} }
                >

                    <div style={styles.DIALOG}>
                        <ObserveKeys only={'Escape'}>
                            <input
                                autoFocus
                                onChange={({target: {value}}) => this.setState({ filter: value })}
                                value={filter}
                                placeholder='Filter'
                            />
                        </ObserveKeys>

                        <table style={styles.MAIN_TABLE}>
                            <tbody>
                            <tr>
                                <td style={styles.MAIN_TABLE_TD}>
                                    <h2>Keyboard shortcuts</h2>
                                    {this.renderKeyMapTable(keyMap, filter)}
                                </td>
                                <td style={styles.MAIN_TABLE_TD}>
                                    <h2>Filter expressions</h2>
                                    {this.renderFilterExpressionHelp()}
                                </td>
                                <td style={styles.MAIN_TABLE_TD}>
                                    <h2>Map hotkeys</h2>
                                    {this.renderMapHotkeyHelp()}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </HotKeys>
            );
        }
        return (null);
    }
}

export default Help
