// Perhaps add https://www.npmjs.com/package/retry-axios to smoothen connectivity issues
import axios from 'axios';
import Cookies from 'universal-cookie';

const CarAjax = {
    // create
    post: (url, data) => axios.post(url, data),
    // read
    get: (url, config) => axios.get(url, config),
    // update
    put: (url, data) => axios.put(url, data),
    // delete
    delete: (url) => axios.delete(url),
    // cancellation feature
    CancelToken: axios.CancelToken,
    isCancel: axios.isCancel,
}

// Persist the authorization token to cookie, and use it in subsequent requests
const cookies = new Cookies();

axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = cookies.get('ssoAuth');
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.request.use( (config) => {
    config.metadata = config.metadata || {};
    config.metadata.startTime = new Date();
    return config;
}, (error) => {
    return Promise.reject(error);
});

function createPerformanceLogRecord(response) {
    // eslint-disable-next-line no-unused-vars
    const log = {
        path: response.config.url,
        method: response.config.method,
        params: response.config.params,
        status: response.status,
        duration: response.duration,
    };
    // NOTE: Could abstract away specific values from path and params
    // console.log(log);
    // TODO: Log into a data analytics system, like https://sentry.io/for/performance/
}

function processResponseTime(response) {
    const metadata = response.config.metadata;
    metadata.endTime = new Date();
    response.duration = metadata.endTime - metadata.startTime;
}

function handleForceLogout(response) {
    if (response?.data?.api_action === 'force_logout') {
        window.location = '/saml/logout';
    }
}

axios.interceptors.response.use( (response) => {
    processResponseTime(response);
    createPerformanceLogRecord(response);
    handleForceLogout(response);
    return response;
}, (error) => {
    if (!axios.isCancel(error) && error.response) {
        processResponseTime(error.response);
        createPerformanceLogRecord(error.response);
        handleForceLogout(error.response);
    }
    return Promise.reject(error);
});

export default CarAjax;
