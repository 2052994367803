import {lowstr} from "./utils";
import lodash from "lodash";
import {AssignValueType} from "./constants";
import CarApi from "../../api/CarApi";

export function normalizeFilters(filters) {
    const normalizedFilters = Object.assign({}, filters);
    normalizedFilters.change = lowstr(normalizedFilters.change);
    normalizedFilters.current = lowstr(normalizedFilters.current);
    normalizedFilters.value = lowstr(normalizedFilters.value);
    normalizedFilters.decision = normalizedFilters.decision | 0;
    normalizedFilters.action = normalizedFilters.action | 0;
    return normalizedFilters;
}

export const mergeTaskFilter = (filter) => (record) => {
    if (filter.field && filter.field !== record.field) {
        return false;
    }
    if (filter.decision && filter.decision !== record.decision) {
        return false;
    }
    if (filter.action && filter.action !== record.action) {
        return false;
    }
    if (filter.change && lowstr(record.us_from_value).indexOf(filter.change) < 0
        && lowstr(record.us_to_value).indexOf(filter.change) < 0) {
        return false;
    }
    if (filter.current && lowstr(record.ds_value).indexOf(filter.current) < 0) {
        return false;
    }
    if (filter.value && lowstr(record.value).indexOf(filter.value) < 0) {
        return false;
    }
    return true;
}

export function getRowUpdateValue(row) {
    return {
        decision: row.decision,
        value: row.value,
        action: row.action,
    };
}

/**
 * Construct update message sent to the server
 * Original value is stored for each row in originalValue field. If data changed, we submit it.
 */
export function getUpdate(data) {
    const result = [];
    data.forEach((row) => {
        const value = getRowUpdateValue(row);
        if (!lodash.isEqual(value, row.originalValue)) {
            value.field = row.field;
            value.place_id = row.place_id;
            result.push(value);
        }
    });
    return result;
}

/**
 * Compute undo and redo logs for assign actions
 *
 * Having a valueType == AssignValueType.DECISION means that for each record
 * we take value from a specific field.
 * Having a valueType == AssignValueType.VALUE means that `value` is used as
 * the value for each row.
 *
 * @param rowIndex List of rows to apply the mutation to
 * @param rawMergeTasks The raw data
 * @param valueType AssignValueType on how to interpret value
 * @param value The new value
 * @returns {{undolog: *[], redolog: *[]}}
 */
export function computeMutations(rowIndex, rawMergeTasks, valueType, value) {
    const undolog = [];
    const redolog = [];
    rowIndex.forEach(rowIndex => {
        const row = rawMergeTasks[rowIndex];
        undolog.push({
            rowIndex: rowIndex,
            decision: row.decision,
            action: row.action,
            value: row.value,
        });
        const redo = {
            rowIndex: rowIndex,
        }
        if (valueType === AssignValueType.ACTION) {
            redo.action = value;
        } else {
            if (valueType === AssignValueType.DECISION) {
                if (value === CarApi.const.MergeDecision.ours) {
                    redo.value = row.ds_value;
                } else if (value === CarApi.const.MergeDecision.theirs) {
                    redo.value = row.us_to_value;
                } else {
                    throw new Error("Invalid value");
                }
            } else {
                redo.value = value;
            }
            if (row.action !== CarApi.const.MergeAction.hold) {
                redo.action = CarApi.const.MergeAction.resolved;
            }
            if (redo.value === row.ds_value) {
                redo.decision = CarApi.const.MergeDecision.ours;
            } else if (redo.value === row.us_to_value) {
                redo.decision = CarApi.const.MergeDecision.theirs;
            } else {
                redo.decision = CarApi.const.MergeDecision.inplace;
            }
        }
        redolog.push(redo);
    });
    return {undolog, redolog};
}
