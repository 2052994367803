import React from 'react';
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";
import CarApi from "../../api/CarApi";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import lodash from 'lodash';
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * Normalize API response
 * Remove unused fields
 * Lowercase key fields
 * @param data
 * @param fields - fields to keep, lowercase values except first
 * @return {*}
 */
function normalize_api_data(data, fields) {
  return data.map(item => {
    var nItem = {}
    fields.forEach(field => {
      var value = item[field] || "";
      if (fields[0] !== field) {
        value = value.toLowerCase();
      }
      nItem[field] = value;
    });
    return nItem;
  });
}

/**
 * Fix regions data
 * - Add in country's name in parens
 * - Convert country refs from alpha2 to alpha3
 * @param regions
 * @param countries - Country list for enriching name
 * @return {*}
 */
function fix_regions(regions, countries) {
  const countryLut = lodash.keyBy(countries, "alpha_2");
  return regions.map(region => {
    const country = countryLut[region.country_alpha_2];
    delete region.country_alpha_2;
    region.short_name = region.name;
    region.name = `${region.name || region.alphanum_3} (${country.name})`;
    region.country_alpha_3 = country.alpha_3;
    return region;
  })
}

/** Build the cache key for country-region pairs  */
function _regionCacheKey(countryCode, regionCode) {
  return `${countryCode}-${regionCode}`;
}

/**
 * Generate label lookup for countries and regions
 * Keys like 'us' or 'us-ca' would return the name of the country/region
 * @param countries API data
 * @param regions API data
 * @returns {{}}
 */
function computeLabelCache(countries, regions) {
  const labelCache = {};
  countries.forEach((c) => {
    labelCache[c.alpha_3] = c.name;
  });
  regions.forEach((r) => {
    labelCache[_regionCacheKey(r.country_alpha_3, r.alphanum_3)] = r.short_name;
  })
  return labelCache;
}

/**
 * Render info box from an area path
 * @param path - The path describing this area [country, region, city]
 * @param onDropPlace - Callback to remove this area
 */
function Area({path, onDropPath, labelCache}) {
  const countryCode = path[0];
  const regionCode = path[1];
  const cityCode = path[2];
  const renderLabel = (code, cacheCode) => {
    const value = labelCache[cacheCode];
    return value ? `${value} (${code})` : code;
  };
  return (
      <Grid container style={{height:45}}>
        <Grid item xs={3} style={{alignSelf:'center'}}>{renderLabel(countryCode, countryCode)}</Grid>
        <Grid item xs={3} style={{alignSelf:'center'}}>{renderLabel(regionCode, _regionCacheKey(countryCode, regionCode))}</Grid>
        <Grid item xs={5} style={{alignSelf:'center'}}>{cityCode}</Grid>
        <Grid item xs={1}>
          { onDropPath && (
              <Tooltip title="Remove from list">
                <IconButton
                    color="primary"
                    aria-label="add"
                    component="span"
                    onClick={()=>onDropPath(path)}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
          )}
        </Grid>
      </Grid>
  );
}

export function PlacesetSettingsMazArea({value, onChange}) {
  const [countries, setCountries] = React.useState([]);
  const [country, setCountry] = React.useState(["usa"]);
  const [rawRegions, setRawRegions] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [region, setRegion] = React.useState();
  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [labelCache, setLabelCache] = React.useState({});
  const init = React.useCallback(async () => {
    // NOTE: UDF stores [lower(country.alpha3), lower(region.alphanum_3), lower(city.name)]
    setLoading(true);
    const res = await CarApi.getDECountries();
    const countries = normalize_api_data(res.data, ["name", "alpha_2", "alpha_3"]);
    setCountries(countries);
    const res2 = await CarApi.getDERegions();
    const regions = fix_regions(normalize_api_data(res2.data, ["name", "country_alpha_2", "alphanum_3"]), countries);
    setRawRegions(regions);
    setLabelCache(computeLabelCache(countries, regions));
    setLoading(false);
  }, []);
  React.useEffect(() => {
    setAreas(value);
  }, [value]);
  React.useEffect(() => {
    init();
  }, [init]);
  React.useEffect(() => {
    setRegions(rawRegions.filter(region => !country || region.country_alpha_3 === country[0]));
  }, [rawRegions, country]);
  const updateAreas = (areas) => {
    setAreas(areas);
    if (areas !== value) {
      onChange(areas);
    }
  }
  const addAreaPath = (value) => {
    if (!value) return;
    const newAreas = areas.filter(area => !lodash.isEqual(area, value));
    newAreas.push(value);
    updateAreas(newAreas.sort());
  };
  const addSelectedCountry = () => {
    addAreaPath(country);
    setCountry();
  }
  const handleSelectedRegion = (value) => {
    setRegion(value);
    if (!value) return;
    addAreaPath(value);
    setTimeout(() => {
      // Synchronously calling it would overwrite the previous change, leaving the value
      // as it was, ultimately cancelling resetting the dropdown. setTimeout ensures that
      // the reset happens.
      setRegion(undefined);
    }, 0);
  }
  const handleDropPath = (path) => {
    updateAreas(areas.filter(area => !lodash.isEqual(area, path)));
  }
  return loading ? "Loading..." : (
      <React.Fragment>
        { onChange && (
            <Box p={1} bgcolor="#f0f0f0">
              <Grid container>
                <Grid item xs={10}>
                  <AriAutocomplete
                      options={countries}
                      onChange={setCountry}
                      value={country}
                      labelGetter={fieldGetter("name")}
                      valueGetter={(object)=>[object?.alpha_3]}
                      valueEquals={lodash.isEqual}
                      fullWidth
                      label="Country"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title="Add selected country">
                    <IconButton
                        color="primary"
                        aria-label="add"
                        component="span"
                        onClick={addSelectedCountry}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <AriAutocomplete
                      options={regions}
                      onChange={handleSelectedRegion}
                      value={region}
                      labelGetter={fieldGetter("name")}
                      valueGetter={(object)=>[object.country_alpha_3, object.alphanum_3]}
                      valueEquals={lodash.isEqual}
                      fullWidth
                      label="Region"
                  />
                </Grid>
              </Grid>
            </Box>
        )}
        <Box p={1}>
          <Grid container style={{height:45}}>
            <Grid item xs={3} style={{alignSelf:'center'}}><b>Country</b></Grid>
            <Grid item xs={3} style={{alignSelf:'center'}}><b>Region/State</b></Grid>
            <Grid item xs={5} style={{alignSelf:'center'}}><b>City*</b></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}>
              *The naming comes from Digital Element's API. Sometimes it refers to a neighborhood, sometimes to a county.
            </Grid>
          </Grid>

          <Paper>
            <Box p={1} style={{height:'300px', overflow:'auto'}}>
              {areas.length === 0 && "No area selected."}
              {areas.map(area => <Area key={area.join("-")} onDropPath={onChange && handleDropPath}
                                       path={area} labelCache={labelCache}/>)}
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
  );
}
