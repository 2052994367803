import lodash from "lodash";
import CarApi from "../api/CarApi";
import {ModeSelectorTooltipInfo} from "../Components/ModeSelectorTooltipInfo";
import React from 'react';

export const SelectionMode = Object.freeze({
  ALL: {
    key: "ALL",
    selector: null,
    avatar_letter: "A",
    path_template: '/editor',
    description: "Browse places in all placesets",
  },
  CLIENT: {
    key: "CLIENT",
    selector: 'client_id',
    avatar_letter: "C",
    base_path: 'clients',
    path_template: '/clients/:id',
    description: "Browse places in all placesets under this client",
  },
  PROJECT: {
    key: "PROJECT",
    urlform: "project",
    selector: 'project_id',
    avatar_letter: "P",
    base_path: 'projects',
    path_template: '/projects/:id',
    description: "Browse places in the placeset of this project",
  },
  PLACESET: {
    key: "PLACESET",
    urlform: "placeset",
    selector: 'placeset_id',
    avatar_letter: "S",
    base_path: 'placesets',
    path_template: '/placesets/:id',
    description: "Browse places in this placeset",
  },
});

export const SELECTOR_PARAMS = Object.values(SelectionMode).map(mode => mode.selector).filter(mode => mode);

/**
 * Determine the SelectionMode of a selector
 * @param selector
 * returns {SelectionMode | null}
 */
export function getSelectionModeFromSelector(selector) {
  const selectionMode = Object.values(SelectionMode).filter(mode =>
      (mode.selector === null && lodash.isEmpty(selector)) ||
      selector[mode.selector]).shift();
  return selectionMode || null;
}

export function getAssignTargetUrlFormFromSelector(selector) {
  const mode = getSelectionModeFromSelector(selector);
  const id = selector[mode.selector];
  return `${mode.urlform}-${id}`
}

export function getAssignTargetSelectorFromUrlForm(urlform) {
  const [type, id_str] = urlform?.split("-") || [];
  const id = id_str|0;
  if (type === SelectionMode.PROJECT.urlform && id > 0) {
    return {
      [SelectionMode.PROJECT.selector]: id,
    }
  } else if (type === SelectionMode.PLACESET.urlform && id > 0) {
    return {
      [SelectionMode.PLACESET.selector]: id,
    }
  }
}

/**
 * Returns a modeInfo object from a mode selector expression
 * @param selector
 * @param onModeSelect Optional handler for mode selection (Used in details view)
 * @returns {Promise<{access_level: string, selection_id, details: undefined, selector, title: string, selection_mode: *, client_id: null}>}
 */
export async function getSelectionModeInfo(selector, onModeSelect) {
  const selectionMode = getSelectionModeFromSelector(selector);
  const id = selector[selectionMode.selector];
  let title = "All";
  let client_id = null;
  let placeset_id = null;
  let details = undefined;
  let access_level = CarApi.const.AccessLevel.ADMIN;
  if (selectionMode === SelectionMode.CLIENT) {
    const res = await CarApi.getClient(id);
    client_id = id;
    title = res.data.name;
  } else if (selectionMode === SelectionMode.PLACESET) {
    const res = await CarApi.getPlaceset(id);
    const placeset = res.data;
    title = placeset.name;
    client_id = placeset.client_id;
    placeset_id = placeset.id;
    details = placeset.projects.length === 0 ? (
        <React.Fragment>
          Not used by any projects.
        </React.Fragment>
    ) : (
        <ModeSelectorTooltipInfo
            client_id={client_id}
            client_name={placeset.client_name}
            projects={placeset.projects}
            onModeSelect={onModeSelect}
        />
    );
    access_level = placeset.access_level;
  } else if (selectionMode === SelectionMode.PROJECT) {
    const res = await CarApi.getProject(id);
    const project = res.data;
    title = project.name;
    client_id = project.client_id;
    placeset_id = project?.placeset?.id;
    details = !project.placeset ? (
        <React.Fragment>
          Has no placeset defined.
        </React.Fragment>
    ) : (
        <ModeSelectorTooltipInfo
            client_id={client_id}
            client_name={project.client_name}
            placeset={project.placeset}
            projects={project.placeset.projects}
            onModeSelect={onModeSelect}
        />
    );
    access_level = project.access_level;
  } else if (selectionMode === SelectionMode.ALL) {
    access_level = CarApi.const.AccessLevel.READONLY;
  } else {
    throw new Error("Invalid Selection Mode");
  }
  return {
    title: title,
    details: details,
    access_level: access_level,
    selection_mode: selectionMode,
    selection_id: id,
    selector: selector,
    client_id: client_id,
    related_placeset_id: placeset_id,
  }
}
