import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import React from "react";
import CarApi from "../api/CarApi";
import {nativeComparator} from "../utils/utils";
import {AriAutocomplete, fieldGetter} from "../utils/formUtils";
import {SelectionMode} from "../utils/modeSelectorUtils";

function extractEntities(collection, selector_key) {
  const res = [];
  collection.forEach((entity) => {
    // FIXME: skip if access_level is not admin (cannot write into it)
    res.push({
      selector: {
        [selector_key]: entity.id,
      },
      name: `${entity.name} (#${entity.id})`,
    });
  })
  res.sort((a, b) => nativeComparator(a.name, b.name))
  return res;
}

function extendName(collection, postfix) {
  return collection.map(item => {
    const copy = {...item}
    copy.name += postfix
    return copy;
  });
}

export function AssignTargetDialog({onSave, open}) {
  const [clients, setClients] = React.useState([]);
  const [clientProjects, setClientProjects] = React.useState({0: []});
  const [clientPlacesets, setClientPlacesets] = React.useState({0: []});
  const [clientId, setClientId] = React.useState(0);
  const [targetType, setTargetType] = React.useState(SelectionMode.PROJECT.selector);
  const [selector, setSelector] = React.useState(null);
  React.useEffect(() => {
    setSelector(null);
  }, [clientId, targetType]);
  React.useEffect(() => {
    async function refresh() {
      const res = await CarApi.getPlacesets();
      const clients = [];
      const clientPlacesets = {0: []};
      const clientProjects = {0: []};
      res.data.forEach((client) => {
        const client_name = `${client.name} (#${client.id})`;
        clients.push({
          id: client.id,
          name: client_name,
        });
        clientPlacesets[client.id] = extractEntities(client.placesets, SelectionMode.PLACESET.selector);
        clientPlacesets[0].push(...extendName(clientPlacesets[client.id], ` - Client: ${client_name}`));
        clientProjects[client.id] = extractEntities(client.projects, SelectionMode.PROJECT.selector);
        clientProjects[0].push(...extendName(clientProjects[client.id], ` - Client: ${client_name}`));
      });
      clients.sort((a, b) => nativeComparator(a.name, b.name));
      clientPlacesets[0].sort((a, b) => nativeComparator(a.name, b.name));
      clientProjects[0].sort((a, b) => nativeComparator(a.name, b.name));
      setClients(clients);
      setClientPlacesets(clientPlacesets);
      setClientProjects(clientProjects);
      if (clients.length === 1) {
        setClientId(clients[0].id);
      }
    }
    refresh();
  }, [open]);
  const handleClose = () => {
    onSave();
  };
  const handleSave = async () => {
    onSave(selector);
  };
  const selectors = targetType===SelectionMode.PROJECT.selector?(
      clientProjects[clientId||0]
  ) : (
      clientPlacesets[clientId||0]
  );
  const selectorTypeName = targetType===SelectionMode.PROJECT.selector?(
      SelectionMode.PROJECT.baseurl
  ) : (
      SelectionMode.PLACESET.baseurl
  );
  const isSingleClient = clients.length === 1;
  return (
      <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Select copy target</DialogTitle>
        <DialogContent style={{width:600}}>
          {!isSingleClient && <AriAutocomplete
              options={clients}
              onChange={setClientId}
              value={clientId}
              labelGetter={fieldGetter("name")}
              valueGetter={fieldGetter("id")}
              fullWidth
              label="Client"
          />}
          <RadioGroup row aria-label="type" name="Type" value={targetType} onChange={e=>setTargetType(e.target.value)}>
            <FormControlLabel value={SelectionMode.PROJECT.selector} control={<Radio />} label={SelectionMode.PROJECT.urlform} />
            <FormControlLabel value={SelectionMode.PLACESET.selector} control={<Radio />} label={SelectionMode.PLACESET.urlform} />
          </RadioGroup>
          <AriAutocomplete
              options={selectors}
              onChange={setSelector}
              value={selector}
              labelGetter={fieldGetter("name")}
              valueGetter={fieldGetter("selector")}
              valueEquals={(a,b)=>JSON.stringify(a)===JSON.stringify(b)}
              fullWidth
              label={selectorTypeName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave}  color="primary">
            Set as Target
          </Button>
        </DialogActions>
      </Dialog>
  );
}
