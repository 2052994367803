import React from "react";
import {Avatar, Box, Button, Paper, Typography} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import InfoIcon from '@material-ui/icons/Info';
import {SelectionMode} from "../utils/modeSelectorUtils";
import {makeStyles} from "@material-ui/core/styles";
import {OptionalTooltip} from "./OptionalTooltip";
import CarApi from "../api/CarApi";

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: theme.spacing(2) + "px !important",
  },
}));
export default function ModeSelectorComponent({modeInfo, onOpenModeSelector, onModeAction, displayModeSelectorList}) {
  const classes = useStyles();
  const hasSettings = [SelectionMode.PLACESET, SelectionMode.PROJECT].includes(modeInfo.selection_mode);
  const modeAvatarChar = modeInfo.selection_mode.avatar_letter;
  return (
      <Paper>
        <Box display="flex">
          <Button
              style={{flexGrow: 1, justifyContent: "space-between", padding: "8px"}}
              onClick={onOpenModeSelector}
              endIcon={
                displayModeSelectorList?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>
              }
              startIcon={<Avatar className={classes.small}>{modeAvatarChar}</Avatar>}
          >
            <Typography noWrap variant="button">
              {modeInfo.title}
            </Typography>
          </Button>
          <OptionalTooltip arrow title={modeInfo.details} interactive>
            <Button
                style={{minWidth: "0px", padding: "6px"}}
                disabled={!modeInfo.details}
                >
              <InfoIcon/>
            </Button>
          </OptionalTooltip>
          <OptionalTooltip title={hasSettings?"Settings":"Create new placeset"}>
            <Button
                style={{minWidth: "0px", padding: "6px"}}
                onClick={onModeAction}
                disabled={modeInfo.access_level === CarApi.const.AccessLevel.READONLY}
            >
              { hasSettings ? <SettingsIcon/> : <AddLocationIcon/> }
            </Button>
          </OptionalTooltip>
        </Box>
      </Paper>
  );
}
