import lodash from 'lodash';

export const GpsSource = Object.freeze({
    CAR:   Symbol("car"),
    PHONE:  Symbol("phone"),
});

export const SearchStatus = Object.freeze({
    NONE:   Symbol("none"),
    LOADING:  Symbol("loading"),
    TIMEOUT:  Symbol("timeout"),
    FAILED:  Symbol("failed"),
});
export function getSearchStatusLabel(searchStatus) {
    switch (searchStatus) {
        case SearchStatus.LOADING:
            return "Loading...";
        case SearchStatus.TIMEOUT:
            return "Search timeout.";
        case SearchStatus.FAILED:
            return "Backend error.";
        default:
            return "";
    }
}

export const boolToCssVisibility = (value) => value?"visible":"none";

/**
 * Query-string decode
 * query: The query string
 * Returns the dictionary containing query params with their respected values
 * see: http://stackoverflow.com/a/2880929/1442987
 * see: http://unixpapa.com/js/querystring.html
 */
export function qs_decode(query) {
    var match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        urlParams = {};
    while ((match = search.exec(query)) !== null)
        urlParams[decode(match[1])] = decode(match[2]);
    return urlParams;
}

/**
 * A smarter encodeURIComponent impl
 * see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 */
function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

/**
 * Query-string encode
 * query: The query-param dictionary
 * Returns the encoded querystring
 */
export function qs_encode(query) {
    return lodash.map(query, (value, key) =>
        fixedEncodeURIComponent(key) + "=" + fixedEncodeURIComponent(value)
    ).join("&")
}

/**
 * Update ref with value provided
 * @param ref Either a function expecting the value as its param, or a react ref (https://reactjs.org/docs/refs-and-the-dom.html)
 * @param value The value to pass to ref
 */
export function updateRef(ref, value) {
    if (ref) {
        if (typeof ref === 'function') {
            ref(value);
        } else if (ref.hasOwnProperty('current')) {
            ref.current = value;
        } else {
            throw new Error("Ref needs to be a function of a react ref object.");
        }
    }
}

export default { GpsSource, SearchStatus, getSearchStatusLabel, boolToCssVisibility, qs_encode, qs_decode }
