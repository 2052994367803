import {computeDestinationPoint, getBounds, getCenter, getDistance} from "geolib";
import lodash from "lodash";
import {createRandomUID, isEmptyArray} from "./utils";
const geoJsonBBox = require('geojson-bbox');

/**
 * Scales the bounding box around its center.
 *
 * scale=1 leaves the box as is.
 * scale=0.5 reduces it to half the size.
 * scale=3 makes it 3 times bigger.
 *
 * @param bbox The bounding box as returned by getPlaceBoundingBox or getCircleBoundingBox.
 * @param scale The scale factor
 * @returns {[*[], *[]]}
 */
export function scaleBoundingBox(bbox, scale) {
  function scaler(origin, target, scale) {
    return origin + (target - origin) * scale;
  }
  const center = getCenter(bbox);
  return [
    [scaler(center.longitude, bbox[0][0], scale), scaler(center.latitude, bbox[0][1], scale)],
    [scaler(center.longitude, bbox[1][0], scale), scaler(center.latitude, bbox[1][1], scale)],
  ];
};

/**
 * Get centroid of a place from its geos
 *
 * @param place A Place object
 * @returns [lon:number, lat:number]
 */
export function getPlaceGeoCentroid(place) {
  if (place.place_geos?.length > 0) {
    const geoCentroids = place.place_geos.map(place_geo => {
      const geometry = JSON.parse(place_geo.geo_text);
      return getCenter(lodash.flatten(geometry.coordinates));
    });
    return getCenter(geoCentroids);
  }
};

/**
 * Get bounding box of a place's points
 *
 * Considers both the location of the place, and the points in its polygons
 *
 * @param place A Place object
 * @returns {[[lon:number, lat:number], [lon:number, lat:number]]} A geolib bounding box object
 */
export function getPlaceBoundingBox(place) {
  return getMetaBoundingBox([
    getPointsBoundingBox([place]),
    ...place.place_geos.map(place_geo => getGeoJsonBoundingBox(JSON.parse(place_geo.geo_text))),
  ]);
};

/**
 * Get bounding box for an array of points
 *
 * NOTE: Would be better to return {lng,lat} or {lon,lat} objects, but
 *   the mapbox component unfortunately fails on it. Because of this we
 *   use this format for bounding boxes throughout our code.
 * @param points Can be in any format geolib accepts
 * @returns {[*[], *[]]}
 */
export function getPointsBoundingBox(points) {
  const bounds = getBounds(points);
  return [
    [bounds.minLng, bounds.minLat],
    [bounds.maxLng, bounds.maxLat],
  ];
};

/**
 * Get bounding box for a GeoJSON Feature
 *
 * @returns {[*[], *[]]}
 */
export function getGeoJsonBoundingBox(geoJson) {
  const bbox = geoJsonBBox(geoJson);
  return [
    [bbox[0], bbox[1]],  // min-lon, min-lat
    [bbox[2], bbox[3]],  // max-lon, max-lat
  ];
};

/**
 * Get GeoJSON for the viewportStatus
 * viewportStatus.bounds stores the boundingbox info (format used by *BoundingBox methods)
 * @param viewportStatus
 * @returns {{coordinates: *[][], type: string}}
 */
export function getGeoJsonFromViewport(viewportStatus) {
  const bbox = viewportStatus.bounds;
  return {
    "type": "Polygon",
    "coordinates": [[
      [bbox[0][0], bbox[0][1]],
      [bbox[0][0], bbox[1][1]],
      [bbox[1][0], bbox[1][1]],
      [bbox[1][0], bbox[0][1]],
      [bbox[0][0], bbox[0][1]],
    ]]
  }
}

/**
 * Get bounding box of a circle
 *
 * NOTE: Due to the peculiarities of the Mercator projection, the center
 * point wont be at the center pixel of the map view. The area closer to
 * the pole will be larger.
 * @param centerPoint
 * @param radiusMeter
 * @returns {*}
 */
export function getCircleBoundingBox(centerPoint, radiusMeter) {
  return getPointsBoundingBox([
    computeDestinationPoint(centerPoint, radiusMeter, 0),
    computeDestinationPoint(centerPoint, radiusMeter, 90),
    computeDestinationPoint(centerPoint, radiusMeter, 180),
    computeDestinationPoint(centerPoint, radiusMeter, 270),
  ]);
}

/** Get bounding box of bounding boxes
 *
 * @param bboxes
 */
export function getMetaBoundingBox(bboxes) {
  if (bboxes === undefined || bboxes === null || isEmptyArray(bboxes)) {
    return;
  }
  const min_lon = Math.min(...bboxes.map(bbox => bbox[0][0]));
  const min_lat = Math.min(...bboxes.map(bbox => bbox[0][1]));
  const max_lon = Math.max(...bboxes.map(bbox => bbox[1][0]));
  const max_lat = Math.max(...bboxes.map(bbox => bbox[1][1]));
  return [
    [min_lon, min_lat],
    [max_lon, max_lat],
  ];
}

/**
 * Get estimated radius of PlaceGeo
 * Picks a random point, and measures distance from all other points.
 * This should be at least the radius at most the diameter, hence the estimate.
 * @param placeGeo A PlaceGeo object
 * @returns {number} Estimated radius of geo in meters.
 */
export function getPlaceGeoRadiusEstimateMeters(placeGeo) {
  const geometry = JSON.parse(placeGeo.geo_text);
  return Math.max(...geometry.coordinates.map( item => {
    const base = item[0];
    const distances = item.map(pos => getDistance(base, pos));
    return Math.max(...distances);
  }));
};

export function createFeatureFromPlaceGeo(placeGeo) {
  return {
    type: "Feature",
    properties: {
      geo_id: placeGeo.geo_id,
      place_id: placeGeo.place_id,
      map_geo_id: createRandomUID(),
      geo_type_id: placeGeo.geo_type_id,
      show: true,
      delete: false,
    },
    geometry: (typeof placeGeo.geo_text === 'string') ? JSON.parse(placeGeo.geo_text) : placeGeo.geo_text
  };
}
