import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { store } from 'react-notifications-component';

export const POPUP_TIMING = {
    SHORT: 1500,
    LONG: 3500,
    EXTRALONG: 6000,
};

export const POPUP_TYPES = {
    INFO: "default",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "danger",
};

export const POPUP = {
    SAVE_ERROR: {
        title: "Save Unsuccessful",
        message: "Your information could not be saved, please recheck your information and try again. If the problem persists please contact support",
        type: POPUP_TYPES.ERROR,
        timing: POPUP_TIMING.LONG,
    },
    SAVE_SUCCESS: {
        message: "Your information was saved!",
    },
    SEARCH_TIMEOUT_ERROR: {
        title: "Search timeout",
        message: "Search query was too complex and timed out.",
        type: POPUP_TYPES.ERROR,
        timing: POPUP_TIMING.SHORT,
    },

    DELETE_SUCCESS:{
        message: "POI Deleted",
    },
    UNKNOWN_ERROR: {
        title: "Unknown Error",
        message: "An unknown error as occurred, please contact support",
        type: POPUP_TYPES.ERROR,
        timing: POPUP_TIMING.LONG,
    },
};

export function showNotification({title, message, type, timing}) {
    store.addNotification({
        title: title || null,
        message: message,
        type: type || POPUP_TYPES.SUCCESS,
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: timing || POPUP_TIMING.SHORT,
            showIcon: true,
        },
    });
}
