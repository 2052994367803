import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function LongOperationModal({open, title, description}) {
  const classes = useStyles();
  const rootRef = React.useRef(null);

  return (
      <div ref={rootRef}>
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={open}
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            className={classes.modal}
            container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <h2 id="server-modal-title">{title}</h2>
            <p id="server-modal-description">{description}</p>
          </div>
        </Modal>
      </div>
  );
}
