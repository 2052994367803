import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import {POPUP_TYPES, showNotification} from "../../notifications";
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CarApi from "../../api/CarApi";
import {nativeComparator} from "../../utils/utils";
import {AriAutocomplete, fieldGetter} from "../../utils/formUtils";

export function PlacesetCreateDialog(
    {
      onSave, open, clientId:clientIdValue, singleClientMode:singleClientModeValue,
        defaultName
    }) {
  const [clientId, setClientId] = React.useState(clientIdValue);
  const [clients, setClients] = React.useState([]);
  const [singleClientMode, setSingleClientMode] = React.useState(singleClientModeValue || false);
  const [name, setName] = React.useState(defaultName || "");
  React.useEffect(() => {
    async function refresh() {
      const res = await CarApi.getClients();
      const hasSingleClient = res.data.length === 1;
      setClients(res.data.sort((a, b) => nativeComparator(a.name, b.name)));
      if (singleClientModeValue === undefined) {
        setSingleClientMode(hasSingleClient);
      } else {
        setSingleClientMode(singleClientModeValue);
      }
      if (hasSingleClient) {
        setClientId(res.data[0].id);
      }
    }
    setName(defaultName);
    setClientId(clientIdValue);
    refresh();
  }, [open, clientIdValue, singleClientModeValue, defaultName]);
  const handleClose = () => {
    onSave();
  };
  const handleSave = async () => {
    if (!singleClientModeValue && (clientId <= 0 || clientId === undefined)) {
      showNotification({
        message:"Client ID is missing",
        type: POPUP_TYPES.ERROR,
      });
      return;
    }
    if (name === undefined || name.length < 1) {
      showNotification({
        message:"Name cannot be empty",
        type: POPUP_TYPES.ERROR,
      });
      return;
    }
    onSave({
      client_id: clientId,
      name: name,
    });
  };
  return (
      <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Create new placeset</DialogTitle>
        <DialogContent>
          {!singleClientMode &&
          <AriAutocomplete
            options={clients}
            onChange={setClientId}
            value={clientId}
            labelGetter={fieldGetter("name")}
            valueGetter={fieldGetter("id")}
            fullWidth
            label="Client"
          />}
          <br/>
          <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}/>
          <br/>
          <br/>
          <Typography variant="body">
            Once created you can assign items to a Placeset with the assign buttons under the places list.
            <AddBoxIcon/><AddToPhotosIcon/>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave}  color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
  );
}
